// BUTTONS APLICATION
.button-general {
    -webkit-user-select: none;
    align-items: center;
    background-color: transparent;
    border-radius: 3rem;
    border: 1px solid;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    height: 3rem;
    text-align: center;
    transition: none;
    line-height: normal;
}

button:focus {
    outline: none !important;
}

.btn-disabled-lighten {
    background-color: $color-light-magento !important;

    & span div {
        color: $color-primary;
    }
}

.MuiButtonBase-root.available.Mui-selected {
    background-color: $color-light-magento !important;
}

.toggle-btn-schedule {
    .toggle-button-hour-renter {
        background-color: $color-light-magento;
    }

    & .toggle-button-hour-renter:hover {
        background-color: darken($color-light-magento, 20) !important;
        color: $color-white !important;
    }

    & .Mui-selected {
        background-color: $color-white !important;
        color: $color-primary !important;
    }

    .toggle-button-calendar {
        background-color: $color-light-magento;
    }

    & .toggle-button-calendar:hover {
        background-color: darken($color-light-magento, 20) !important;
        color: $color-white !important;

        & span .columns {
            color: $color-white;
        }
    }
}

.only-view-btn-schedule {
    & .toggle-button-hour-renter:hover {
        color: $color-white !important;
    }

    & .Mui-selected {
        color: $color-primary !important;
    }

    .toggle-button-calendar {
        background-color: $color-light-magento;
    }

    & .toggle-button-calendar:hover {
        background-color: $color-light-magento !important;
        color: black !important;
        cursor: not-allowed;
    }

    .MuiTouchRipple-child {
        background-color: transparent !important;
    }
}

.only-view-available {
    & .Mui-selected {
        background-color: $color-light-magento !important;
        color: $color-primary !important;
    }

    .toggle-button-calendar:hover {
        background-color: white !important;
        cursor: not-allowed;
    }

    .MuiTouchRipple-child {
        background-color: transparent !important;
    }

    .MuiToggleButton-root:hover {
        background-color: transparent !important;
    }
}

.available {
    & .Mui-selected {
        background-color: $color-light-magento !important;
        color: $color-primary !important;
    }
}

.calendar-mobile-visit {
    @extend .mb-2;
    @extend .d-flex;
    @extend .flex-row;
}

.toggle-btn-schedule-mobile {
    & .MuiButtonBase-root {
        background-color: $color-light-magento !important;
    }

    & span .columns {
        color: $color-primary;
    }

    & .Mui-selected {
        background-color: $color-white !important;
        color: $color-primary !important;
    }

    & .available.Mui-selected {
        background-color: $color-white !important;
        color: $color-primary !important;
    }
}

.view-btn-schedule-mobile {
    & .MuiButtonBase-root {
        background-color: $color-light-magento !important;
    }

    & span .columns {
        color: $color-primary;
    }

    & .Mui-selected {
        background-color: $color-light-magento !important;
        color: $color-primary !important;
    }

    & .available.Mui-selected {
        background-color: $color-light-magento !important;
        color: $color-primary !important;
    }

    .MuiToggleButton-root:hover {
        background-color: $color-light-magento !important;
    }

    .MuiTouchRipple-child {
        background-color: transparent !important;
    }
}

.toggle-button-calendar {
    @extend .toggle-button-hour;
    @extend .col;
    margin-top: 0.3rem !important;
    margin-bottom: 0.5rem !important;
    border-radius: 0.6rem !important;
    height: 4.3rem !important;
    width: 90% !important;
}

.button-back {
    @extend .button-general;
    border-radius: 0rem;
    border: none;
    box-shadow: none;
    color: $color-secondary;
    font-size: 0.875rem;
    text-decoration: none;
}

.button-back:hover {
    text-decoration: underline;
}

.button-primary {
    @extend .button-general;
    background: $color-primary;
    border-color: $color-primary;
    color: $color-white;

    &:hover:enabled {
        background: darken($color: $color-fourth, $amount: 5) !important;
    }
}

.home-card-button {
    @extend .button-general;
    background: #a8123e;
    color: $color-white;
}

.button-copy {
    @extend .button-primary;
    height: 38px;
}

.button-relative {
    @extend .button-primary;
    position: relative;
}

.button-secondary {
    @extend .button-general;
    background: $color-secondary !important;
    border-color: $color-secondary !important;
    color: $color-white !important;

    &:hover:enabled {
        background: darken($color: $color-secondary, $amount: 5) !important;
    }

    &:disabled {
        cursor: not-allowed;
        background: darken($color: $color-secondary, $amount: 10) !important;
    }
}

.button-secondary-sign {
    @extend .button-secondary;

    @media only screen and (max-width: $breakpoint-phone) {
        order: 1;
    }
}

.button-white {
    @extend .button-general;
    background-color: $color-white;
    border-color: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $color-secondary;
    text-decoration: none !important;

    &:hover:enabled {
        color: darken($color: $color-secondary, $amount: 5);
    }
}

.button-white-home {
    @extend .button-general;
    background-color: $color-white !important;
    border-color: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $color-secondary;
    text-decoration: none !important;
    width: 20rem !important;
    font-size: 1.2rem !important;

    &:hover {
        color: #a8123e !important;
    }
}

.button-red-home {
    @extend .button-general;
    background-color: #a8123e !important;
    border-color: #a8123e;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    text-decoration: none !important;
    width: 20rem !important;
    font-size: 1.2rem !important;

    &:hover {
        color: white !important;
    }
}

.button-white {
    @extend .button-general;
    background-color: $color-white;
    border-color: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $color-secondary;

    &:hover:enabled {
        color: darken($color: $color-secondary, $amount: 5);
    }
}

.button-admin-tab {
    @extend .button-general;
    background: $color-fourth !important;
    border-color: $color-fourth !important;
    color: $color-white !important;
}

.button-tertiary {
    @extend .button-general;
    background: $color-tertiary;
    border-color: $color-tertiary;
    color: black;
}

.button-fourth {
    @extend .button-general;
    background: $color-white;
    border-color: $color-secondary;
    color: $color-secondary;
}

.button-fourth-hover {
    @extend .button-fourth;
    transition: all ease-in-out 0.2s;

    &:hover:enabled {
        @extend .button-secondary;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.button-five {
    @extend .button-general;
    background: $color-white;
    border-color: $color-tertiary;
    color: $color-tertiary;
}

.button-sixth {
    @extend .button-general;
    background: $color-white;
    border-color: black;
    color: black;
}

.button-change-info {
    @extend .button-general;
    background: $color-button-change !important;
    border-color: $color-button-change !important;
    color: $color-tertiary !important;
}

.button-primary:disabled,
.button-primary-disabled {
    @extend .button-general;
    background: $color-fourth;
    border-color: $color-fourth;
    color: $color-tertiary;
    cursor: not-allowed;
}

.button-secondary:disabled,
.button-secondary-disabled {
    @extend .button-general;
    background: $color-white;
    border-color: $color-tertiary;
    color: $color-tertiary;
}

.button-tertiary:disabled,
.button-tertiary-disabled {
    @extend .button-general;
    background: $color-block-disabled;
    border-color: $color-tertiary;
    color: $color-block-bg-disabled;
    cursor: not-allowed;
}

.button-admin-tab:disabled,
.button-admin-tab-disabled {
    @extend .button-general;
    background: $color-white;
    border-color: $color-tertiary;
    color: $color-tertiary;
}

.button-transparent {
    @extend .button-general;
    color: $color-white;
    border-radius: 0rem;
    border: none;
    box-shadow: none;
}

.button-cancel-modal {
    @extend .button-general;
    color: $color-secondary;
    border-radius: 0rem;
    border: none;
    box-shadow: none;
    font-weight: bold;
    @media only screen and (max-width: $breakpoint-phone) {
        order: 2;
    }
}

.button-change-view-active {
    @extend .button-general;
    background: $color-white;
    border-color: $color-secondary;
    color: $color-secondary;
    border: none;
    border-radius: 10px 0 0 10px;
    padding-right: 0.55rem;
    padding-left: 0.55rem;
    box-shadow: none;
}

.button-change-view {
    @extend .button-general;
    color: $color-white;
    border: none;
    border-radius: 10px 0 0 10px;
    padding-right: 0.55rem;
    padding-left: 0.55rem;
    box-shadow: none;
}

// BUTTON LIST MAP
.button-list-map-container {
    text-align: left;
    margin-left: 2%;
    @extend .mt-3;

    .select-order-by {
        width: 10rem;
        text-align: left;
        margin-right: 45%;
    }

    .pin-list {
        display: none;
    }

    .pin-menu {
        cursor: pointer;
        width: 1rem;
        height: 1.5rem;
        margin: 0 0.25rem 0 1.5rem;
    }

    .list-menu {
        cursor: pointer;
        width: 1rem;
        height: 1rem;
        margin: 0 0.25rem 0 0;
    }
}

.margin-buttons {
    margin-left: 2rem;
    margin-right: 2rem;
}

// BUTTON TABLE TANGO
.button-color {
    .red-color {
        background-color: #a8123e;
        border: 1px solid #a8123e;
        color: #ffffff;
        text-transform: none;

        &:hover {
            color: #ffffff;
        }
    }

    .black-color {
        background-color: #101820;
        color: #ffffff;
        text-transform: none;
    }

    .grey-color {
        background-color: #999999;
        color: #ffffff;
        border: 1px solid #ffffff;
        text-transform: none;
    }

    .white-color {
        background-color: #ffffff;
        color: #a8123e;
        border: 1px solid #a8123e;
        text-transform: none;
    }

    .white-grey-color {
        background-color: #ffffff;
        color: #999999;
        border: 1px solid #999999;
        text-transform: none;
    }

    .grey-black-color {
        background-color: #999999;
        color: #000000;
        border: 1px solid #999999;
        text-transform: none;
    }

    .big-button {
        max-height: 2.8rem;
        width: 160px;
    }

    .margin-button {
        margin-left: 1rem;
    }

    .margin-button-left {
        margin-left: 2rem;
    }

    .margin-button-right {
        margin-right: 1rem;
    }

    .margin-payment-button-left {
        align-self: end;
    }
}

.plan-btns {
    @extend .button-general;
    height: fit-content;
    background: $color-primary;
    border-color: $color-primary;
    color: $color-white;
}

.btn-user-search {
    @extend .button-fourth;
    @extend .col-md-7;
    @extend .py-3;
    margin: 1rem 0 !important;
}

.button-secondary-menu {
    @extend .button-general;
    background: $color-secondary !important;
    border-color: $color-secondary !important;
    color: $color-white !important;
    height: 2rem;
    transition: ease-in-out 0.2s all;

    &:hover {
        background: darken($color-secondary, 5) !important;
    }
}

.btn-floating-container {
    right: 10%;
    bottom: 1rem;
    position: fixed;
}

.button-secondary-disabled-no-cursor {
    @extend .button-general;
    background: $color-secondary;
    border-color: $color-tertiary;
    color: $color-block-disabled;
    cursor: not-allowed;
}

.button-search {
    @extend .button-primary;
    height: 1.875rem;
    padding: 0.2rem 1.3rem 0.2rem 1.3rem;
}

.table-button {
    width: 120px;
}

.google-button-container {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;

    .google-button {
        height: 50px !important;
        font-family: Roboto, sans-serif !important;
        justify-content: center !important;
        width: 100% !important;
        font-size: 16px !important;
    }
}

.tango-button-container {
    padding: 1.875rem;
    text-align: center;
    width: 100%;

    .tango-button {
        height: 50px !important;
        justify-content: center !important;
        width: 100% !important;
    }
}

.button-six {
    @extend .button-general;
    background: $color-white;
    border-color: $color-secondary;
    color: $color-primary;
    font-weight: bold;
    padding-inline: 1rem;
}

.button-sort-table-section {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.button-sort-table-container {
    @extend .button-sort-table-section;
}

.button-sort-table {
    @extend .w-100;
    font-size: 0.9rem;
    @extend .mt-3;
}

.button-sort-table-section .button-sort-table-container .button-container-w100 {
    width: 100%;
}

.save-lease-document-button {
    @extend .button-primary;
    @extend .col-md-5;
    @extend .col-lg-4;
    .spinner-border {
        height: 1.7rem;
        width: 1.7rem;
    }
}

.cancel-lease-document-button {
    @extend .button-five;
    @extend .text-dark;
    @extend .col-md-5;
    @extend .col-lg-4;
    margin-right: 0.2rem;
}

.promotion-buttons-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
    margin-top: 5;
    .button-five {
        width: 100%;
    }
    .button-primary {
        width: 100%;
        margin-bottom: 0.7rem;
    }
}

@media only screen and (min-width: $breakpoint-phone) {
    .button-sort-table-container {
        width: 12.5rem;
    }

    .button-sort-table-section .button-sort-table-container .button-container-w100 {
        width: 50%;
    }

    .promotion-buttons-container {
        display: flex;
        flex-wrap: nowrap;
        .button-five {
            width: 50%;
        }
        .button-primary {
            width: 50%;
            margin-bottom: 0rem;
        }
    }
}

a.hyperlink {
    cursor: pointer;
    text-decoration: underline;
}

.container-button-paid {
    @extend .py-3;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    max-width: 35.5rem;
    margin-top: 1rem;
    button {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.button-outlined {
    box-shadow: none;
    border: 1px solid black;
}

.buttons-container-options {
    @extend .d-flex, .flex-wrap-reverse;
    justify-content: end;
    width: 100%;
    margin-top: 0.3rem;
    padding-inline: 0.5rem;
    &.center {
        justify-content: center;
    }
    button:first-child {
        @extend .button-white, .col-12, .col-md-5;
        box-shadow: none;
        border: 1px solid black;
        color: black;
        margin: 0.5rem;
        margin-right: 0.1rem;
        padding-inline: 2rem;
    }
    button:last-child {
        @extend .button-secondary, .col-12, .col-md-5;
        box-shadow: none;
        margin: 0.5rem;
        margin-right: 0;
        padding-inline: 2rem;
    }
}

.send-solicitude-button {
    @extend .px-5, .mb-2, .button-secondary;
}

.send-solicitud-button-container {
    @extend .d-flex, .justify-content-end, .mt-2;
}

.lateral-scroll-button-container {
    @extend .d-flex, .justify-content-start, .flex-nowrap;
    width: 100%;
    margin-top: 1rem;
    &::-webkit-scrollbar {
        width: 0;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    overflow-x: auto;
    button {
        @extend .button-white;
        box-shadow: none;
        border: 1px solid black;
        color: black;
        margin: 0.2rem;
        padding-inline: 2rem;
        &.active {
            @extend .button-primary;
            &:hover {
                color: white;
            }
        }
    }
}
