// FOOTER
.footer-page-container {
    background-color: $color-primary;
    color: $color-white;
    text-align: center;
    padding: 1rem;
    display: block;
    margin-top: 3rem;
    font-size: 0.75rem;
    .link-privacy-policies {
        color: #2d9cdb;
        cursor: pointer;
    }
}

.footer-home {
    margin-top: 26px;
    margin-left: auto;
    margin-right: auto;
    .list-unstyled {
        text-align: left;
        margin-bottom: 0.25rem;
        & a:hover {
            cursor: pointer;
            color: lighten($color: $color-secondary, $amount: 20) !important;
            transition: ease-in-out all 0.2s;
        }
    }
    .list-unstyled-mobile {
        @extend .list-unstyled;
        @extend .text-left;
        @extend .mb-0;
        @extend .ml-2;
    }
    li a {
        text-decoration: none !important;
        color: white !important;
        cursor: pointer;
    }
}

.title-footer {
    font-weight: bold;
    color: $color-white;
    text-align: left;
    font-size: 0.75rem;
    .contact-footer {
        margin-top: 0.7rem;
        font-size: 1rem;
    }
}

.link-contact-email {
    margin-top: 0.86rem;
}

// SOCIAL NETWORKS
.rrss {
    height: 50%;
    width: 100%;
    text-align: start;
}

.rrss a {
    text-align: center;
    margin: 10px;
    display: inline-block;
    min-height: 50px;
    min-width: 50px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    margin-bottom: 20px;
}

.rrss a i {
    font-size: 35px !important;
    color: black;
    padding-top: 8px;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ul-style-footer {
    span {
        color: $color-secondary !important;
    }
    ul {
        list-style: none;
    }
    ol,
    ul,
    dl {
        margin-bottom: 0;
    }
    ul li::before {
        content: '\2022';
        color: $color-secondary !important;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
}
