// LOGO NAVBAR
.logo-navbar {
    width: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
}

// NAVBAR
.navbar-renter-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 30rem;
    grid-template-areas: 'navbar-container';

    .template-navbar-container {
        grid-area: navbar-container;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 17.5rem 2.5rem;
        grid-template-areas:
            'template-area'
            '.';

        .template-navbar {
            height: auto;
            grid-area: template-area;
        }
    }

    .search-navbar-container {
        grid-area: navbar-container;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 14rem 17rem;
        grid-template-areas:
            '.'
            'search-area';
    }
}

.new-navbar-mobile {
    display: flex;
}

.new-navbar-mobile-ml {
    margin-left: 1rem;
}

.new-navbar-mobile-mr {
    margin-right: 1rem;
}

.navbar-desktop {
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.new-navbar-desktop {
    display: none;
    align-items: center;
    justify-content: space-between;
    a {
        text-decoration: none;
        color: $color-primary;
        border-bottom: 2px solid transparent;
    }
    a:hover {
        color: $color-secondary !important;
        border-bottom: 2px solid $color-secondary;
        text-decoration: none;
    }
}
.MuiToolbar-root.MuiToolbar-regular {
    height: 6.5rem;
    margin-right: 2%;
    margin-left: 2%;
}

// NavBar for RENTER
.template-navbar {
    height: 17.5rem;
    background-color: $color-primary;
    color: $color-white !important;

    // margin-bottom: 3rem;
    .template-title {
        margin-left: 4rem;
    }

    .MuiPaper-elevation4 {
        box-shadow: none;
        color: $color-white !important;
    }

    .MuiAppBar-colorPrimary {
        background-color: transparent !important;
    }

    .second-navbar {
        text-align: left;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 93%;
        margin-left: 3.5%;
        margin-right: 3.5%;

        .title-head {
            margin-left: 2rem;
        }
    }
}

//MIXIN FOR NAV-BAR-CONTAINER

// NAVBAR
.nav-bar-container {
    margin-bottom: 3rem;
    .MuiAppBar-root {
        z-index: 900;
    }

    .root {
        flex-grow: 1;
    }

    .title {
        flex-grow: 1;
    }

    .name-user-login {
        font-size: 1rem !important;
        padding: 0 0.5rem 0 0.5rem;
    }

    .arrow-display-menu {
        font-size: 1rem;
        color: $color-block-time;
    }

    .MuiIconButton-root {
        padding: 0 !important;
    }
}

.w-15 {
    width: 15% !important;
}
.w-20 {
    width: 20% !important;
}
.w-25 {
    width: 25% !important;
}
.w-90 {
    width: 90% !important;
}
.w-100 {
    width: 100% !important;
}
.w-10 {
    width: 10% !important;
}
.fw-700 {
    font-weight: 700 !important;
}

.margin-horizontal {
    margin: 0 1rem 0 1rem;
}

#nav-icon {
    width: 2.5rem;
    height: 1.75rem;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    margin-right: 2rem;
}
#nav-icon span {
    display: block;
    position: absolute;
    height: 2.5px;
    width: 100%;
    background: $color-primary;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}
#nav-icon span:nth-child(1) {
    top: 0px;
}
#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
    top: 9px;
}
#nav-icon span:nth-child(4) {
    top: 18px;
}
#nav-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}
#nav-icon.open span {
    background: $color-secondary;
}
#nav-icon.open.white span {
    background: $color-white;
}
#nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
#nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
#nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}
#nav-icon.white span {
    background: $color-white;
}

.notifier-mobile {
    font-size: 16px;
    .notification-section {
        height: 38vh;
        max-height: 38vh;
        overflow-y: scroll;
    }

    .btn-back {
        border-radius: 1.5rem;
        background-color: black;
        color: $color-white;
        border: none;
        &:focus,
        &:hover {
            box-shadow: none;
            color: white;
        }
        margin: 1rem;
        @extend .justify-content-start;
    }

    .circle-notifications-defualt {
        margin-top: 0.3rem;
        width: 1rem;
        height: 1rem;
        line-height: 1.4rem;
        border-radius: 50%;
        cursor: pointer;
    }

    .circle-notifications.readed {
        @extend .circle-notifications-defualt;
        background: $color-tertiary;
    }

    .circle-notifications.unread {
        @extend .circle-notifications-defualt;
        background: $color-alert-red;
    }

    .date {
        color: $color-tertiary;
    }

    span {
        font-size: smaller !important;
    }
}
