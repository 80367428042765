@media only screen and (max-width: $breakpoint-ultra-small) {
    .general-popup-with-buttons {
        #customized-dialog-content {
            .maximun-font-size {
                font-size: 0.87rem;
            }
        }
    }

    .promotions-popup {
        .MuiDialog-paper {
            margin: 1.5rem;
        }
        .MuiDialog-container {
            height: 520px;
        }
    }
    .dialog-save-charge-lines {
        #customized-dialog-title {
            padding-inline: 1rem;
        }

        #customized-dialog-title {
            padding-right: 1.2rem;
            padding-left: 1rem;
            padding-top: 0rem;
            padding-bottom: 0rem;
        }
        .MuiDialog-paper {
            margin: 1rem;
            margin-right: 1.5rem;
        }
        .MuiDialog-container {
            height: 110%;
        }

        .MuiPaper-root.MuiDialog-paper {
            top: -2rem;
        }
        .MuiDialogTitle-root {
            padding: 0.5rem 0.7rem;
        }
        .MuiDialogContent-root {
            padding-bottom: 1rem;
            p {
                font-size: 0.9rem;
            }
        }
    }

    .sidebar-right {
        font-size: 0.9rem !important;
    }
    .modal-style-sm {
        height: 77vh !important;
    }
    .step-visit .small-button {
        width: auto !important;
    }
    .popup-visit-renter-step-2 {
        .MuiDialog-paperWidthSm {
            width: 95vw !important;
        }
    }

    .step-visit {
        .MuiPickersStaticWrapper-staticWrapperRoot {
            min-width: 1rem;
            .MuiPickersBasePicker-pickerView {
                max-width: 100%;
                min-width: 0;
                min-height: 0;
            }
        }
    }

    .mh-79 {
        max-height: 73vh !important;
    }

    .communes-info-container {
        .communes-list.featured-properties {
            width: 86vw !important;
            left: -0.2rem !important;
            font-size: 0.65rem;
        }
    }

    .upload-logo {
        cursor: pointer;
        width: 1.875rem;
        height: 1.875rem;
    }

    .card-body-home {
        height: fit-content;
    }

    .sidebar {
        width: 16.875rem;

        .nav-items {
            font-size: 0.9rem;
            line-height: 2.4rem;
        }
    }

    .icon-card {
        img {
            width: 3rem !important;
            margin: 0.5rem 0 0.5rem 0;
        }
    }
}

.card-little-margin-top {
    box-shadow: none !important;
}

@media only screen and (max-width: $breakpoint-ex-small) {
    .payment-radio {
        height: 7.5rem;
    }
    .step-visit {
        margin: 0 !important;
        .input-color-tertiary {
            font-size: 0.8rem;
        }
    }
    .form-short-register {
        input,
        .text-color-error {
            font-size: 0.8rem !important;
        }
    }
}

// MEDIA WILL BE DELETED IN NAVBAR TICKET ISSUE
@media only screen and (max-width: $breakpoint-tablet) {
    .mount-container-tango-view {
        .container-mount {
            .body-card {
                #choose-register-text-desktop {
                    display: none;
                }
            }

            .body-card.new-unit-creation {
                padding: 1rem;
            }

            .creation-step {
                margin: 0;
                padding: 1rem 0.2rem 1.5rem 0.2rem;
                text-align: left;

                &.active-step {
                    font-size: 1.19rem !important;
                }
            }

            .step-number {
                margin-right: 0.9rem;
            }
        }
        .card-container-mount {
            .body-card {
                #choose-register-text-desktop {
                    display: none;
                }
                padding: 0 !important;
            }

            .body-card.new-unit-creation {
                padding: 1rem;
            }

            .creation-step {
                margin: 0;
                padding: 1rem 0.2rem 1.5rem 0.2rem;
                text-align: left;

                &.active-step {
                    font-size: 1.19rem !important;
                }
            }

            .step-number {
                margin-right: 0.9rem;
            }
            box-shadow: none !important;
        }

        .checkbox-width {
            max-width: 90%;
        }
    }

    .mount-container-tango-view-app-tango {
        .card-container-mount-app-tango {
            .body-card {
                #choose-register-text-desktop {
                    display: none;
                }
            }

            .body-card.new-unit-creation {
                padding: 1rem;
            }

            .creation-step-app-tango {
                margin: 0;
                padding: 1rem 0.2rem 1.5rem 0.2rem;
                text-align: left;

                &.active-step {
                    font-size: 1.19rem !important;
                }
            }

            .step-number {
                margin-right: 0.9rem;
            }
        }

        .checkbox-width {
            max-width: 90%;
        }
    }

    // LOGO TANGO
    .img-logo-ml {
        margin-left: 1rem;
    }

    .intention-cards-container > :first-child {
        .icon-card {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .intention-cards-container > :last-child {
        .icon-card {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .icon-card {
        width: 85%;
        padding: 1.8rem;
        margin: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon-card-title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        img {
            width: 4rem;
            height: 4rem;
        }

        h5 {
            font-size: 1rem !important;
        }
    }

    .home-contact-title {
        justify-content: start;
    }

    .tango-card {
        padding: 1.3rem;
        margin: 2rem 0 2rem 0;

        .contact-data-container {
            margin: 1rem 0 1rem 0;
        }
    }

    .home-header {
        min-height: 58vh;

        .container-navbar {
            h1.home-title {
                font-size: 1.6rem !important;
            }
        }

        h2.mobile-h2 {
            font-size: 1rem !important;
        }
    }

    .h-phone-75 {
        height: auto !important;
        padding-bottom: 1rem;
    }

    .owner-carousel {
        .react-multiple-carousel__arrow--right,
        .react-multiple-carousel__arrow--left {
            display: none;
        }
    }

    .mt-10 {
        margin-top: 0;
    }

    .owner-carousel-slide {
        min-height: 50vh;

        p {
            font-size: 1.5rem;
        }

        .carousel-number,
        .carousel-number-skew-left,
        .carousel-number-skew-right {
            display: none;
        }
    }

    .slide-number {
        font-size: 1.5rem;
    }

    .home-contact {
        text-align: justify;
    }

    .container-home {
        margin: auto;
        width: 100%;
    }

    .container-home.pd-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .container-home.about-us-container {
        padding-top: 6rem;
    }

    .intention-cards-container {
        padding-top: 7rem;
        margin: 0;
        margin-bottom: 1.5rem;
    }
    .social-media-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 1rem !important;
    }

    .margin-button-right {
        margin-right: 0.3rem !important;
    }

    .mount-container-tango-view .card-container-mount {
        display: block;
        min-height: 0rem;
        -webkit-box-shadow: 0px -1px 12px -2px $color-primary;
        box-shadow: none;
    }
    .mount-container-tango-view > div > .card-container-mount {
        #form {
            width: 100% !important;
        }
    }

    .mount-container-tango-view .container-mount {
        display: block;
        min-height: 0rem;
    }

    .mount-container-tango-view .card-container-mount.new-unit-creation {
        margin-top: 12.5rem;
    }
    .mount-container-tango-view .container-mount.new-unit-creation {
        margin-top: 12.5rem;
    }

    .mount-container-tango-view-app-tango .card-container-mount-app-tango {
        display: block;
        min-height: 0rem;
        -webkit-box-shadow: 0px -1px 12px -2px $color-primary;
        box-shadow: 0px -1px 12px -2px $color-primary;
    }

    .mount-container-tango-view-app-tango .card-container-mount.new-unit-creation {
        margin-top: 12.5rem;
    }

    .card-little-margin-top {
        margin-top: 1.5rem !important;
        box-shadow: none;
    }

    .mt-sm-2 {
        margin-top: 2rem;
    }

    .mount-container-tango-view .card-container-mount .container-mount .body-card .info-post-login {
        border-bottom: none !important;
        padding-bottom: 1rem;
    }

    .mount-container-tango-view .card-container-mount .container-mount .body-card .footer-container {
        padding-top: 0;
    }

    .mount-container-tango-view-app-tango .card-container-mount-app-tango .body-card .footer-container {
        padding-top: 0;
    }
    .mount-container-tango-view-app-tango .card-container-mount-app-tango .body-card .footer-container {
        padding-top: 0;
    }
    form .radio-toolbar input[type='checkbox']:checked + label {
        width: 127px;
        font-size: 0.7rem;
    }

    .mount-container-tango-view .card-container-mount .body-card {
        border-radius: 20px;
        width: 90vw;
        padding: 1rem 1rem 1rem 1rem;
    }
    .container-mount {
        width: 100vw;
        padding: 1rem;
    }

    .mount-container-tango-view .card-container-mount .info-card {
        display: none;
    }

    .mount-container-tango-view .container-mount .info-card {
        display: none;
    }

    .login-navbar .button-color,
    .login-navbar .big-button,
    .mount-container-tango-view .card-container-mount .container-mount .button-group-change {
        display: none;
    }
    .mount-container-tango-view-app-tango .card-container-mount-app-tango .body-card {
        border-radius: 20px;
        width: 90vw;
        padding: 1rem 1rem 1rem 1rem;
    }

    .mount-container-tango-view-app-tango .card-container-mount-app-tango .info-card {
        display: none;
    }

    .login-navbar .button-color,
    .login-navbar .big-button,
    .mount-container-tango-view-app-tango .card-container-mount-app-tango .button-group-change {
        display: none;
    }

    .phone-form {
        padding: 20px;
    }

    .phone-map {
        width: 100% !important;
    }

    .pd-1 {
        padding: 1rem;
    }

    .mtp-sm-0 {
        margin-top: 0 !important;
    }

    .table-content {
        font-size: 0.8rem !important;
    }

    .MuiTableCell-head {
        line-height: 0.7rem !important;
        font-size: 0.9rem !important;
    }

    .line-white-footer {
        height: 0.5px;
        margin-bottom: 12px;
        margin-top: 12px;
        padding: 1px;
        background-color: $color-fifth;
    }

    .circle-btn-cnt button .fa {
        z-index: 1;
    }

    .mx-sm-0 {
        margin: 0 !important;
    }

    .mlp-sm-1 {
        margin-left: 0.25rem !important;
    }

    .pr-sm-2 {
        padding-right: 2rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .container-footer {
        width: 100vw;
    }

    .content-center {
        display: flex;
        justify-content: center;
    }

    .mh-79 {
        max-height: 79vh;
    }

    .plm-0 {
        padding: 0 !important;
    }

    .subtitle {
        font-size: 20px !important;
    }

    .modal-open-sm {
        margin-left: 0% !important;
        margin-right: 0% !important;
    }

    form .radio-toolbar .button-secondary-disabled-string,
    form .radio-toolbar .button-secondary-disabled-string-cb,
    form .radio-toolbar .button-secondary-disabled-string-cb-cbx {
        min-width: 127px;
        font-size: 0.7rem;
    }

    form .radio-toolbar .radio-string.checked + label {
        min-width: 127px;
        font-size: 0.7rem;
    }

    .cold-sm-6 {
        max-width: 50%;
    }

    .max-100 {
        max-width: 100%;
    }

    .modal-style-sm {
        width: 91% !important;
        padding: 0 !important;
        // height: 63vh;
        height: 85vh;
    }

    .modal-small {
        height: 74vh;
    }

    .pd-4 {
        padding: 3.5rem !important;
    }

    .post-card-plan {
        max-width: 95vw;
    }

    .ml-xs-2 {
        margin-left: 0.7rem !important;
    }

    .mt-xs-1 {
        margin-top: 4.5rem;
    }

    .mt-xs-1rem {
        margin-top: 1.5rem;
    }

    .offer-detail-container {
        box-shadow: none;
    }

    .calendar-container .week-section .day-column .toggle-button-hour {
        height: 2.2rem !important;
        padding-left: 0;
        padding-right: 0;
    }

    .calendar-container .week-section .day-column .day {
        font-size: 0.5rem;
    }

    .h-sm-36 {
        height: 36rem;
    }

    .w-sm-14 {
        width: 14rem;
    }

    .w-sm-18 {
        width: 18rem;
    }

    .mt-sm-1 {
        margin-top: 1rem;
    }

    .mt-6 {
        margin-top: 5.5rem;
    }

    .section-house-item {
        display: flex !important;
        justify-content: space-around;
        margin-top: 1rem;
    }

    .font-sm-2 {
        font-size: 1.3rem !important;
    }

    .font-sm-1rem {
        font-size: 1rem !important;
    }

    .font-sm-1 {
        font-size: 0.8rem !important;
    }

    .font-sm-09 {
        font-size: 0.9rem !important;
    }

    .font-xs-1 {
        font-size: 0.4rem;
    }

    .font-sm-07 {
        font-size: 0.7rem !important;
    }

    .font-sm-1r {
        font-size: 1rem !important;
    }

    .col-mb-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .mt-sm-16 {
        margin-top: 16rem !important;
    }

    .h-sm-50 {
        height: 50%;
    }

    .no-bg-header-sm {
        background-color: #ffffff00 !important;
    }

    .h-sm-4 {
        height: 4rem !important;
    }

    .mt-sm-10 {
        margin-top: 10rem !important;
    }

    .btn-pass {
        font-size: 0.8rem;
        color: $color-tertiary;
    }

    .arrow-logo {
        height: 15%;
    }

    .arrow-logo-right {
        height: 15%;
    }

    .navbar-renter-container {
        grid-template-rows: 31rem;

        .template-navbar-container {
            grid-template-rows: 17.5rem 13.5rem;

            .template-navbar {
                height: auto;
                grid-area: template-area;
            }
        }

        .search-navbar-container {
            grid-template-rows: 14rem 17rem;
        }
    }

    .search-container {
        .search-card-container {
            border-radius: 25px !important;
        }

        .row-search {
            flex-wrap: wrap !important;

            .button-fourth {
                margin-top: 4rem;
            }

            .mt-search {
                margin: 2rem 0;
            }
        }

        .container-25 {
            align-items: flex-end;
            flex: 0 0 80%;
            max-width: 80%;
        }

        .container-50 {
            flex: 0 0 80%;
            min-height: 3.5rem;
            max-width: 80%;
            border-top-color: #a8123e;
            border-top-width: 1px;
            border-top-style: solid;
            border-bottom-color: #a8123e;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-left: none;
            border-right: none;
        }

        label {
            z-index: 1;
        }
    }

    .about-us-card {
        h2.card-title {
            font-size: 1.5rem !important;
        }

        h3 {
            font-size: 1rem !important;
        }
    }

    .tango-red-belt {
        p {
            font-size: 0.8rem;
        }

        small {
            font-size: 0.65rem;
        }
    }

    .communes-info-container {
        .communes-list.featured-properties {
            top: 2.2rem;
            width: 85vw;
            left: 0.7rem;
        }
    }

    .select-id-item {
        font-size: 0.75rem !important;
    }
}

@media only screen and (min-width: $breakpoint-tablet) {
    // MARGIN NAVBAR
    .container-navbar,
    .container-footer {
        max-width: 1440px;
        margin: auto;
    }

    .template-navbar {
        .second-navbar {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .nav-bar-container {
    }

    // MARGIN HOME
    .container-home {
        max-width: 1340px;
        margin: auto;
    }

    /* UNIT CARD OWNER */
    .card-timeline-owner {
        display: inline-flex;

        // text-align: start;
        .timeline-container {
            padding-left: 5%;

            .button-action-pending {
                margin-top: 2.75rem;
                text-align: start;
                margin-left: 3.5rem;

                span {
                    display: block;
                    text-align: start;
                }
            }
        }
    }

    .big-card {
        width: 55%;
        max-width: 24.379rem;
        min-width: auto;
    }

    .big-card-rented {
        margin-left: 0;
        margin-right: 0;
        margin-right: 2rem;
        display: inline-block;
    }

    .unit-box {
        .big-card {
            margin: 0;
        }
    }

    .column-margin-left {
        margin-left: 11rem;
    }

    .column-margin-top {
        margin-top: 1.5rem;
    }

    .button-list-map-container {
        .pin-list {
            position: relative;
            display: inline-block;
            left: 40%;
        }
    }

    .new-navbar-mobile {
        display: none;
    }

    .navbar-desktop {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .new-navbar-desktop {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item-carousel-container {
        .react-multiple-carousel__arrow--left {
            display: block;
        }

        .react-multiple-carousel__arrow--right {
            display: block;
        }
    }

    .navbar-renter-container {
        grid-template-rows: 20rem;

        .search-navbar-container {
            grid-template-rows: 14rem 6rem;
        }
    }

    .search-container {
        .search-card-container {
            border-radius: 10px !important;
            max-width: 1340px !important;
        }

        .row-search {
            flex-wrap: nowrap !important;
        }

        .container-25 {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .container-50 {
            flex: 0 0 50%;
            min-height: 3.5rem;
            max-width: 50%;
            border-right-color: #a8123e;
            border-right-width: 2px;
            border-right-style: solid;
            border-left-color: #a8123e;
            border-left-width: 2px;
            border-left-style: solid;
            border-bottom: none;
            border-top: none;
        }
    }

    // LOGO NAVBAR
    .logo-navbar {
        width: 100%;
        text-align: start;
    }

    .mount-container-tango-view {
        .container-mount,
        .card-container-mount {
            .body-card {
                #choose-register-text-desktop {
                    display: block;
                }

                #choose-register-text-mobile {
                    display: none;
                }

                .choose-register-buttons-container {
                    margin-top: 40%;
                }
            }
        }
    }
    .mount-container-tango-view-app-tango {
        .card-container-mount-app-tango {
            .body-card {
                #choose-register-text-desktop {
                    display: block;
                }

                #choose-register-text-mobile {
                    display: none;
                }

                .choose-register-buttons-container {
                    margin-top: 40%;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .hide-mobile {
        display: none;
    }
}
@media only screen and (min-width: 994px) {
    .name-and-surnames {
        padding-left: 15px ;
        padding-right: 15px ;
    }
}

@media only screen and (max-width: 993px) {
    .name-and-surnames {
        padding-left: 0px ;
        padding-right: 0px ;
    }
}

// TABLET
// col-sm
// 768 px
@media only screen and (min-width: $breakpoint-phone) {
    .border-top-card {
        padding-top: 0;
        border-top: none;
    }
    .column-margin-top {
        margin-top: 2rem;
    }
    .dialog-save-charge-lines {
        .MuiDialog-container {
            height: fit-content;
        }
        .MuiPaper-root.MuiDialog-paper {
            top: 0;
            width: 100%;
        }
        .MuiDialogContent-root {
            margin-left: 0;
            padding-left: 5rem;
            padding-right: 5rem;
            display: flex;
            .MuiTableCell-root {
                padding: 0;
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;
            }
        }
    }
}

// ESCRITORIO
// col-md ; col
// 992px

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: $breakpoint-extra-small) {
    .container-home {
        .title-carousel {
            div h1 {
                font-size: 1.6rem !important;
            }
        }
        .title-carousel-images {
            font-size: 1.3rem !important;
        }
    }

    .not-available-visit {
        p {
            font-size: 0.8rem;
        }
        button {
            font-size: 0.8rem;
            height: 2.5rem;
        }
    }

    #customized-dialog-title {
        padding-top: 1.2rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin: 0;
    }

    #customized-dialog-title-with-icon {
        padding: 0;
        margin: 0;
    }

    .step-visit {
        .step-visit__header {
            p {
                font-size: 0.8rem;
            }
        }
    }

    .container-carousel-images {
        .carousel-card {
            .carousel-card__number {
                div {
                    width: 43px;
                    height: 43px;
                }
            }
            .carousel-card__image {
                padding-right: 0;
            }
            .carousel-card__new-image {
                padding-left: 0;
                img {
                    padding: 0;
                    height: auto;
                }
            }
            .carousel-card__actions {
                margin-top: 1rem;
                div {
                    padding: 0 !important;
                    &:last-child div {
                        button {
                            margin-top: 0.8rem;
                        }
                    }
                }
            }
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    .title-container {
        padding: 0;

        .line {
            margin: 0 0 1.875rem 0;
        }

        .title-info {
            padding: 0.75rem 0 0.75rem 0;
        }

        .no-button-back-padding {
            padding-top: 3.75rem;
        }
    }

    .button-back-container {
        padding: 3.5rem 0 1.875rem 0;
    }

    .rounded-circle-notifications-header {
        margin-top: 0.8rem;
    }

    .right-item-list {
        float: none;
        padding-right: 0;
    }

    .left-item-list {
        float: none;
        padding-right: 0;

        .MuiTooltip-popper {
            top: -95px !important;
        }

        .template-navbar {
            .second-navbar {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .nav-bar-container {
            margin-bottom: 2rem;
        }

        .card-timeline-owner .timeline-container {
            .button-action-pending {
                span {
                    display: block;
                    text-align: start;
                }
            }

            .button-action-available {
                margin-top: 2.75rem;
                text-align: center;
            }
        }

        .mr-sm-0 {
            margin-left: 0 !important;
        }

        .button-color .big-button,
        .mount-container-tango-view
            .card-container-mount
            .container-mount
            .button-group-change
            .button-group-selected
            .big-button,
        .row-head-buttons .big-button {
            max-height: 2.8rem;
            width: 46%;
        }

        .big-card,
        .big-card-rented {
            width: 28.75rem;
            max-width: 100vw;
        }

        .timeline-flex-parent .timeline-input-flex-container {
            width: 150%;
        }

        .big-card .unit-card-img,
        .big-card-rented .unit-card-img {
            height: 14.5rem;
        }

        .timeline-flex-parent .timeline-input-flex-container {
            width: 137%;

            span.timeline-text {
                font-size: 0.75rem;
            }
        }

        .card-info-home {
            height: 30rem;
            width: 95vw;
            padding: 1.5rem;
            padding-left: 0;
            padding-right: 0;
        }

        .p-xs-0 {
            padding: 10px;
        }

        .search-container {
            .search-card-container {
                width: 90vw;
            }
        }

        .home-button-container button {
            font-size: 0.75rem;

            .react-multi-carousel-dot-list {
                bottom: 2rem;
            }
        }

        .home-carousel {
            font-size: 0.8rem;
        }

        .contract-html-container {
            .table-responsive h2,
            th,
            td,
            h1,
            h2 {
                font-size: 1rem !important;
            }
        }
    }

    .button-search-container {
        padding-top: 2.5rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: $breakpoint-extra-small) {
    .card-timeline-owner .timeline-container {
        .button-action-pending {
            span {
                display: block;
                text-align: start;
            }
        }

        .button-action-available {
            margin-top: 2.75rem;
            text-align: start;
        }
    }

    .card-info-home {
        width: 90vw;
    }

    .home-arrow-right-mobile {
        position: absolute;
        top: -7rem;
        right: -8rem;

        img {
            width: 19rem;
        }
    }

    .home-arrow-left-mobile {
        top: 2rem;
        left: -8rem;

        img {
            width: 16rem;
        }
    }
}

/* Phone devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: $breakpoint-phone) {
    .buttons-container-options {
        &.max-width-card-price {
            margin-left: auto;
            margin-right: auto;
            max-width: 35.5rem;
        }
        padding-inline: 0;
        button:first-child {
            margin: 0.5rem;
        }
        button:last-child {
            margin: 0.5rem;
            margin-right: 0;
        }
    }
    .sidebar-right {
        font-size: 0.9rem !important;
    }
    .modal-style-sm {
        height: 77vh !important;
    }
    .step-visit .small-button {
        width: auto !important;
    }
    .popup-visit-renter-step-2 {
        .MuiDialog-paperWidthSm {
            width: 95vw !important;
        }
    }

    .lease-renewal-text-left {
        text-align: left;
    }
    .lease-renewal-input-container {
        display: flex;
    }
    .card-timeline-owner .timeline-container {
        .button-action-pending {
            span {
                display: block;
                text-align: start;
            }
        }

        .button-action-available {
            margin-top: 2.75rem;
            text-align: center;
        }
    }

    .big-card .unit-card-img,
    .big-card-rented .unit-card-img {
        height: 20.5rem;
    }

    .big-card,
    .big-card-rented,
    .big-card-rented {
        width: 27.75rem;
        max-width: 100vw;
    }

    .home-arrow-right-mobile {
        top: -7rem;
        right: -10rem;

        img {
            width: 20rem;
        }
    }

    .home-arrow-left-mobile {
        top: -1rem;
        left: -10rem;

        img {
            width: 18rem;
        }
    }

    .card-view-login {
        padding: 5rem 6rem 6rem 6rem !important;
    }
}

@media only screen and (min-width: $breakpoint-phone) and (max-width: $breakpoint-tablet) {
    .container-requisitions {
        width: 100%;
        margin-left: none;
        padding-inline: 1rem;
    }
    .tango-card-requisitions {
        padding-inline: 8rem;
    }

    .promotion-card {
        max-width: 100%;
    }
    .card-price-table {
        margin-inline: auto;
    }
    .container-button-paid {
        margin-inline: auto;
        justify-content: end;
    }

    .margin-top-percentage {
        margin-top: 30%;
    }

    .font-standard {
        font-size: 0.675rem !important ;
    }
    .renewal-card {
        margin: 0;
        min-height: auto;
        .renewal-card-input-container {
            min-height: auto;
            .p-1 h2 {
                font-size: 1rem !important ;
            }
        }
    }
    .renewal-card-input-container .p-1 .px-2 span {
        font-size: 0.875rem !important ;
    }

    .not-available-visit {
        p {
            font-size: 1rem;
        }
    }

    .mount-container-tango-view .card-container-mount .container-mount .container-mount {
        width: 90vw;
        padding: 1rem 1rem 1rem 1rem;
    }

    .body-card {
        border-radius: 20px;
        width: 90vw;
        padding: 1rem 1rem 1rem 1rem;
    }

    .rrss a {
        margin: 5px;
        min-height: 40px;
        min-width: 40px;
    }

    .rrss a i {
        font-size: 30px !important;
    }
}

@media only screen and (min-width: $breakpoint-tablet) {
    .tango-plan-card {
        min-height: 35rem;
        max-height: 40rem;
    }

    .tango-plan-card-content {
        height: 30rem;
    }

    .card-timeline-owner .timeline-container {
        .button-action-pending {
            margin-top: 2.75rem;
            text-align: start;
            margin-left: 3rem;

            span {
                display: block;
                text-align: start;
            }
        }

        .button-action-available {
            margin-top: 2.75rem;
            text-align: start;
            margin-left: 0.5rem;
        }
    }

    .big-card .unit-card-img,
    .big-card-rented .unit-card-img,
    .big-card-rented .unit-card-img {
        height: 12.5rem;
    }

    .container-mount-view {
        width: 99vw;
        height: 50rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: $breakpoint-extra-large) {
    .lease-renewal-radio-up,
    .lease-renewal-radio-options {
        .lease-renewal-text {
            padding: 3.125rem 0 3.125rem 0;
            font-size: 1.25rem !important;
        }
    }
    .lease-renewal-contact-info {
        padding: 2rem 2rem 8rem 2rem;
        font-size: 1rem !important;
    }
    .tango-plan-card-content {
        height: 25rem;
    }

    .card-timeline-owner .timeline-container {
        .button-action-pending {
            margin-top: 2.75rem;
            text-align: start;
            margin-left: 6rem;

            span {
                display: block;
                text-align: start;
            }
        }

        .button-action-available {
            margin-top: 2.75rem;
            text-align: start;
            margin-left: 1.5rem;
        }
    }

    .mw-8r {
        min-width: 8.375rem !important;
    }

    .word-sp-5 {
        word-spacing: 72pt;
    }

    .card-info-home {
        width: 75vw;
    }

    .home-arrow-right {
        position: absolute;
        top: -1.5rem;
        right: -3rem;

        img {
            height: 17rem;
        }
    }

    .home-arrow-left {
        position: absolute;
        left: -0.5rem;
        bottom: -4.3rem;

        img {
            height: 14.5rem;
        }
    }

    .home-arrow-right-middle {
        position: absolute;
        top: 1rem;
        right: -3rem;

        img {
            height: 14rem;
        }
    }

    .home-arrow-left-end {
        position: absolute;
        left: -2.5rem;
        bottom: -4rem;

        img {
            height: 15rem;
        }
    }

    .home-arrow-right-end {
        position: absolute;
        top: -2.5rem;
        right: -2rem;

        img {
            height: 18.5rem;
        }
    }

    .step-container {
        img.steps-img {
            cursor: pointer;
            width: 9rem;
        }
    }
}

@media only screen and (min-width: 0) and (max-width: $breakpoint-tablet) {
    .img-show-renter-post {
        width: 100%;
    }
}

// LAPTOPS WITH SMALL RESOLUTIONS
@media only screen and (min-width: $breakpoint-extra-large) and (max-width: $breakpoint-laptop) {
    .margin-horizontal {
        margin: 0 0.5rem 0 0.5rem;
    }
}

@media only screen and (max-width: $breakpoint-laptop) {
    .select-visit-table-admin {
        width: auto !important;
    }
}

@media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-extra-large) {
    .lease-renewal-contact-info {
        text-align: center;
        padding: 2rem;
        display: flex;
    }

    .background-fifth {
        background-color: white;
    }

    .card-without-shadow {
        background: $color-white !important;
        box-shadow: none !important;
    }

    .margin-horizontal {
        margin: 0 0.25rem 0 0.25rem;
        font-size: 0.8rem;
    }
    .image-carousel-container {
        .image-carousel-line {
            width: 17rem;
        }
    }

    .MuiTooltip-popper {
        top: -40px !important;
    }

    .img-show-renter-post {
        width: 16rem;
    }
}

@media only screen and (min-width: 1500px) {
    .home-header {
        min-height: 65vh;
    }

    .image-carousel-container {
        .image-carousel-line {
            width: 22rem;
        }
    }

    .img-show-renter-post {
        width: 22rem;
    }

    .footer-page-container {
        font-size: 1rem;
    }

    .title-footer {
        font-size: 1rem;
    }

    .footer-home {
        .list-unstyled {
            margin-bottom: 1.5rem;
        }
    }
}

/* Extra extra large devices (large laptops and desktops, 2560px and up) */
@media only screen and (min-width: $breakpoint-xx-large) {
    .card-timeline-owner .timeline-container {
        .button-action-pending {
            margin-top: 2.75rem;
            text-align: start;
            margin-left: 8rem;

            span {
                display: block;
                text-align: start;
            }
        }

        .button-action-available {
            margin-top: 2.75rem;
            text-align: start;
            margin-left: 2.5rem;
        }
    }
}

.home-card {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}

@media only screen and (max-width: $breakpoint-phone) {
    .custom-accept-requisition {
        .custom-padding-inline {
            padding-inline: 0rem;
        }
    }
    .general-popup-with-buttons {
        .MuiDialog-paper {
            margin: 1rem;
            margin-right: 1.4rem;
        }
        .MuiDialog-paperScrollPaper {
            min-width: 15rem;
            width: 100%;
            max-width: 100%;
        }

        #customized-dialog-title {
            padding-left: 1rem;
            padding-right: 1rem;
            p {
                font-size: 1.125rem;
            }
        }
        #customized-dialog-content {
            .confirm-finishi-contrct-popup-container {
                margin-inline: 0;
            }
            padding-left: 1rem;
            padding-right: 1rem;
            span {
                font-size: 1rem;
                line-height: initial;
                text-align: unset;
                text-align-last: unset;
            }
            .buttons-container-options {
                padding-inline: 0;
            }
        }
        &.dialog-modal-container-tango {
            padding-top: 1rem;
        }
    }

    .buttons-container-options {
        button:first-child {
            margin: 0;
            margin-top: 0.5rem;
        }
        button:last-child {
            margin: 0;
            margin-top: 0.5rem;
        }
    }
    .container-requisitions {
        width: 100%;
        margin-left: none;
    }
    .tango-card-requisitions {
        border: none;
        border-top: 2px solid $color-tertiary-light;
        border-radius: 0;
        padding: 1rem;
    }
    .container-button-paid {
        justify-content: center;
        max-width: none;
        button {
            width: 95%;
        }
    }
    .card-price-table {
        margin-inline: 0.5rem;
        max-width: none;
        .card-header {
            span {
                display: block;
                margin-inline: 0rem;
            }
        }
        .card-content {
            margin-inline: 0rem;
        }
        .card-actions {
            margin-inline: 0rem;
            button {
                width: 48%;
            }
        }
    }

    .promotions-popup {
        #customized-dialog-title {
            padding-right: 0;
            padding-left: 1rem;
        }
        .MuiDialog-paper {
            margin: 1.5rem;
        }
        .MuiDialog-container {
            height: 35rem;
        }
        .MuiPaper-root.MuiDialog-paper {
            top: -5.5rem;
        }
    }

    .card-without-shadow {
        background: $color-white !important;
        box-shadow: none !important;
    }
    .container-home {
        .container-sort-table {
            margin-bottom: 5rem;
        }
    }

    .container-home {
        .title-carousel {
            div h1 {
                font-size: 1.6rem !important;
            }
        }
        .title-carousel-images {
            font-size: 1.3rem !important;
        }
    }

    .background-fifth {
        background-color: white;
    }
    .card-view-login {
        padding: 15px;
        width: 100%;
        box-shadow: none !important;
        height: 50vh;
    }

    .p-mb-0 {
        padding: 0;
    }

    .timeline-hidden-text {
        display: none !important;
    }
    .timeline-show-text {
        font-size: 0.75rem;
    }

    .home-search-container > .search-container > .search-card-container {
        background-color: white !important;
        border-radius: 8px !important;
        box-shadow: none !important;
        width: 335px;
    }

    .tango-container-home {
        span {
            font-size: 16px !important;
        }
        padding-top: 0 !important;
        strong {
            font-weight: bold !important;
        }
    }

    .home-card:nth-child(1) {
        margin-bottom: 2rem !important;
    }
    .home-card {
        height: 440px;
        padding: 0 !important;
        margin-top: 0 !important;
        width: 325px;
        margin-left: 0.4rem !important;
        border-radius: 20px;
        .home-card-bottom {
            margin: 0 !important;
            div > button {
                width: 90% !important;
            }
        }
        .home-card-plan > h2 {
            font-size: 1.1rem !important;
        }
        .card-body-home > .body-container {
            .subtitle-body {
                font-size: 1rem !important;
                color: #a9a9aa !important;
            }
            .container-benefits > .card-benefits > .benefits-text {
                p {
                    margin-top: 0.2rem;
                    font-size: 12px;
                }
            }
        }
    }

    .featured-section > .row > div {
        h2 {
            font-size: 1.1rem !important;
            margin: 0;
        }
    }

    .alliances-section {
        height: fit-content;
        h2 {
            font-size: 1.1rem !important;
            margin: 0;
            font-weight: normal;
            margin: 2rem 0 1rem 0;
        }
        .d-flex {
            p {
                width: 83% !important;
                font-size: 12px !important;
            }
        }
        .images img {
            margin: 0 !important;
        }
    }
    .section-testimonial {
        background-color: transparent;
        h2 {
            font-size: 1.1rem !important;
            margin: 2rem 0 0 0;
        }
        .testimonials-container {
            margin-top: 0 !important;
            .react-multi-carousel-item {
                display: flex;
                justify-content: center;
            }
            .react-multiple-carousel__arrow {
                background-color: transparent;
                margin: -1.5rem;
                &:before {
                    color: $color-primary;
                    font-weight: 900;
                    font-size: 2.5rem;
                }
            }
            .testimonial-card {
                width: 99% !important;
                .card-head {
                    padding: 0;
                    div:nth-child(1) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 80px !important;
                            height: 80px !important;
                        }
                    }
                }
                .card-text > p {
                    -webkit-font-smoothing: black;
                }
            }
        }
    }

    .cards-home {
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
    }

    .home-card {
        height: 100%;
    }
    .section-carousel {
        margin-top: 6rem;
        .item-carousel {
            margin-bottom: 2rem;
        }
    }

    .social-media-section {
        span {
            font-size: 1.1rem !important;
            margin: 2rem 0 0 0;
        }
        .social-media-container > .card-social {
            width: 153px !important;
            height: 124px !important;
            max-width: 100% !important;
            flex: 0 0 auto !important;
            margin-top: 1rem;
            img {
                width: 29px;
                height: auto;
            }
            .text-value {
                font-size: 16px;
                margin-bottom: 0;
            }
            .text-label {
                font-size: 12px;
                margin-top: 0;
            }
        }
    }

    .greystar-section {
        background-size: contain;
        height: 28rem;
        background-color: white;
        img {
            margin-top: 3rem !important;
            width: 50%;
        }
        h2 {
            font-size: 1.1rem !important;
            margin: 2rem 0 0 0;
            font-weight: normal !important;
        }
        .text-bottom > span {
            font-size: 1rem !important;
            margin: 2rem 0 0 0;
            width: 95% !important;
        }
        .w-100 > .numbers {
            width: auto !important;
            .properties-number,
            .renters-number,
            .property-countries {
                margin: 0.5rem;
                span {
                    font-size: 1rem;
                }
            }
        }
    }

    .rounded-circle-notifications-header {
        margin-left: 1rem !important;
        width: 1.6rem;
        height: 1.6rem;
        span {
            font-size: 1rem;
        }
    }

    .sidebar-right {
        font-size: 1.2rem;
    }

    .circle-notifier {
        background-color: $color-alert-red;
        border-radius: 50%;
        height: 0.7rem;
        width: 0.7rem;
        position: absolute;
        left: 2rem;
    }

    .lease-renewal-radio {
        padding: 1.5rem 0;
        height: 100%;

        .lease-renewal-text {
            font-size: 0.95rem !important;
        }

        .lease-renewal-text {
            margin-top: 0.6rem;
            padding: 0 0.4rem;
        }

        .lease-renewal-input-container {
            justify-content: center;
        }

        .lease-renewal-input-container.with-height {
            min-height: 5rem;
        }
    }

    .lease-renewal-radio-options {
        font-size: 0.9rem;

        & input[type='radio'] {
            width: 1rem;
            height: 1rem;
            padding: 0.58rem;
        }

        input[type='radio']:checked:before {
            height: 0.82rem;
            width: 0.82rem;
        }
    }

    .lease-renewal-container {
        padding: 0rem 0.6rem;
    }

    .rejected-lease-renewal-container {
        justify-content: center;
        min-height: 5rem;
        padding: 1.5rem 0;
    }
}

@media only screen and (min-width: 992) and (max-width: 1200px) {
    .testimonial-card {
        width: 99% !important;
        height: 90% !important;
        margin-left: 1rem;
        .card-head {
            padding: 0;
            div:nth-child(1) {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 70px !important;
                    height: 70px !important;
                }
            }
        }
    }
    .home-card {
        margin-right: 5rem;
        height: 440px;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .card-view {
        padding: 2rem 0.75rem 0.75rem 0.75rem;
        box-shadow: none !important;
        width: 100vw;
    }

    .p-mb-0 {
        padding: 0;
    }

    .home-search-container > .search-container > .search-card-container {
        background-color: white !important;
        border-radius: 8px !important;
        box-shadow: none !important;
        width: 100vw;
    }

    .tango-container-home {
        span {
            font-size: 16px !important;
        }
        padding-top: 0 !important;
        strong {
            font-weight: bold !important;
        }
    }

    .home-card:nth-child(1) {
        margin-bottom: 2rem !important;
    }
    .home-card {
        padding: 0 !important;
        margin-top: 0 !important;
        margin-left: 0.4rem !important;
        border-radius: 20px;
        .home-card-bottom {
            margin: 0 !important;
            div > button {
                width: 90% !important;
            }
        }
        .home-card-plan > h2 {
            font-size: 1.1rem !important;
        }
        .card-body-home > .body-container {
            .subtitle-body {
                font-size: 1rem !important;
                color: #a9a9aa !important;
            }
            .container-benefits > .card-benefits > .benefits-text {
                p {
                    margin-top: 0.2rem;
                    font-size: 12px;
                }
            }
        }
    }

    .featured-section > .row > div {
        h2 {
            font-size: 1.1rem !important;
            margin: 0;
        }
    }

    .alliances-section {
        height: fit-content;
        h2 {
            font-size: 1.1rem !important;
            margin: 0;
            font-weight: normal;
            margin: 2rem 0 1rem 0;
        }
        .d-flex {
            p {
                width: 83% !important;
                font-size: 12px !important;
            }
        }
        .images img {
            margin: 0 !important;
        }
    }
    .section-testimonial {
        background-color: transparent;
        h2 {
            font-size: 1.1rem !important;
            margin: 2rem 0 0 0;
        }
        .testimonials-container {
            margin-top: 0 !important;
            .react-multi-carousel-item {
                display: flex;
                justify-content: center;
            }
            .react-multiple-carousel__arrow {
                background-color: transparent;
                margin: -1.5rem;
                &:before {
                    color: $color-primary;
                    font-weight: 900;
                    font-size: 2.5rem;
                }
            }
            .testimonial-card {
                width: 99% !important;
                height: 84% !important;
                .card-head {
                    padding: 0;
                    div:nth-child(1) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 80px !important;
                            height: 80px !important;
                        }
                    }
                }
                .card-text > p {
                    -webkit-font-smoothing: black;
                }
            }
        }
    }

    .cards-home {
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
    }
    .home-card {
        height: 100%;
    }
    .section-carousel {
        margin-top: 6rem;
        .item-carousel {
            margin-bottom: 3rem;
        }
    }

    .social-media-section {
        span {
            font-size: 1.1rem !important;
            margin: 2rem 0 0 0;
        }
        .social-media-container > .card-social {
            width: 153px !important;
            height: 124px !important;
            max-width: 100% !important;
            flex: 0 0 auto !important;
            margin-top: 1rem;
            img {
                width: 29px;
                height: auto;
            }
            .text-value {
                font-size: 16px;
                margin-bottom: 0;
            }
            .text-label {
                font-size: 12px;
                margin-top: 0;
            }
        }
    }

    .greystar-section {
        background-size: contain;
        height: 28rem;
        background-color: white;
        img {
            margin-top: 3rem !important;
            width: 50%;
        }
        h2 {
            font-size: 1.1rem !important;
            margin: 2rem 0 0 0;
            font-weight: normal !important;
        }
        .text-bottom > span {
            font-size: 1rem !important;
            margin: 2rem 0 0 0;
            width: 95% !important;
        }
        .w-100 > .numbers {
            width: auto !important;
            .properties-number,
            .renters-number,
            .property-countries {
                margin: 0.5rem;
                span {
                    font-size: 1rem;
                }
            }
        }
    }

    .rounded-circle-notifications-header {
        margin-left: 1rem !important;
        width: 1.6rem;
        height: 1.6rem;
        span {
            font-size: 1rem;
        }
    }

    .sidebar-right {
        font-size: 1.2rem;
    }

    .circle-notifier {
        background-color: $color-alert-red;
        border-radius: 50%;
        height: 0.7rem;
        width: 0.7rem;
        position: absolute;
        left: 2rem;
    }

    .lease-renewal-radio {
        padding: 1.5rem 0;
        height: 100%;

        .lease-renewal-text {
            font-size: 0.95rem !important;
        }

        .lease-renewal-text {
            margin-top: 0.6rem;
            padding: 0 0.4rem;
        }

        .lease-renewal-input-container {
            justify-content: center;
        }

        .lease-renewal-input-container.with-height {
            min-height: 5rem;
        }
    }

    .lease-renewal-radio-options {
        font-size: 0.9rem;

        & input[type='radio'] {
            width: 1rem;
            height: 1rem;
            padding: 0.58rem;
        }

        input[type='radio']:checked:before {
            height: 0.82rem;
            width: 0.82rem;
        }
    }

    .lease-renewal-container {
        padding: 0rem 0.6rem;
    }

    .rejected-lease-renewal-container {
        justify-content: center;
        min-height: 5rem;
        padding: 1.5rem 0;
    }
}

@media only screen and (min-width: $breakpoint-extra-small) and (max-width: $breakpoint-extra-large) {
    .title-container {
        padding: 0;

        .line {
            margin: 0 0 2rem 0;
        }

        .title-info {
            padding: 0.75rem 0 0.75rem 0;
        }

        .no-button-back-padding {
            padding-top: 3.75rem;
        }
    }

    .button-back-container {
        padding: 5rem 0 0 2rem;
    }
}

@media screen and (min-width: $breakpoint-laptop) {
    .section-carousel {
        margin-top: 4rem;
    }
}
