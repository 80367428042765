.contract-html-container {
    h2 {
        margin: 1rem auto;
        text-align: left;
    }
    p {
        text-align: justify;
    }
    margin: auto;
    max-width: 100vw;
    padding: 0 0.5rem;
    .pre-header-paragraph {
        text-align: right;
    }
    .contract__title h1 {
        text-align: center;
        font-weight: 600;
        margin: 1.5rem auto;
    }
    .contract__party {
        margin: 2rem auto;
    }
    .contract__table,
    .contract__second-table {
        width: 100%;
    }
    .contract__table td,
    .contract__second-table td {
        text-align: left;
    }
    .contract__table,
    .contract__second-table,
    .contract__table th,
    .contract__second-table th,
    .contract__second-table td,
    .contract__table td {
        border: 1px solid #999999;
        border-collapse: collapse;
    }

    .contract__table th,
    .contract__table td,
    .contract__second-table th,
    .contract__second-table td {
        padding: 0.19rem 0.4rem;
    }
    .contract__table th {
        text-align: right;
    }
    .contract__second-table th {
        text-align: left;
    }
    .contract__conditions,
    .contract__common_document h2 {
        margin: 2rem auto;
        text-decoration: underline;
        text-align: left;
    }

    .contract__party,
    .contract__property,
    .contract__period,
    .contract__payment,
    .contract__mandate_maintenance,
    .contract__discount {
        margin: 2rem auto;
    }
    .contract__payment {
        .contract__small {
            margin-top: 1rem;
            font-size: 0.8rem;
        }
    }
    .contract__period p {
        margin: 1rem auto;
    }
    .contract-error {
        margin-left: -1.2rem;
    }
    ol {
        counter-reset: list;
        text-align: justify;
    }
    ol > li {
        list-style: none;
        position: relative;
        left: -1rem;
    }
    ol > li:before {
        counter-increment: list;
        content: counter(list, lower-alpha) ') ';
        position: absolute;
        left: -1.5rem;
    }
}
