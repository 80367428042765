$color-secondary: #a8123e;
$color-tertiary: #999999;
$color-fourth: #333333;

.timeline-flex-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .timeline-input-flex-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100px;
        position: relative;
        z-index: 0;
        text-align: center;
    }
    .timeline-input-wrapper {
        flex: 1 0 0%;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .timeline-input {
        width: 20px;
        height: 20px;
        background-color: $color-secondary;
        position: relative;
        border-radius: 50%;
    }
    .timeline-input:hover {
        cursor: pointer;
    }
    .timeline-input-wrapper::before,
    .timeline-input-wrapper::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        background-color: $color-tertiary;
        height: 0.3125rem;
        width: 100%;
    }
    .timeline-input-wrapper.active::after {
        background-color: $color-tertiary !important;
    }
    .timeline-input-wrapper.active::after {
        background-color: $color-secondary;
    }
    .timeline-input-wrapper::before,
    .timeline-input-wrapper::after {
        background-color: $color-secondary;
    }
    .timeline-input-wrapper.inactive::before,
    .timeline-input-wrapper.inactive::after {
        background-color: $color-tertiary;
    }
    .timeline-input-wrapper::before {
        width: 50%;
        left: 0%;
    }

    .timeline-input-wrapper::after {
        width: 50%;
        right: 0%;
    }

    .timeline-input-wrapper:first-child::before {
        width: 0;
    }

    .timeline-input-wrapper:last-child::after {
        width: 0;
    }

    .timeline-input.active {
        background-color: $color-secondary;
    }

    .timeline-input.inactive {
        background-color: $color-tertiary;
        width: 15px;
        height: 15px;
    }

    .timeline-input.active span {
        font-weight: 700;
        color: $color-fourth;
    }
    .timeline-input.active ~ .timeline-input {
        background-color: $color-tertiary;
    }
    .timeline-input span {
        width: 1px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        visibility: hidden;
        font-size: 10px;
        color: $color-tertiary;
    }
    .timeline-input span::before,
    .timeline-input span::after {
        visibility: visible;
        position: absolute;
        left: 50%;
    }
    .timeline-input span::after {
        content: attr(data-type);
        top: 25px;
        transform: translateX(-50%);
    }
}
.timeline-hide-mobile {
    display: none;
}
.timeline-hide-desktop {
    display: block;
}

@media only screen and (min-width: 768px) {
    .timeline-flex-parent {
        .timeline-input span {
            font-size: 12px;
        }
    }
}

@media only screen and (min-width: 992px) {
    .timeline-hide-mobile {
        display: block;
    }
    .timeline-hide-desktop {
        display: none;
    }
    .timeline-flex-parent {
        margin-top: 10%;
        .timeline-input span {
            font-size: 15px;
        }
        .timeline-input-flex-container {
            width: 100%;
        }
        .timeline-input {
            width: 40px;
            height: 40px;
        }
        .timeline-input-wrapper::before,
        .timeline-input-wrapper::after {
            height: 0.75rem;
        }
        .timeline-input.inactive {
            width: 30px;
            height: 30px;
        }
    }
}
