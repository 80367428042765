.input-number-range {
    @extend .p-2;

    width: 100%;
    border-style: solid;
    border-width: 0.08rem;
    border-color: $color-secondary;
    border-radius: 0.313rem;
    font-size: 0.76rem;
}
.input-number-range.input-border-error {
    border-color: #fb0000;
}

.error-text-helper {
    color: #fb0000;
    font-size: 0.8rem;
}
