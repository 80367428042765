.tango-home-search-navbar {
    margin-top: -3rem;
}
.home-header {
    background-size: cover;
    color: $color-white;
    background-position: center center;
    position: relative;
    min-height: 55vh;
    width: 100%;
    text-align: right;
    .MuiPaper-elevation4 {
        box-shadow: none;
        color: $color-white !important;
    }
    .MuiAppBar-colorPrimary {
        background-color: transparent !important;
    }
    .second-navbar {
        text-align: left;
        margin-bottom: 1rem;
        width: 93%;
        margin-left: 3.5%;
        margin-right: 3.5%;
        .title-head {
            margin-left: 2rem;
        }
    }
    .container-navbar {
        h1.home-title {
            font-size: 3rem !important;
            text-shadow: 0.8px 0.8px 0.8px rgb(43, 42, 42);
        }
        .publish-btn {
            padding: auto 4rem;
        }
    }
}

.tango-description-container {
    max-width: 83.75px !important;
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    @extend .mt-5;
    @extend .mx-2;
}

.tango-container-home {
    max-width: 80% !important;
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    @extend .mt-5;
    @extend .mx-2;
    span {
        font-size: 2rem;
    }
}

h2.home-subtitle {
    font-size: 3rem !important;
    font-weight: bold;
    margin: 4rem 0 5rem 0;
}

.home-card {
    @extend .p-0;
    @extend .text-center;
    @extend .post-card-plan;
    @extend .bg-white;
    @extend .col-xl-10;
    @extend .my-3;
}

.home-card-bottom {
    @extend .card-plan-bottom;
    @extend .d-flex;
    @extend .flex-column;
    margin-top: 9rem;
    min-height: 1rem !important;
}

.home-contact-medium {
    @extend .col-xl-3;
    @extend .col-md-4;
    @extend .col-9;
    @extend .my-3;
}

.home-contact-title {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        margin-right: 0.3rem;
    }
}

.card-body-home .body-container {
    .subtitle-body {
        margin-bottom: 1rem;
        font-size: 1.2rem !important;
        color: $color-light-grey !important;
    }
}

.benefits-text {
    font-size: 1.1rem !important;
    p {
        color: black !important;
    }
}

.container-benefits {
    @extend .text-left;
    @extend .mt-4;
}

.section-testimonial {
    @extend .mb-0;
    background-color: #c4c4c4;
}

.greystar-section {
    background-image: url('../../resources/icons/BgMapHome.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 42rem;
    .text-bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        .tango-greystar-text {
            font-size: 2rem;
        }
    }
    .numbers {
        width: 75%;
        display: flex;
        justify-content: space-between;
        span {
            font-size: 2rem;
            font-weight: bold;
        }
    }
}

.alliances-section {
    height: fit-content;
    background: #333333;
    color: white !important;
    p {
        font-size: 2rem;
    }
    .images {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        img {
            margin-right: 8rem;
        }
    }
}

.pl-xs {
    padding-left: 0.313rem !important;
}

.social-media-section {
    @extend .mt-5;
    height: 40rem;
    .title-section {
        font-size: 2rem;
    }
    .social-media-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 4rem;
        .card-social {
            color: black;
            text-decoration: none;
            width: 277.57px;
            height: 16rem;
            background: white;
            border: 0.3px solid #999999;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
            border-radius: 30px;
            margin-right: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex: 0 0 25%;
            max-width: 23% !important;
            .text-value {
                font-size: 1.2rem;
                font-weight: bold;
            }
            .text-label {
                font-size: 1.1rem;
                color: #808080;
                margin-bottom: 0%;
                margin-top: 0%;
            }
        }
    }
}

.testimonials-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2rem 2rem 2rem;
    .testimonial-card {
        background-color: white;
        width: 24%;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        text-align: start;
        margin-top: 2rem;
        .card-head {
            @extend .row;
            padding: 1rem;
            .col-4 {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .text-name {
                font-size: 1rem !important;
                font-weight: bold;
                color: black !important;
            }
            .text-role {
                font-size: 0.8rem !important;
                color: $color-light-grey !important;
            }
        }
        .card-text {
            padding: 1rem;
            color: $color-light-grey !important;
            font-size: 0.95rem !important;
        }
    }
}

.card-body-home {
    height: auto;
}

.cards-home {
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    max-width: 100% !important;
    .body-card-row {
        width: 100%;
        display: flex;
        justify-content: center;
        width: 45vw !important;
    }
}

.home-card-plan {
    border-radius: 35px 35px 0px 0px;
    h2 {
        font-weight: bold;
        font-size: 1.8rem !important;
    }
    height: fit-content;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
    h1 {
        position: relative;
    }
}

.card-benefits {
    display: flex;
    padding: 0.1rem 0.8rem;
    .benefits-check {
        flex: 0;
    }
    .benefits-text {
        flex: 1;
    }
}

.home-paragraph {
    font-size: 1.375rem;
}

.intention-cards-container {
    @extend .row;
    margin: 3rem 0 3rem 0;
    padding-top: 7rem;
}

.intention-button {
    @extend .mx-1;
    @extend .px-4;
    @extend .font-weight-bold;
}

// HOME RENTER
.home-renter-container {
    @extend .container-home;
    .unit-box-columns {
        @extend .card;
        display: flex;
        height: 100%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px !important;
    }
}

.home-carousel {
    .react-multiple-carousel__arrow {
        background-color: transparent;
        &:before {
            color: $color-primary;
            font-weight: 900;
            font-size: 2.5rem;
        }
        &:hover {
            background-color: transparent;
        }
    }
    .item-carousel {
        @extend .row;
        justify-content: center;
        text-align: start;
        p {
            width: 64%;
            margin-bottom: 3rem;
        }
        h1 {
            margin-bottom: 3rem;
            font-size: 3.5rem !important;
            font-weight: bold;
        }
        button {
            width: 40% !important;
        }
    }
    .col-img {
        @extend .col-5;
        @extend .col-md-5;
        display: flex;
        justify-content: start;
    }
    .image-carousel {
        background-color: white;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .col-info {
        @extend .col-7;
        @extend .col-md-5;
        margin-left: 5rem;
    }
    .react-multi-carousel-dot button {
        border-color: transparent;
        background-color: $color-button-carousel;
    }
    .react-multi-carousel-dot--active > button {
        background-color: $color-secondary !important;
        color: #000000 !important;
        width: 13.18px;
        height: 13.18px;
        border-color: transparent;
        margin-right: 1rem;
    }
    .react-multi-carousel-dot > button {
        margin-right: 1rem;
        width: 13.18px;
        height: 13.18px;
    }
}
