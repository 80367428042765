.container-search-cards {
    width: 95% !important;
    margin-left: 5%;
    margin-right: 5%;
}

// CARD RENTER LARGE
.card-large-size {
    width: 15.125rem;
    text-align: center;
    font-size: 1rem;
    cursor: pointer !important;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 2rem;

    .unit-card-info {
        padding: 0.5rem;
        height: 9rem;

        .price {
            font-size: 1.25rem;
        }

        p {
            margin: 0;
        }

        p span {
            text-align: center;
            padding: 0;
            margin-left: 1.5rem;
        }

        .second-price {
            font-size: 0.8rem;
            color: $color-price-secondary;
        }
    }

    .unit-card-img {
        grid-area: img-card;

        .img-card {
            width: 100%;
            height: 9.625rem;
            border-radius: 5px 5px 0px 0px;
            background-color: #c4c4c4;
            object-fit: cover;
        }

        .img-relevant {
            height: 100%;
            width: 2rem;
            right: 2rem;
            position: absolute;
            z-index: 1;
        }
    }
}

.card-large-size-carousel {
    @extend .card-large-size;
    margin-left: auto !important;
    margin-right: auto !important;
}

.card-small-size {
    width: 8rem;
    text-align: center;
    font-size: 0.6rem;
    cursor: pointer !important;
    margin-bottom: 1rem;

    .unit-card-info {
        padding: 0.1rem;
        height: 4.5rem;
        font-family: 'Montserrat', sans-serif !important;

        p {
            margin: 0;
        }

        p span {
            text-align: center;
            padding: 0;
        }

        .template-line-title-card {
            height: 1px;
            margin-top: 2px;
            margin-bottom: 2px;
        }
    }

    .unit-card-img {
        grid-area: img-card;

        .img-card {
            width: 100%;
            height: 5.2rem;
            border-radius: 3px 3px 0px 0px;
            background-color: #c4c4c4;
        }

        .img-relevant {
            height: 2rem;
            width: 1rem;
            right: 1rem;
            position: absolute;
        }

        .img-close {
            height: 1rem;
            width: 1rem;
            right: -0.2rem;
            top: -0.2rem;
            position: absolute;
            z-index: 1;
            cursor: pointer !important;
        }
    }
}

.min-height-container {
    min-height: 40rem;
    max-width: 90rem;
}

.margin-left-container {
    margin-left: 15%;
}

.renewal-cards-container .renewal-card-response {
    min-height: 21.25rem;
    @extend .px-3;
}

.renewal-card {
    position: relative;
    min-height: 380px;
    background-color: #fff;
    overflow: hidden;
    margin: 0.5rem 2rem;
    border-radius: 15px;
    border: 2px solid #999999a2;
    height: 100%;

    &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    & input[type='radio'] {
        -webkit-appearance: none;
        outline: none;
    }

    .renewal-card-input-container {
        width: 100%;
        min-height: 380px;
        cursor: pointer;
        .second-price {
            font-size: 1.125rem;
            color: $color-price-secondary;
        }
    }

    .badge {
        &.red {
            background: $color-secondary;
        }

        &.diagonal {
            white-space: nowrap;
            position: absolute;
            padding: 12px 0px;
            min-width: 300px;
            min-height: 2rem;
            transform: rotate(40deg) translate(42%, 0);
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: bold;
            top: -55px;
            box-sizing: border-box;
        }
    }

    .price-suggestion {
        font-size: 18px;
        margin-top: 1rem;
        margin-bottom: 0;
        span.font-weight-bold.text-color-secondary {
            @extend .px-2;
        }
    }

    .info-container {
        display: flex;
        padding: 0.6rem 1rem;
        align-items: center;
        justify-content: center;

        .info-img {
            flex: 0;
            margin: auto;
        }

        .info-text {
            flex: 1;
            text-align: left;
            font-size: 13.5px;
            margin: auto;
            margin-left: 0.5rem;
        }
    }
}

.renewal-card.active {
    border: 3px solid $color-secondary;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: #e9d0d8;
}

.renewal-card.intitial-border {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border: 3px solid $color-secondary;
}

.desktop-width {
    min-width: 35rem;
    max-width: 50rem;
    width: 100%;
}

.tango-card-component {
    background-color: #fff;
    overflow: hidden;
    margin: 0.5rem 1rem;
    border-radius: 3rem;
    border: 1px solid #c4c4c4;
    max-height: fit-content;
    align-self: center;
    .card-content {
        display: inline-block;
        width: 41.25rem;
        height: auto;
        justify-content: center;
        border-radius: 1.25rem;
        background: $color-white;
        max-width: fit-content;
        max-height: fit-content;
        padding-inline: 1.5rem;
        padding-block: 3rem;
    }
}
.card-body-relevant {
    width: 100%;
    padding: 1rem;
    border: 1px solid #c4c4c4;
    border-radius: 5rem;
}

.tango-plan-card {
    position: relative;
    min-height: 30rem;
    background-color: #fff;
    overflow: hidden;
    margin: 0.5rem 1rem;
    border-radius: 15px;
    border: 2px solid #999999a2;

    &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    & input[type='radio'] {
        -webkit-appearance: none;
        outline: none;
    }

    .tango-plan-card-input-container {
        width: 100%;
        min-height: 32rem;
        cursor: pointer;
    }

    .info-container {
        display: flex;
        padding: 0.6rem 1rem;
        align-items: center;
        justify-content: center;

        .info-img {
            flex: 0;
            margin: auto;
        }

        .info-text {
            flex: 1;
            text-align: left;
            font-size: 13.5px;
            margin: auto;
            margin-left: 0.5rem;
        }
    }
}

.tango-plan-card.active {
    border: 3px solid $color-secondary;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: #e9d0d8;
}

.tango-plan-card.intitial-border {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border: 3px solid $color-secondary;
}

.tango-plan-card.selected-option:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border: 2px solid $color-secondary;
}

.tango-plan-card-content {
    font-size: small;
    height: 25rem;
}

.tango-plan-content-title {
    height: 3rem;
    padding-bottom: 4rem;
}

.tango-plan-small-card-title {
    @extend .pt-2;
    @extend .pl-3;

    h3 {
        @extend .pt-2;
        @extend .pl-3;
        text-align: left;
    }
}

.tango-card {
    background: $color-white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 0.5rem;
    padding: 1.7rem;
    margin: 0.5rem 1.5rem 0.5rem 1.5rem;

    h5 {
        text-align: left;
        font-size: 1.4rem !important;
    }

    .contact-data-container {
        text-align: justify;
        margin: 3rem 0 3rem 0;
    }
}

.intention-cards-container > :first-child {
    .icon-card {
        margin-left: auto;
        margin-right: 1.2rem;
    }
}

.intention-cards-container > :last-child {
    .icon-card {
        margin-right: auto;
        margin-left: 1.2rem;
    }
}

.icon-card {
    background: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1.25rem;
    width: 70%;
    padding: 2.5rem 2rem 2.5rem 2rem;

    .icon-card-title-container {
        text-align: left;
    }

    img {
        margin: 1.8rem 0 1.8rem 0;
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.15);
    }

    h5 {
        font-size: 1.5rem !important;
        text-decoration: none;
        color: $color-primary;
    }
}

.contact-data {
    @extend .col-md-8;
    @extend .p-0;

    p {
        word-wrap: break-word;
    }
}
.tango-card-requisitions {
    border: 1px solid $color-tertiary-light;
    border-radius: 0.5rem;
    width: 100%;
    justify-content: start;
    padding: 2.5rem;
    padding-inline: none;
    .card-title {
        font-weight: bold;
        text-align: left;
    }
}

.card-price-table {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 0px 0px 12px 12px;
    max-width: 35.5rem;
    .card-header {
        background: $color-secondary;
        text-align: left;
        span {
            display: flex;
            margin-inline: 2rem;
            color: #ffffff;
            &:nth-child(1) {
                font-weight: bold;
            }
        }
    }
    .card-content {
        margin-inline: 2rem;
    }
    .card-actions {
        margin-inline: 2rem;
        @extend .d-flex;
        @extend .justify-content-end;
        button {
            box-shadow: none;
            border: 1px solid black;
            width: 30%;
            border-radius: 3rem;
            padding-inline: 1.5rem;
        }
    }
}

.border-top-card {
    border-top: solid 1px gray;
    padding-top: 1rem;
}

.promotion-card {
    @extend .card;
    @extend .mb-2;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);

    max-width: clamp(400px, 70ch, 50vw);
    h2 {
        margin-block-end: 0.5rem;
        color: var(--color-secondary);
    }
    text-align: start;
    hr {
        border-color: var(--color-secondary);
    }
    .promotion-plan-address {
        color: var(--color-secondary);
    }

    dl {
        display: grid;
        grid-template-columns: 7fr 3fr;
        :where(dt, dd) {
            text-align: start;
        }
        margin-bottom: 0.25rem;
    }
    section.plan-kind {
        text-align: start;
    }
    .row {
        section {
            @extend .col-12;
        }
    }
    .promotion-card-plan-detail {
        position: relative;
        &:not(:last-child) {
            &:after {
                position: absolute;
                bottom: 0;
                border-bottom: var(--color-muted) 1px solid;
                width: 90%;
                left: 5%;
            }
        }
    }
}

.card.card-container-motive-details {
    border-radius: 1.125rem;
    border-width: 0;
    span {
        font-size: 1rem;
    }
    .card-body {
        .card-title {
            font-weight: bold;
            text-align: start;
            margin-bottom: 0;
        }
        .MuiFormHelperText-contained {
            margin-left: 0;
            margin-right: 0;
            font-size: 0.875rem;
        }
        .MuiOutlinedInput-multiline {
            border-radius: 1.125rem;
        }

        button {
            width: 100%;
        }
    }
}

@media (min-width: 768px) {
    .card.card-container-motive-details {
        border-width: 1px;
        .card-body {
            padding-inline: 5rem;
            padding-bottom: 2rem;
            padding-top: 3rem;

            button {
                width: 50%;
            }
        }
    }
}

.periods-contract-title {
    text-align: left;
    color: #6b6b6b;
}

.early-lease-termination {
    @extend .row;
    width: 100%;
}

.early-lease-termination-detail {
    @extend .col-12, .col-lg-6, .text-left, .pr-3, .mb-md-4, .mb-lg-0;
    .secondary-price {
        color: $color-price-secondary;
    }
}

.early-lease-solicitud-card {
    @extend .col-12, .col-lg-6, .border-top-card;
}
