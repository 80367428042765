// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
body {
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif !important;
    #root {
        min-height: 100vh;
        .App {
            min-height: 100vh;
            display: grid;
            grid-template-columns: minmax(100vh, auto);
            grid-template-rows: auto;
            grid-template-areas: 'root';
            .principal-container {
                grid-area: root;
                display: flex;
                flex-direction: column;
                min-height: 90vh;
                max-width: 100vw;
                justify-content: flex-start;
            }
            input[type='search']::-webkit-search-cancel-button {
                cursor: pointer;
                -webkit-text-fill-color: red !important;
            }
        }
    }
    .back-ground-line-diagonal {
        background-image: -webkit-linear-gradient(109deg, white 50%, #f6f6f6 50%);
    }
    input,
    button,
    select,
    optgroup,
    textarea {
        font-family: 'Montserrat', sans-serif !important;
    }
    .MuiTypography-body1 {
        font-family: 'Montserrat', sans-serif !important;
    }
    .MuiTableCell-root {
        font-family: 'Montserrat', sans-serif !important;
        font-size: 1rem;
    }
    .MuiFormLabel-root {
        font-family: 'Montserrat', sans-serif !important;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.68);
    }
    .MuiFormHelperText-root {
        font-family: 'Montserrat', sans-serif !important;
    }
}
code {
    font-family: 'Montserrat', sans-serif;
}
