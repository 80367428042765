// TOAST
.Toastify {
    .Toastify__toast--default {
        border-radius: 10px 10px 10px 10px !important;
        color: $color-primary !important;
        background: $color-fifth !important;
        font-family: 'Montserrat', sans-serif !important;
    }
    .Toastify__close-button--default {
        opacity: 1 !important;
        color: $color-secondary !important;
    }
}
