// TIME PICKER
.rc-time-picker-input {
    height: 2rem !important;
    font-size: 1rem !important;
    color: currentColor !important;
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    border: 1px solid #c1c5c8 !important;
}
.rc-time-picker-panel {
    z-index: 1301 !important;
}
.full-width {
    width: 100%;
    z-index: -1;
}

.input-time {
    .MuiInputBase-input.MuiInput-input::-webkit-datetime-edit-ampm-field {
        display: none;
    }
    input[type='time']::-webkit-clear-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;
        appearance: none;
        margin: -10px;
    }
}
