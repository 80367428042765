.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HOME Styles */
.home-container {
  .home-body {
    .option {
      text-align: center;
      height: 240px;
      width: 96%;
    }
  }
}

/* Posts index */
.posts-index-container {
  h1 {
    text-align: start;
  }
  .posts-index-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      font-size: 2.4rem;
    }
  }
  .posts-index-body {
    display: flex;
    flex-wrap: wrap;
  }
}

/* Renter validation */

.renter-validation {
  padding: 3.4rem 0rem;
  h2 {
    text-align: start;
  }
  .documents-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .bottom-buttons {
    display: flex;
    justify-content: space-between;
    margin: 0rem 3.6rem;
  }
  .bottom-buttons-rows {
    text-align: start;
  }
}

.button-floating {
  display: block; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */

  button:focus {
    outline: 1px dotted;
    outline: none;
  }
}

.button-floating-center {
  display: block; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 0rem; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */

  button:focus {
    outline: 1px dotted;
    outline: none;
  }
}

.hidden {
  display: none;
}

.invisible-button{
  background: white !important;
  border: white !important;
  box-shadow: none !important;
}
