// PAGINATOR
.paginator-principal-view {
    .MuiPaginationItem-outlined {
        color: transparent;
        margin-right: 5px;
        font-size: 1px;
    }
    .MuiPaginationItem-outlined :not(svg) {
        color: #a8123e;
    }
    [aria-label='Go to previous page'] {
        border-style: hidden;
    }
    [aria-label='Go to next page'] {
        border-style: hidden;
    }
    .Mui-selected {
        background-color: #a8123e !important;
        color: transparent !important;
        border: 1px solid #a8123e !important;
    }
    .MuiPaginationItem-sizeSmall {
        height: 18px;
        min-width: 18px;
    }
}
