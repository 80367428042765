// TITLES
h1 {
    font-size: 1.75rem !important; // 28px
}
// SUBTITLES
h2 {
    font-size: 1.375rem !important; // 22px
}
h3 {
    font-size: 1.125rem !important; // 18px
}
p.h6 {
    font-size: 0.813rem !important; // 13px
}
