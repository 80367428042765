.video-player {
    cursor: pointer;
    width: 500px;
    max-width: 85vw;
    max-height: 19rem;
}

.video-player::-webkit-media-controls-panel {
    background-color: transparent;
}
.video-player:focus {
    outline: none;
}
