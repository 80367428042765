// CAROUSEL
.image-carousel-container {
    .image-carousel-line {
        height: 13rem;
        width: 19rem;
        max-width: 100%;
        max-height: 100%;
        border: 1px solid #ddd;
        border-radius: 5px;
        cursor: pointer;
        object-fit: cover;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(-4% + 1px);
    }
    .react-multiple-carousel__arrow--right {
        right: calc(-4% + 1px);
    }
    .react-multiple-carousel__arrow:hover {
        background-color: transparent;
    }
    .react-multiple-carousel__arrow::before {
        color: $color-secondary;
        font-weight: 900;
        font-size: 2.3rem;
    }
    .react-multiple-carousel__arrow:hover {
        background-color: transparent;
    }
    .react-multiple-carousel__arrow {
        background-color: transparent;
        z-index: 10;
    }
    button:focus {
        outline: none;
    }
}

.item-carousel-container {
    @extend .image-carousel-container;
    margin-bottom: 3rem;
    width: 95%;
    .react-multiple-carousel__arrow--left {
        display: none;
        left: calc(-1% + 2px);
        top: 8rem;
    }
    .react-multiple-carousel__arrow--right {
        display: none;
        right: calc(-1% + 1px);
        top: 8rem;
    }
    .react-multi-carousel-track {
        margin-bottom: 3rem;
    }
    .react-multiple-carousel__arrow:hover {
        background-color: transparent;
    }
    .react-multiple-carousel__arrow::before {
        color: $color-secondary;
        font-weight: 900;
        font-size: 2.3rem;
    }
    .react-multiple-carousel__arrow:hover {
        background-color: transparent;
    }
    .react-multiple-carousel__arrow {
        background-color: transparent;
    }
    .react-multi-carousel-dot--active button {
        background-color: $color-secondary;
    }
    .react-multi-carousel-dot button {
        border-color: transparent;
    }
    .react-multi-carousel-list {
        align-items: baseline;
    }
}
.font-disabled {
    color: $color-block-time;
}
.item-carousel-container.show-arrows {
    .react-multiple-carousel__arrow--left {
        display: block;
    }
    .react-multiple-carousel__arrow--right {
        display: block;
    }
}

// Renter's carousel image from property view
img.react-images__view-image--isModal {
    object-fit: contain;
    max-height: 100vh;
}

.cursor-activated {
    cursor: pointer;
}

.desktop-height {
    height: 42.5rem;
}

.tablet-height {
    height: 100%;
}

.tablet-height-width {
    @extend .tablet-height;
    img {
        width: 100%;
        height: 100%;
    }
}

.mobile-height {
    height: 20.75rem;
}

.banner-height {
    height: 17.875rem;
}

.banner-height-width {
    @extend .banner-height;
    img {
        width: 100%;
    }
}

.reduced-carousel-width {
    width: 60% !important;
}
