//Abstracts
@import 'abstracts/variables';

.div-asdasd { color: red; }
// Pages
@import 'pages/home';
@import 'pages/aboutUs';
@import 'pages/ownerHome';

// Components
@import 'components/table';
@import 'components/buttons';
@import 'components/toastify';
@import 'components/modal';
@import 'components/newModal';
@import 'components/cards';
@import 'components/timePicker';
@import 'components/paginator';
@import 'components/carousel';
@import 'components/contractHTML';
@import 'components/documentHTML';
@import 'components/videoPlayer';
@import 'components/checkboxes';
@import 'components/inputs';
@import 'components/inputRange';
@import 'components/selects';
@import 'components/sidebar';
@import 'components/lists';
@import 'components/numberedTable';
@import 'components/payment_method_component';
@import 'components/alertMessage';
@import 'components/statusModal';
// Layouts
@import 'layout/navbar';
@import 'layout/footer';

// Base
@import 'base/typography';
@import 'base/reset';
@import 'base/base';
@import 'base/safari';

// Medias
@import 'medias/medias';

//selects
.select-input {
  @extend .col-12;
  @extend .plm-0;
  @extend .pr-0;
}

.select-input {
  @extend .col-12;
  @extend .plm-0;
  @extend .pr-0;
}

.select-no-border {
  border: 0;
  border-color: transparent;
  background-color: transparent;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../resources/icons/Down.png');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.813rem, 0.375rem;
  option:hover {
    background-color: $color-fourth;
  }
}
.select-no-border:focus {
  outline: none;
}
.select-no-border option:checked,
.select-no-border option {
  filter: hue-rotate(90deg);
}

.select-border {
  border: 2;
  border-color: #999999;
  background-color: transparent;
  border-radius: 0.313rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../resources/icons/Down.png');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.813rem, 0.375rem;
  option:hover {
    background-color: $color-fourth;
  }
}
.select-border-filter{
  filter: grayscale(100%);
  select {
    padding-top : 0.25rem;
    padding-bottom : 0.188rem;
  }
}
.select-border:focus {
  outline: none;
}
.select-border option:checked,
.select-border option {
  filter: hue-rotate(90deg);
}

// Temporary solution until we figure out
// a definite solution for the background-image url problem
.select-style {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  border: 1px solid;
  border-color: $color-secondary;
  background-color: $color-white;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../resources/icons/Down.png'),
    linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.813rem, 0.375rem;
  option:hover {
    background-color: $color-fourth;
  }
}
.select-style:focus {
  outline: none;
}
.select-style option:checked,
.select-style option {
  filter: hue-rotate(90deg);
}

.show-renter-select-style {
  @extend .select-style;
  @extend .ml-2;
  @extend .col-12;
  padding-top: 0.07rem;
  padding-bottom: 0.07rem;
}

.disabled {
  border: 1px solid #adadad;
  background-image: url('../resources/icons/DownDisabled.png'),
    linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.813rem, 0.375rem;
}

.btn-img {
  background-image: url('../resources/icons/IconFloatSave.svg');
  background-repeat: no-repeat, repeat;
  background-size: 3.125rem, 3.125rem;
  height: 3.125rem;
  width: 3.125rem;
  background-color: transparent;
  border: none;
}
