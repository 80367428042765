// TABLE MARGIN
.table {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

// TABLE
.MuiTableCell-head {
    color: $color-white !important;
}
.MuiTableCell-body {
    color: $color-tertiary !important;
}
.table-header {
    border: none !important;
    background-color: #a8123e !important;
    vertical-align: middle !important;
    text-align: left !important;
}
.table-header-padding {
    padding-left: 1rem;
    padding-right: 1rem;
}
.table-border-top-left {
    border-top-left-radius: 0.5rem !important;
}
.table-border-top-right {
    border-top-right-radius: 0.5rem !important;
}
.table-border-right {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
}
.table-border-left {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
}
.table-content {
    @extend .table-header;
    vertical-align: top !important;
    background-color: #fff !important;
    .button-show-detail {
        @extend .button-fourth;
        width: 7rem;
        height: 1.1rem;
        white-space: nowrap;
    }
    .button-validate {
        @extend .button-show-detail;
        @extend .button-primary;
    }
    .button-show-detail-vouchers {
        @extend .button-admin-tab;
        width: 7rem;
        height: 1.1rem;
        white-space: nowrap;
    }
    .button-vouchers {
        @extend .button-show-detail-vouchers;
        @extend .button-primary;
    }
}
.table-content-center {
    @extend .table-content;
    text-align: center !important;
}

.table-content-start {
    @extend .table-content;
    text-align: start !important;
}

.table-content-vertical-center {
    @extend .table-content;
    vertical-align: middle !important;
}
.table-content-vertical-center {
    @extend .table-content;
    vertical-align: middle !important;
}

// BORDERS GENERAL
#first-row :nth-child(1) {
    @extend .table-border-left;
}

#first-row :nth-last-child(1) {
    @extend .table-border-right;
}

// BUTTON TABLE
.table-buttons {
    padding: 0.5rem !important;
    border-top: #ffffff !important;
    border-bottom: #ffffff !important;
}

.component-list {
    float: left;
}
