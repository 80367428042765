.about-us-container {
    padding-top: 8rem;
}
.about-us-paragraph {
    font-size: 1.375rem;
}
.about-us-card {
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.25);
    border-radius: 2.1875rem;
    .middle-card {
        background-color: $color-secondary;
        color: $color-white;
    }
    h2.card-title {
        font-size: 1.8rem !important;
    }
}
.tango-red-belt {
    background-color: $color-secondary;
    width: 100vw;
    color: $color-white;
    .thin-column {
        flex: 1;
    }
}

.secondary-border-bottom {
    position: relative;
}

.secondary-border-bottom::after {
    content: ' ';
    position: absolute;
    bottom: calc(-1rem - 1px);
    left: 50%;
    width: calc(100% - 30px);
    height: 1px;
    border-top: 1px solid $color-secondary;
    transform: translate(-50%, -50%);
}
