// @import 'bootstrap';
@import '~bootstrap/scss/bootstrap';

::-webkit-scrollbar {
    width: 0.6rem;
}

body {
    scrollbar-width: thin;
    scrollbar-color: $color-secondary $color-button-change;

    color: $color-fourth !important;
}

.background-fifth {
    background-color: #f7f7f7;
}

::-webkit-scrollbar-track {
    background: $color-button-change;
}

::-webkit-scrollbar-thumb {
    background-color: $color-secondary;
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.principal-container-bg-gray {
    @extend .principal-container;
    background-color: darken($color: white, $amount: 2);
}

.text-select {
    color: $color-secondary;
    cursor: pointer;

    &:hover {
        color: darken($color: $color-secondary, $amount: 10);
    }
}

.container-home-100 {
    width: 100% !important;
}

.container-notfound-view {
    height: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

.container-cosigner-signtimeout-view {
    display: flex;
    justify-content: center;
}

.vertical-direction {
    flex-direction: column;
}

.inline-circlecheck-logo {
    width: 32px;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.timeout-logo {
    max-width: 350px;
    width: 100%;
    height: auto;
}

.centered-text {
    text-align: center;
}

.cosigner-signtimeout-container {
    grid-gap: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 834px;
    margin: 0px 10px 75px;
    display: flex;
}

.cosigner-signsucessful-container {
    grid-gap: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 834px;
    margin: 50px 10px 75px;
    display: flex;
}

.tango-can-do-width {
    max-width: 561px;
    align-self: center;
}

.notfound-container {
    grid-gap: 20px;
    gap: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 440px;
    margin: 62px 10px 137px 10px;
    display: flex;
}

.notfound-item {
    display: flex;
    justify-content: center;
}

.notfound-text {
    padding: 0px 24.5px;
}

.notfound-logo {
    width: 100%;
    height: auto;
}

.circlecheck-padding-right {
    padding-right: 11px;
}

.notfound-backbutton {
    max-width: 350px;
    width: calc(100% - 8px);
}

// IMAGE THANK_YOU
.thank-you-image {
    width: 80%;
    margin: auto;
    margin-top: 3rem;

    img {
        max-width: 100%;
        border-radius: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        width: fit-content;
    }
}

// FONT SIZE THANK_YOU
.text-body-size {
    font-size: x-large;
}

.popup-medium-font {
    font-size: medium;
}

// MARGIN NAVBAR
.container-navbar,
.container-footer {
    width: 100%;
}

// MARGIN HOME
.container-home {
    width: 79%;
    margin-left: 10.5%;
    margin-right: 10.5%;
    padding-bottom: 1.5rem;
}

.contracts-container {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
}

.contract-states {
    @extend .row;
    text-align: left;
    margin-top: 1rem;
    min-height: 20vh;
}

.contract-states-list {
    list-style: none;
    font-size: 0.78rem;
    line-height: 1.4;
}

// BTN BG DISABLED
.btn-disabled {
    background-color: lighten($color: $color-block-time, $amount: 16) !important;
}

// LOGO NAVBAR
.logo-navbar {
    width: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.bg-calendar-instapage {
    background: $color-white !important;
    border-radius: 10px;
}

.not-available-visit {
    @extend .mt-5;
    p {
        font-size: 1.25rem;
        color: $color-dark-gray;
    }
    button {
        @extend .col-md-10;
        @extend .col-12;
        @extend .button-primary;
    }
}

.w-10 {
    width: 10% !important;
}

.select-span:hover {
    cursor: pointer;
    color: $color-secondary;
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.pointer {
    cursor: pointer;
}

.link-span:hover {
    cursor: pointer;
    font-weight: bolder;
    color: $color-primary;
    text-decoration-line: underline;
}

// MARGIN NAVBAR
.container-navbar {
    width: 100%;
}

.d-inline-grid {
    display: inline-grid;
}

// LOGO NAVBAR
.logo-navbar {
    width: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.underline-link {
    cursor: pointer;
    text-decoration: underline !important;

    &.text-color-dark-gray:hover {
        color: $color-fourth !important;
    }

    color: $color-fourth !important;
}

.link-login {
    @extend .color-secondary;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        color: darken($color: $color-secondary, $amount: 10);
    }
}

.link-login-black {
    cursor: pointer;
    color: black;
    text-decoration: underline;

    &:hover {
        color: darken($color: $color-primary, $amount: 10);
    }
}

.negative-m-1 {
    margin: -0.5rem -0.3rem -0.5rem -1.8rem;
}

.negative-m-2 {
    margin: -1rem -0.5rem -1rem -3rem;
}

// TABLE
.table-tango {
    table {
        text-align: left;

        thead {
            td {
                border-style: hidden;
                color: $color-secondary;
                padding-bottom: 2rem;
            }
        }

        tbody {
            tr {
                border-bottom: 2px;
                border-bottom-style: solid;
                border-bottom-color: $color-secondary;
                border-right: 0;
                border-left: 0;
                color: $color-tertiary;
            }
        }
    }
}

// VIDEO CAROUSEL

// END VIDEO CAROUSEL

// TITLES
h1 {
    font-size: 1.75rem !important; // 28 px
}

// SUBTITLES
h2 {
    font-size: 1.375rem !important; // 22 px
}

h3 {
    font-size: 1.125rem !important;
}

h5 {
    font-size: 0.75rem !important;
}

.tango-h6 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
}

// BACKGROUND COLOR TANGO
.bg-color-tango {
    background-color: $color-secondary;
}

.gray-background {
    background-color: #999999;
}

// TEXT RED
.span-text {
    text-align: start;
    padding-left: 0rem !important;
    color: $color-secondary !important;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin-bottom: 0rem;
}
.new-modal-span-field-title {
    text-align: start;
    padding-left: 0rem !important;
    color: $color-medium-gray !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 0rem;
}

.span-text-no-uppercase {
    @extend .span-text;
    text-transform: none;
}

.span-weight {
    font-weight: 550;
}

.span-text-sign-up {
    text-align: start;
    padding-left: 0rem !important;
    color: #999999 !important;
    line-height: 1.25rem;
    margin-bottom: 0rem;
}

.p-label {
    color: $color-tertiary !important;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.375rem;
}

.select-currency {
    width: 100%;
    text-align: center;
    height: 2.625rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid $color-tertiary;
    text-transform: uppercase;
    border-right-style: hidden;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .MuiSelect-root {
        color: $color-tertiary;
    }

    .MuiSelect-icon {
        color: $color-tertiary;
        font-size: 1.8rem;
        margin-right: 8%;
        pointer-events: none;
        top: inherit;
    }
    &.no-border-radius {
        border-radius: 0.25rem 0 0 0.25rem;
        height: 2.45rem;
    }
}

.select-currency-color-secondary {
    @extend .select-currency;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: $color-secondary;
    height: 2rem;
}

.select-currency:focus {
    outline: none;
}

.select-currency-alert {
    border-color: $color-alert;
}

.invalid-input-alert {
    border-color: $color-alert !important;
    input:focus {
        border-color: white !important;
    }
}

.alert-input {
    text-align: left;
    width: 300%;
    color: $color-alert;
    font-size: small;
}

.max-width-input {
    @media only screen and (max-width: $breakpoint-phone) {
        max-width: 15%;
    }
    @media only screen and (max-width: 1025px) and (min-width: $breakpoint-phone) {
        max-width: 20%;
    }
    @media only screen and (max-width: $breakpoint-laptop) and (min-width: 1025px) {
        max-width: 40%;
    }
}

.input-currency {
    height: 2.625rem;
    border: 0.0625rem solid $color-tertiary;
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: $color-tertiary;
}

.input-currency:focus {
    outline: none;
}

.input-currency-alert {
    border-color: $color-alert;
}

.price-validation {
    @extend .row;
    @extend .input-container;
    @extend .d-flex;
    @extend .col-sm-9;
    @extend .col-8;
    @extend .col-lg-8;
    @extend .align-items-start;
    @extend .input-currency;
    &.no-border-radius {
        border-radius: 0 0.25rem 0.25rem 0;
        height: 2.45rem;
    }
}

.price-validation-color-secondary {
    @extend .price-validation;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: $color-secondary;
    height: 2rem;
}

.price-validation-enabled {
    @extend .price-validation;
    @extend .input-currency-alert;
}

.title-red {
    text-align: start;
    padding-left: 0rem !important;
    color: $color-secondary !important;
    line-height: 20px;
    margin-bottom: 0rem;
}

.text {
    text-align: start;
    padding-left: 0rem !important;
    line-height: 20px;
    margin-bottom: 0rem;
}

.margin-title {
    margin-bottom: 2rem !important;
}

.lh-2 {
    line-height: 1.7rem;
}

.thank-you-instapage {
    position: relative;
    top: -8rem;
}

.text-white {
    text-align: start;
    padding-left: 0 !important;
    line-height: 20px;
    color: $color-white;
    margin-bottom: 0rem;
}

.text-white-bold {
    color: $color-white;
    font-size: 2rem;
    @extend .font-weight-bold;
}

.text-white-bold-iframe {
    font-size: 1rem;
    text-transform: uppercase;
}

.text-color-white {
    color: $color-white;
}

.text-color-black {
    color: black;
}

// LOGOS
.check-logo,
.delete-logo {
    cursor: pointer;
    width: 1.875rem;
    height: 1.875rem;
}

.upload-logo {
    cursor: pointer;
    width: 2.6rem;
    height: 2.6rem;
}

.info-cosigner {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #1a1a1a;
}

.delete-logo-img-video {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.arrow-logo-container {
    width: 3rem;
    height: 3rem;
    background-color: $color-white;
    border-radius: 0rem;
    border: none;
    box-shadow: none;
}

.arrow-logo {
    cursor: pointer;
}

.arrow-section {
    transform: rotate(-90deg);
    // animation: rotateArrowDown 0.3s ease-in both;
}

.arrow-section-up {
    transform: rotate(90deg);
    animation: rotateArrowUp 0.3s ease-in both;
}

@keyframes rotateArrowUp {
    from {
        transform: rotate(-90deg);
    }

    to {
        transform: rotate(90deg);
    }
}

@keyframes rotateArrowDown {
    from {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(-90deg);
    }
}

.btn-day-owner {
    font-size: 0.72rem;
    margin: 0.5rem !important;
}

.align-self-sm-none {
    align-self: auto !important;
}

// LOGO TANGO
.img-logo {
    width: 7.3rem;
    cursor: pointer;
    z-index: 10;
}

.small-img-logo {
    height: 2.5rem;
    width: 7.5rem;
    cursor: pointer;
    z-index: 10;
}

.medium-img-logo {
    width: 6rem;
    cursor: pointer;
    z-index: 10;
}

// FOR H2 SUBTITLES
.new-subtitle {
    text-align: start;
    font-style: normal;
    font-weight: normal;
    line-height: 34px;
    align-items: center;
    margin-bottom: 0.9rem;
}

.new-subtitle-list {
    text-align: start;
    font-style: normal;
    font-weight: normal;
    align-items: center;
    margin-bottom: 0.9rem;
}

.header-calendar {
    background-color: #a8123e !important;
    height: 3.2rem;
    border-radius: 1rem;
}

.max-height-modal {
    max-height: 16.04rem;
    overflow: auto;
    padding: 1rem;
}

.template-line-gray {
    width: 5rem;
    height: 0.031rem;
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    padding: 0.063rem;
    background-color: #999999;
}

.vertical-line {
    height: 3rem;
    margin-left: 1.9rem;
    width: 0.3rem;
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    padding: 0.063rem;
    background-color: #d3d3d3;

    &:ntd-child(3) {
        height: 0rem !important;
        width: 0rem !important;
    }
}

// TEMPLATE LINE
.template-line {
    width: 5rem;
    height: 0.5px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 1px;
    background-color: $color-secondary;
}

.template-line-home {
    @extend .template-line;
    width: 7.25rem;
    margin: auto;
}

.template-line-title {
    @extend .template-line;
    height: 2px;
    padding: 2px;
}

.template-line-title-center {
    @extend .template-line-title;
    width: 7.125rem;
}

.template-line-title-card {
    @extend .template-line-title;
    width: 90%;
    height: 2px;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

.template-line-input {
    margin-left: 0.75rem;
    width: 90%;
    height: 0.2px;
    background-color: $color-secondary;
}

.template-line-100 {
    width: 100%;
    background-color: $color-secondary;
    height: 0.5px;
    margin-bottom: 12px;
}

.template-line-center-color-tertiary {
    height: '1px';
    border: 'none';
    background-color: $color-tertiary;
}

// TEMPLATE LINE OR
.template-line-or-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.5rem;

    span {
        color: $color-secondary;
        margin: auto;
        text-align: center;
        width: 10%;
    }
}

.template-line-or {
    background-color: $color-secondary;
    height: 0.1px;
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
}

.template-line-or-left {
    @extend .template-line-or;
    justify-content: left;
    width: 48%;
    background-color: $color-tertiary;
}

.sidebar-right {
    font-size: 16px;

    &:hover {
        border-bottom: 0px !important;
        background-color: transparent !important;
        cursor: default;
    }
}

.template-line-or-right {
    @extend .template-line-or;
    justify-content: right;
    width: 48%;
    background-color: $color-tertiary;
}

// TEMPLATE LINE TANGO APP
.template-line-app-tango {
    width: 5rem;
    height: 0.5px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 1px;
    background-color: $color-secondary;
}

.template-line-home-app-tango {
    @extend .template-line;
    width: 7.25rem;
    margin: auto;
}

.template-line-title-app-tango {
    @extend .template-line;
    height: 2px;
    padding: 2px;
}

.template-line-title-center-app-tango {
    @extend .template-line-title;
    width: 7.125rem;
}

.template-line-title-card-app-tango {
    @extend .template-line-title;
    width: 90%;
    height: 2px;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

.template-line-input-app-tango {
    margin-left: 0.75rem;
    width: 90%;
    height: 0.2px;
    background-color: $color-secondary;
}

.template-line-100-app-tango {
    width: 100%;
    background-color: $color-secondary;
    height: 0.5px;
    margin-bottom: 12px;
}

.template-line-center-color-tertiary-app-tango {
    height: '1px';
    border: 'none';
    background-color: $color-tertiary;
}

// TEMPLATE LINE OR
.template-line-or-container-app-tango {
    display: flex;
    justify-content: center;
    width: 100%;

    #template-line-span-app-tango {
        color: $color-secondary;
        margin: auto;
        text-align: center;
        width: 10%;
    }

    .template-line-or-app-tango {
        background-color: $color-secondary;
        height: 0.1px;
        margin-bottom: 0.75rem;
        margin-top: 0.75rem;
    }

    .template-line-or-left-app-tango {
        @extend .template-line-or;
        justify-content: left;
        margin-left: 1.875rem;
        width: 48%;
    }

    .template-line-or-right-app-tango {
        @extend .template-line-or;
        justify-content: right;
        margin-right: 1.875rem;
        width: 48%;
    }
}

// FORM
@import '../components/buttons';

form {
    #phone_number::-webkit-outer-spin-button,
    #phone_number::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    #phone_number {
        -moz-appearance: textfield;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
        padding-top: 6.5px;
        padding-bottom: 6.5px;
    }

    .MuiOutlinedInput-inputMarginDense {
        padding-top: 6.5px;
        padding-bottom: 6.5px;
    }

    .new-unit-subtitle-two {
        display: block;
        align-self: center;
        text-align: start;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0rem;
        color: $color-secondary;
        line-height: 20px;
        text-transform: uppercase;
    }

    section {
        padding: 1.6rem;
    }

    .basic-data {
        background-color: #c4c4c4;

        input {
            background-color: white;
            border-radius: 5rem;
        }
    }

    .buttons-botom {
        display: flex;
        justify-content: space-between;
        margin: 0rem 1.6rem;
    }

    .upload-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .upload-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.4rem 0rem;

        span {
            margin: auto 0rem;
        }
    }

    .switch-container {
        align-items: center;
        display: flex;
        padding: 0.8rem 0rem;
        text-align: start;
    }

    .info {
        background-color: $color-white;
        border-radius: 5px;
        border: 1px solid $color-secondary;
        color: $color-tertiary;
        font-size: 1rem;
        height: auto;
        line-height: 22px;
        padding: 14px;
        text-align: start;
    }

    .total-area {
        @extend .info;
        height: 30px;
        width: 100%;
        border-color: $color-block-time;
    }

    .date-picker-container {
        .MuiFormControl-marginNormal {
            margin-top: 1px;
            margin-bottom: 8px;
            padding-right: 0px;
            padding-left: 0px;
        }
    }

    .radio-button-tango-container {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        text-align: start;
        padding: 0.8rem 0rem;
    }

    .radio-toolbar {
        align-items: center;
        margin: 0px;
        padding-right: 15px;
        padding-left: 15px;

        input[type='radio'] {
            display: none;
            visibility: hidden;
            cursor: pointer;
        }

        input[type='checkbox'] {
            display: none;
            visibility: hidden;
            cursor: pointer;
        }

        label {
            margin-right: 15px;
            cursor: pointer;
            display: inline-block;
            box-shadow: none;
        }

        .radio-string.checked + label {
            @extend .button-secondary;
            border-radius: 0.3rem;
            min-width: 171px;
            height: 30px;
            box-shadow: none;
        }

        .radio-number.checked + label {
            @extend .button-secondary;
            border-radius: 0.3rem;
            min-width: 30px;
            padding-left: 5px;
            padding-right: 5px;
            height: 30px;
            box-shadow: none;
        }

        input[type='checkbox']:checked + label {
            @extend .button-secondary;
            border-radius: 0.7rem;
            width: 171px;
            height: 30px;
            box-shadow: none;
        }

        .button-secondary-disabled-string {
            @extend .button-secondary-disabled;
            padding-top: 5px;
            border-radius: 0.3rem;
            min-width: 171px;
            padding-left: 5px;
            padding-right: 5px;
            height: 30px;
            box-shadow: none;
        }

        .button-secondary-disabled-string-cb {
            @extend .button-secondary-disabled-string;
            border-radius: 1rem;
        }

        .button-secondary-disabled-string-cb-cbx {
            @extend .button-secondary-disabled-string;
            border-radius: 0.7rem;
        }

        .button-secondary-disabled-number {
            @extend .button-secondary-disabled;
            border-radius: 0.3rem;
            width: 30px;
            height: 30px;
            box-shadow: none;
            padding-top: 5px;
        }

        .button-secondary-disabled-number-cbx {
            @extend .button-secondary-disabled;
            border-radius: 0.6rem;
            width: 30px;
            height: 30px;
            box-shadow: none;
            padding-top: 5px;
        }
    }

    .p-info-label {
        color: #999999;
        height: auto;
        padding-left: 0px;
        padding-right: 0px;
        margin: 0px;
        width: 100%;
        text-align: start;
        font-size: 0.85rem;
        text-transform: none;
    }

    .p-info-label::first-letter {
        text-transform: uppercase;
    }

    .p-info-label-white {
        @extend .p-info-label;
        color: $color-white;
    }

    .white-select {
        color: white;
        border-bottom: 1px solid $color-white;

        & .MuiSelect-icon {
            color: $color-white;
        }
    }

    .magenta-select {
        border-bottom: 1px solid $color-secondary;
    }

    select {
        width: 100%;
        background-color: white;
        border-radius: 4px;
        padding-top: 6.5px;
        padding-bottom: 6.5px;
        padding-left: 14px;
        padding-right: 14px;
    }
}

.payment-radio-selected {
    background: darken($color: white, $amount: 8);
}

.payment-radio {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    align-items: center;
    height: 6.5rem;
    margin-bottom: 1rem;

    &:hover {
        cursor: pointer;
    }

    @extend .custom-radio;
    span {
        font-size: 0.875rem;
    }
    .payment-description {
        @extend .d-flex, .flex-column, .align-items-start, .pr-2;
        max-width: 85%;
    }
}

#icon-close-tooltip-container {
    height: 0;
    #icon-close-tooltip {
        position: relative;
        height: 0.9375rem;
        width: 0.9375rem;
        top: -0.78125rem;
        img {
            height: 100%;
            width: 100%;
        }
    }
}

.icon-alert-tooltip {
    height: 0.9375rem;
    width: 0.9375rem;
}

.icon-alert-info-tooltip {
    width: 1.5rem;
}

.radio-button-link-container {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    text-align: start;
    padding: 0.8rem 0rem;
}

.radio-button-link {
    align-items: center;
    margin: 0px;
    padding-right: 15px;
    padding-left: 15px;

    input[type='radio'] {
        display: none;
        visibility: hidden;
        cursor: pointer;
    }

    label {
        margin-right: 15px;
        cursor: pointer;
        display: inline-block;
        box-shadow: none;
    }

    .button-secondary-disabled-string {
        @extend .button-secondary-disabled;
        padding-top: 5px;
        border-radius: 0.3rem;
        min-width: 171px;
        padding-left: 5px;
        padding-right: 5px;
        height: 30px;
        box-shadow: none;
        pointer-events: none;
    }

    .button-secondary-string {
        @extend .button-secondary-disabled;
        padding-top: 5px;
        border-radius: 0.3rem;
        min-width: 171px;
        padding-left: 5px;
        padding-right: 5px;
        height: 30px;
        box-shadow: none;
    }

    .button-secondary-string:hover {
        @extend .button-secondary-disabled;
        padding-top: 5px;
        border-radius: 0.3rem;
        min-width: 171px;
        padding-left: 5px;
        padding-right: 5px;
        height: 30px;
        box-shadow: none;
        background-color: $color-secondary;
        color: white;
    }
}

// NAVBAR
.navbar-renter-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 30rem;
    grid-template-areas: 'navbar-container';

    .template-navbar-container {
        grid-area: navbar-container;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 17.5rem 2.5rem;
        grid-template-areas:
            'template-area'
            '.';

        .template-navbar {
            height: auto;
            grid-area: template-area;
        }
    }

    .search-navbar-container {
        grid-area: navbar-container;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 14rem 17rem;
        grid-template-areas:
            '.'
            'search-area';
    }
}

.container-img {
    position: relative;
    text-align: center;
    color: white;

    .centered {
        position: absolute;
        color: $color-secondary;
        font-size: 1.3rem;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.center-search {
    @extend .d-inline-block;
    @extend .justify-content-center;
    @extend .py-2;

    .input-center-search {
        @extend .text-center;
        @extend .my-auto;

        .MuiInputBase-input {
            margin: 0.5rem;
        }
    }
}

.border-login {
    .MuiInputBase-input {
        padding: 0.5rem !important;
        font-size: 0.8rem !important;
    }
}

.user-search-label {
    @extend .pt-2;
    font-size: 1.25rem;
}

.navbar-desktop {
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.search-container {
    grid-area: search-area;

    .search-card-container {
        @extend .card;
        width: 98%;
        height: fit-content;
        -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px !important;
        border-color: #a8123e !important;
    }

    .row-search {
        @extend .row;
        @extend .justify-content-center;
        @extend .align-items-center;
        margin: 0 !important;
        height: 100%;
        flex-wrap: wrap !important;
    }

    .container-25 {
        align-items: flex-end;
        flex: 0 0 80%;
    }

    .container-50 {
        flex: 0 0 80%;
        min-height: 3.5rem;
        max-width: 80%;
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-left: none;
        border-right: none;
    }

    .search-input {
        position: relative;

        li {
            font-size: 0.8rem;
        }
    }

    .search-accordion {
        position: absolute;
        top: 0.9rem;
        right: -0.8rem;
        z-index: 100;
    }

    label {
        z-index: 1;
    }

    .MuiSelect-icon {
        color: $color-secondary;
        font-size: 2.5rem;
        top: inherit;
        pointer-events: none;
    }
}

.MuiInput-root.Mui-focused {
    .MuiSelect-root {
        background-color: transparent;
    }
}

.unit-type-select {
    margin-bottom: 1rem;

    .MuiSelect-icon {
        color: $color-secondary;
        font-size: 2rem;
        top: inherit;
        pointer-events: none;
    }

    .MuiInput-underline {
        border-bottom-color: $color-secondary;

        &:before {
            border-bottom-color: $color-secondary;
        }
    }
}

.font-sm-08 {
    font-size: 0.8rem;
}

.lh-0 {
    line-height: 1.2rem !important;
    font-size: 1rem !important;
}

.logo-style {
    margin-top: 1rem;
}

.notifier-style {
    margin-right: 8rem;
}

.list-notification {
    max-height: 29.375rem;
    width: 25.25rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.list-notification::-webkit-scrollbar {
    width: 5px;
}

.list-notification::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.list-notification::-webkit-scrollbar-thumb {
    background-color: $color-fourth;
    outline: 1px solid slategrey;
}

.btn-active {
    background-color: $color-fifth !important;
    color: $color-fifth !important;
}

.btn-alert-notifier {
    position: absolute;
    right: -0.6em;
    top: 0;
    background-color: $color-secondary !important;
    color: $color-white !important;
    width: 1.4rem;
    height: 1.4rem;
}

.popup-visit-renter-step-3 {
    .MuiDialog-paperWidthSm {
        width: 44rem !important;
        height: 42rem !important;
    }
}

.popup-visit-renter-step-2 {
    .MuiDialog-paperWidthSm {
        height: 47rem !important;
    }
}

.custom-margin-step-3 {
    margin: 1rem 6rem 0rem 6rem !important;
    .step-visit__header {
        @extend .d-flex;
        @extend .mb-5;
        @extend .flex-column;
        @extend .align-items-center;
        @extend .justify-content-center;
        span {
            font-size: 1.3rem;
        }
    }
    .step-visit__header:nth-child(1) {
        margin: 0 !important;
    }
}
.step-visit {
    .small-button {
        width: 20rem !important;
    }
    margin: 1rem 8rem 0rem 8rem;

    .MuiTypography-body1 {
        color: gray !important;
    }
    .step-visit__body-step-three {
        @extend .d-flex;
        @extend .flex-column;
        @extend .align-items-start;
        @extend .mb-5;
    }
    .step-visit__footer-step-three {
        @extend .d-flex;
        @extend .flex-column;
        @extend .align-items-center;
        .message-button {
            margin-top: 0.7rem;
            font-size: 0.875rem !important;
        }
    }
    .step-visit__footer {
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .step-visit__form {
        display: flex;
        flex-direction: column;
    }
    .step-visit__header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        img {
            border-radius: 50%;
        }
        p {
            font-size: 1.2rem;
            text-align: left;
            align-self: center;
            margin-left: 2rem;
            font-weight: bold;
        }
    }
    .input-color-tertiary {
        color: black !important;
    }
    .MuiInputBase-input {
        padding: 1rem !important;
    }

    .flag-element {
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-left: 1px solid;
        border-color: $color-dark-gray;
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        max-width: 100%;
    }
    .MuiOutlinedInput-notchedOutline:focus {
        border: none !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $color-dark-gray !important;
    }
    .number-format {
        @extend .d-flex;
        div:nth-child(1) {
            img {
                border-radius: 0.2rem;
                margin-left: 0.5rem;
                -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.71);
                box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.71);
            }
            .number-code {
                color: $color-dark-gray;
                padding-left: 1rem;
            }

            .vertical-line {
                height: 70%;
                width: 1px;
                background: $color-dark-gray;
            }
        }
        & div:nth-child(2) div fieldset {
            border: none;
            border-top: 1px solid;
            border-bottom: 1px solid;
            border-right: 1px solid;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-color: $color-dark-gray;
        }
    }
}

.new-close-btn {
    @extend .d-flex;
    @extend .justify-content-end;
    @extend .mr-4;
    @extend .mt-4;
    img {
        transition: all ease-in-out 0.1s;
    }
    &:hover {
        cursor: pointer;
    }
    img:hover {
        transform: scale(1.1);
    }
}

.btn-alert-notifier-admin {
    @extend .btn-alert-notifier;
    box-shadow: 0 0 2px black;
    background-color: $color-fourth !important;
}

.notifier-ico {
    font-size: 1.3rem !important;
}

.rounded-circle-notifications-desktop {
    @extend .rounded-circle-notifications-header;
    @extend .ml-2;
    font-size: 0.7rem !important;
    position: absolute;
    width: 1.1rem;
    height: 1.1rem;
    margin-top: 0 !important;
    top: 0;
    font-weight: normal;
    right: -1.2rem !important;
}

// POPOVER NOTIFICATION
.notification-popover {
    .list-group-item.active {
        z-index: 2;
        color: #fff;
        background-color: $color-fourth;
        border-color: $color-fourth;
    }

    .list-group-item {
        padding: 0.4rem 1.25rem;
    }
}

.color-secondary {
    color: $color-secondary;
}

.cursor-pointer {
    cursor: pointer;
}

.popover-container-form {
    width: 17rem;
    padding: 0rem 1rem 1rem 1rem;
}

// PRIMARY COLOR
.MuiAppBar-colorPrimary {
    color: #101820 !important;
    background-color: $color-white !important;
}

.btn-second {
    color: $color-primary !important;
    background-color: $color-block-time !important;
}

.btn-third {
    color: $color-white !important;
    background-color: $color-primary !important;
}

.btn-blank {
    color: $color-primary !important;
    background-color: $color-white !important;
}

.admin-card-container {
    @extend .col-md-6;
    @extend .col-xl-4;
}

.card-admin {
    background: #ffffff;
    border: 0.031rem solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 0.125rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.625rem;
    min-height: 15rem;
    height: fit-content;
    @extend .m-1;
    @extend .my-3;
    padding: 0.8rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cards-admin {
    @extend .col-md-3;
    @extend .col;
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-around;

    .card-admin-roles {
        margin-bottom: 0.4rem;

        .card {
            display: flex;
            border: none;
            @extend .h-100;
            width: 100%;
            height: 100%;

            .card-body {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                border: 1px solid $color-secondary;
                border-radius: 0.5rem;
            }
        }
    }

    .card-admin-two-roles {
        @extend .card-admin-roles;
        min-height: 15rem;
    }
}

.table {
    grid-area: table;
}

.MuiTableCell-body {
    @extend .link-general-app;
}

.table-title-schdule {
    @extend .text;
    @extend .text-color-secondary;
    @extend .my-2;
}

// LOGIN
.login-form {
    display: flex;
    justify-content: center;

    .card {
        min-height: 38rem;
        background-color: $color-secondary;
    }

    .card-header {
        background-color: inherit;
        border-bottom: none;
    }

    .forget-password-container {
        @extend .col-7;
        @extend .col-md-6;
        @extend .p-0;
        @extend .text-right;
        margin: 0.8rem 0rem;
        text-align: end;
    }

    .card-footer {
        background-color: inherit;
        border-top: none;
    }
}

.forget-password-container {
    .text-color-tertiary-inline {
        font-size: 0.9rem !important;
        color: $color-light-grey !important;
    }
}

.sign-up-button {
    @extend .button-primary;
    @extend .w-75;
    font-size: 0.95rem;
}

.popup-register-phone {
    .info-sign-up {
        @extend .mb-4;
        @extend .text-left;
        display: flex;
        font-size: 1.2rem;

        .info-img {
            flex: 0;
            margin-right: 0.7rem;
        }

        .info-text {
            flex: 1;
            font-size: 0.875rem;
            @extend .text-color-primary;
        }
    }

    .info-owner-singup {
        @extend .text-color-primary;
        text-align: center;
        @extend .mt-4;
    }
}
.mt-11 {
    margin-top: 7rem;
}
.mt-10 {
    margin-top: 5.5rem;
}

// color instapage - primary
.container-instapage-primary {
    @extend .container-mount-view;
    @extend .d-flex;
    @extend .align-items-center;
    background-color: $color-instapage-bg-primary !important;

    h2,
    p,
    input,
    select,
    label,
    span {
        color: $color-instapage-items-primary !important;
    }

    select {
        height: 2.063rem;
    }

    .price-validation {
        color: $color-instapage-items-primary !important;
        background-color: $color-instapage-bg-primary !important;
    }

    .template-line-or-container {
        #template-line-span {
            color: $color-instapage-bg-primary !important;
        }

        .template-line-or {
            color: $color-instapage-bg-secondary !important;
        }
    }

    .google-button-container {
        .google-button {
            span {
                color: rgba(0, 0, 0, 0.54) !important;
            }
        }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        border-bottom-color: $color-instapage-items-primary !important;
    }

    .bg-calendar-instapage {
        p,
        span {
            color: black !important;
        }

        .Mui-disabled span {
            color: $color-block-disabled !important;
        }
    }

    .MuiPickersCalendar-week {
        color: $color-white !important;

        .MuiPickersDay-current p {
            color: $color-instapage-bg-primary !important;
        }

        .MuiPickersDay-daySelected p {
            color: $color-instapage-items-primary !important;
        }

        .MuiPickersDay-dayDisabled p {
            color: $color-block-disabled !important;
        }
    }

    .white-select {
        color: $color-instapage-items-primary !important;
        border-bottom: 1px solid $color-instapage-items-primary !important;

        & .MuiSelect-icon {
            color: $color-instapage-items-primary !important;
        }
    }

    ul li span {
        color: #333333 !important;
    }

    .bg-color-secondary {
        background-color: $color-instapage-bg-primary !important;
    }

    .template-line-input {
        background-color: $color-instapage-items-primary !important;
    }
}

// color instapage - secondary
.container-instapage-secondary {
    @extend .container-mount-view;
    @extend .d-flex;
    @extend .align-items-center;
    background-color: $color-instapage-bg-secondary !important;

    h2,
    p,
    input,
    select,
    label,
    span {
        color: $color-instapage-btn-secondary !important;
    }

    select {
        height: 2.063rem;
    }

    .price-validation {
        color: $color-instapage-btn-secondary !important;
        background-color: $color-instapage-bg-secondary !important;
    }

    .template-line-or-container {
        #template-line-span {
            color: $color-instapage-bg-primary !important;
        }

        .template-line-or {
            color: $color-instapage-bg-secondary !important;
        }
    }

    .google-button-container {
        .google-button {
            span {
                color: rgba(0, 0, 0, 0.54) !important;
            }
        }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        border-bottom-color: $color-instapage-btn-secondary !important;
    }

    .bg-calendar-instapage {
        .Mui-disabled span {
            color: $color-block-disabled !important;
        }
    }

    .MuiPickersCalendar-week {
        .MuiPickersDay-current p {
            color: $color-white !important;
        }

        .MuiPickersDay-daySelected p {
            color: $color-instapage-items-primary !important;
        }

        .MuiPickersDay-dayDisabled p {
            color: $color-block-disabled !important;
        }

        .MuiPickersDay-current p {
            color: $color-primary !important;
        }
    }

    .white-select {
        color: $color-instapage-btn-secondary !important;
        border-bottom: 1px solid $color-instapage-items-secondary !important;

        & .MuiSelect-icon {
            color: $color-instapage-items-secondary !important;
        }
    }

    i {
        color: $color-instapage-items-secondary !important;
    }

    .bg-color-secondary {
        background-color: $color-instapage-bg-secondary !important;
    }

    .template-line-input {
        background-color: $color-instapage-items-secondary !important;
    }

    .button-primary {
        background-color: $color-instapage-btn-secondary !important;
    }
}

// color instapage - tertiary
.container-instapage-tertiary {
    @extend .container-mount-view;
    @extend .d-flex;
    @extend .align-items-center;
    background-color: $color-instapage-bg-tertiary !important;

    h2,
    p,
    input,
    select,
    label,
    span {
        color: $color-instapage-items-tertiary !important;
    }

    select {
        height: 2.063rem;
    }

    .price-validation {
        color: $color-instapage-items-tertiary !important;
        background-color: $color-instapage-bg-tertiary !important;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        border-bottom-color: $color-instapage-btn-border-text-tertiary !important;
    }

    .bg-color-secondary {
        background-color: $color-instapage-bg-tertiary !important;
    }

    .template-line-input {
        background-color: $color-instapage-btn-border-text-tertiary !important;
    }

    i {
        color: $color-instapage-btn-border-text-tertiary !important;
    }

    .button-primary {
        background-color: $color-instapage-btn-tertiary !important;
        color: $color-instapage-btn-border-text-tertiary !important;
        border-color: $color-instapage-btn-border-text-tertiary !important;
    }

    .button-primary:disabled {
        color: $color-block-disabled !important;
    }

    form .white-select {
        border-bottom: 1px solid $color-instapage-btn-border-text-tertiary !important;
        color: black;

        & .MuiSelect-icon {
            color: $color-instapage-btn-border-text-tertiary !important;
        }
    }
}

.line-instapage {
    @extend .template-line-input;
    background-color: #ffffff;
}

.text-color-instapage {
    color: $color-white;
}

.submit-button-container {
    display: flex;
    justify-content: center;
    margin: 2rem 0rem 1rem 0rem;
}

.circle-step {
    width: 4rem !important;
    height: 4rem !important;
    line-height: 4rem !important;
    border-radius: 50% !important;
    font-size: 1.7rem !important;
    color: #fff !important;
    text-align: center !important;
    background-color: #d3d3d3 !important;

    &.active-step {
        background-color: #a8123e !important;
    }
}

// MOUNT CARD VIEW
.mount-container-tango-view {
    display: flex;
    justify-content: center;

    .card-container-mount {
        border-radius: 20px 20px 20px 20px;
        box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.25);
        min-height: 50rem;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 100%;
        grid-template-areas: 'info-card body-card';

        .info-card {
            grid-area: info-card;
            display: flex;
            background-color: white;
            border-radius: 20px 0px 0px 20px;
            padding: 3.5rem 2.5rem 3.5rem 2.5rem;
            flex-direction: column;
            justify-content: space-between;

            .info-sign-up {
                @extend .row;
                @extend .mt-4;
                @extend .text-left;
                font-size: 1.2rem;

                .info-img {
                    @extend .col-2;
                }

                .info-text {
                    @extend .col-7;
                    @extend .text-color-black;
                }
            }

            .info-owner-singup {
                @extend .text-color-black;
                @extend .text-left;
            }
        }

        .choose-register {
            @extend .info-card;
            background-image: url('../../resources/images/HappyWoman.png');
            background-color: $color-fifth;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .choose-register-logo-tango {
                position: absolute;
                text-align: start;
                bottom: 2.5rem;
                left: 2.5rem;
            }
        }

        .info-card.new-unit-creation {
            background-color: $color-white;
        }

        .button-group-change {
            grid-area: info-card;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .button-group-selected {
                display: flex;
                flex-direction: column;
                @extend .button-color;
            }
        }

        .body-card {
            grid-area: body-card;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            border-radius: 0px 20px 20px 0px;
            padding: 3.5rem 2.5rem 3.5rem 2.5rem;

            .title-body {
                justify-content: flex-start;
                text-align: start;
                width: 100%;
            }

            .footer-container {
                padding-top: 2.5rem;
                text-align: center;
            }

            .info-post-login {
                padding: 0;
                padding-bottom: 2.5rem;
                border-bottom: 1px solid $color-tertiary !important;
            }

            .info-post-login-app-tango {
                padding: 0;
                padding-bottom: 2.5rem;
                border-bottom: 1px solid $color-secondary !important;
            }
        }

        .body-card.new-unit-creation {
            align-items: start;
            padding: 3.5rem 2.5rem 3.5rem 2.5rem;
        }
    }

    .container-mount {
        min-height: 50rem;
        display: grid;
        grid-template-columns: 55% 50%;
        grid-template-rows: 100%;
        grid-template-areas: 'info-card body-card';

        .info-card {
            display: none;

            .info-sign-up {
                @extend .row;
                @extend .mt-4;
                @extend .text-left;
                font-size: 1.2rem;

                .info-img {
                    @extend .col-2;
                }

                .info-text {
                    @extend .col-7;
                    @extend .text-color-black;
                }
            }

            .info-owner-singup {
                @extend .text-color-black;
                @extend .text-left;
            }
        }

        .choose-register {
            @extend .info-card;
            background-image: url('../../resources/images/HappyWoman.png');
            background-color: $color-fifth;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .choose-register-logo-tango {
                position: absolute;
                text-align: start;
                bottom: 2.5rem;
                left: 2.5rem;
            }
        }

        .info-card.new-unit-creation {
            background-color: $color-white;
        }

        .button-group-change {
            grid-area: info-card;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .button-group-selected {
                display: flex;
                flex-direction: column;
                @extend .button-color;
            }
        }
        .body-card-mobile {
            grid-area: body-card;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            border-radius: 0px 20px 20px 0px;
        }

        .body-card {
            grid-area: body-card;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            border-radius: 0px 20px 20px 0px;
            padding: 3.5rem 2.5rem 3.5rem 2.5rem;

            .title-body {
                justify-content: flex-start;
                text-align: start;
                width: 100%;
            }

            .footer-container {
                padding-top: 2.5rem;
                text-align: center;
            }

            .info-post-login {
                padding: 0;
                padding-bottom: 2.5rem;
                border-bottom: 1px solid $color-tertiary !important;
            }

            .info-post-login-app-tango {
                padding: 0;
                padding-bottom: 2.5rem;
                border-bottom: 1px solid $color-secondary !important;
            }
        }

        .body-card.new-unit-creation {
            align-items: start;
            padding: 3.5rem 2.5rem 3.5rem 2.5rem;
        }
    }

    .creation-step {
        margin: 0;
        display: flex;
        text-align: left;

        &.active-step {
            font-weight: bold;
            font-size: 1.2rem !important;
        }

        .step-number {
            color: $color-secondary;
            flex: 0;
            margin-right: 0.5rem;
        }

        .step-text {
            flex: 1;
        }
    }

    .card-container-thank-you {
        border-radius: 20px 20px 20px 20px;
        box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.25);
        min-height: 50rem;
    }
}

// MOUNT CARD VIEW APP TANGO
.mount-container-tango-view-app-tango {
    display: flex;
    justify-content: center;

    .card-container-mount-app-tango {
        border-radius: 20px 20px 20px 20px;
        box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.25);
        min-height: 50rem;
        display: grid;
        grid-template-columns: 55% 50%;
        grid-template-rows: 100%;
        grid-template-areas: 'info-card body-card';
        .info-card {
            grid-area: info-card;
            display: flex;
            background-color: $color-secondary;
            border-radius: 20px 0px 0px 20px;
            padding: 3.5rem 2.5rem 3.5rem 2.5rem;
            flex-direction: column;
            justify-content: space-between;

            .info-sign-up {
                @extend .row;
                @extend .mt-4;
                @extend .text-left;
                font-size: 1.2rem;

                .info-img {
                    @extend .col-2;
                }

                .info-text {
                    @extend .col-7;
                    @extend .text-color-white;
                }
            }

            .info-owner-singup {
                @extend .text-color-white;
                @extend .text-left;
            }
        }

        .choose-register {
            @extend .info-card;
            background-image: url('../../resources/images/HappyWoman.png');
            background-color: $color-fifth;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .choose-register-logo-tango {
                position: absolute;
                text-align: start;
                bottom: 2.5rem;
                left: 2.5rem;
            }
        }

        .info-card.new-unit-creation {
            background-color: $color-white;
            border-right: 2px solid $color-secondary;
        }

        .button-group-change {
            grid-area: info-card;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .button-group-selected {
                display: flex;
                flex-direction: column;
                @extend .button-color;
            }
        }

        .body-card {
            grid-area: body-card;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            border-radius: 0px 20px 20px 0px;
            padding: 1rem 2.5rem 1rem 2.5rem;

            .title-body {
                justify-content: flex-start;
                text-align: start;
                width: 100%;
            }

            .footer-container {
                padding-top: 2.5rem;
                text-align: center;
            }

            .info-post-login {
                padding: 0;
                padding-bottom: 2.5rem;
                border-bottom: 1px solid $color-tertiary !important;
            }

            .info-post-login-app-tango {
                padding: 0;
                padding-bottom: 2.5rem;
                border-bottom: 1px solid $color-secondary !important;
            }
        }

        .body-card.new-unit-creation {
            align-items: start;
            padding: 3.5rem 2.5rem 3.5rem 2.5rem;
        }
    }

    .creation-step-app-tango {
        margin: 0;
        display: flex;
        text-align: left;

        &.active-step {
            font-weight: bold;
            font-size: 1.5rem !important;
        }

        .step-number {
            color: $color-secondary;
            flex: 0;
            margin-right: 0.5rem;
        }

        .step-text {
            flex: 1;
        }
    }

    .card-container-thank-you-app-tango {
        border-radius: 20px 20px 20px 20px;
        box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.25);
        min-height: 50rem;
    }
}

.step-margin {
    margin-top: 1.2rem;
    margin-left: 1rem;
}

.mw-97 {
    max-width: 97%;
}

.direction-card {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 45px;
    line-height: 30px;
    margin-bottom: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #000000;
}

.content-text-card {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1a1a1a;
}

.bathrooms-card {
    @extend .content-text-card;
    font-size: 14px;
}

.no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.no-padding-left {
    padding-left: 0 !important;
}
// LOGO IMAGE
.big-img-logo {
    height: 4rem;
}

// UNIT CARD
@import '../customBootstrap-base.scss';

.unit-card {
    display: grid;
    -webkit-box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.75);
    width: fit-content;
    grid-template-columns: [f-col] 70% [s-col] 30% [th-col];
    grid-template-rows: [f-row] auto [s-row] 40px [th-row] 16% [four-row] 10% [fth-row] 42% [six-row];
    border-radius: 1.6rem;
    padding-bottom: 1.6rem;
    height: 100%;
    width: 16rem;

    .unit-card-img {
        grid-column: f-col / th-col;
        grid-row: f-row / four-row;
        width: 100%;

        img {
            border-radius: 1.6rem 1.6rem 0rem 0rem;
            width: 100%;
            height: 100%;
        }
    }

    .unit-card-label {
        grid-column: f-col / s-col;
        grid-row: s-row / th-row;
        background-color: rgba(255, 255, 255, 0.411);
        border-radius: 0rem 2rem 2rem 0rem;
        display: flex;
        align-items: center;
        text-align: start;

        p {
            margin-left: 1.6rem;
        }
    }

    h3 {
        grid-column: f-col / th-col;
        grid-row: four-row / fth-row;
        vertical-align: center;
        width: 100%;
    }

    .unit-card-search {
        text-align: end;
        grid-column: f-col / th-col;
        grid-row: th-row / four-row;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 1rem;
        height: 100%;

        i {
            -webkit-box-shadow: 0px 0px 0.4rem 1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 0.4rem 1px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 0px 0.4rem 1px rgba(0, 0, 0, 0.75);
            background-color: rgba(255, 255, 255, 0.479);
            border-radius: 2rem;
            padding: 0.4rem;
        }
    }

    .unit-card-body-list {
        grid-column: f-col / th-col;
        grid-row: fth-row / six-row;
        padding-top: 0.8rem;
        margin: 0rem 1.6rem;

        .divider {
            background-color: rgb(105, 105, 105);
            height: 0.08rem;
        }

        .description_line {
            border-bottom: 0.08rem solid rgb(117, 117, 117);

            span {
                font-size: 1rem;
                text-align: start;
            }
        }
    }

    .unit-card-body-img {
        grid-column: s-col / th-col;
        grid-row: fth-row / six-row;
        display: flex;
        align-items: center;
        font-size: 4em;
    }

    @media only screen and (max-width: 400px) {
        .unit-card-search {
            font-size: 0.8rem;
        }

        .unit-card-body-img {
            font-size: 2.4rem;
        }
    }

    @media only screen and (min-width: 400px) and (max-width: 1000px) {
        .unit-card-search {
            font-size: 1.2rem;
        }

        .unit-card-body-img {
            font-size: 3.6rem;
        }
    }
}

.unit-box {
    display: block;
}

.border-login {
    border: 0.063rem solid $color-tertiary !important;
    border-radius: 0.3rem !important;
    padding: 0;

    .MuiInput-underline:after,
    .MuiInput-underline:before,
    MuiInput-underline:hover:not(.Mui-disabled):before {
        border: none !important;
    }

    .MuiFormHelperText-root.Mui-error {
        color: transparent;
    }
}

/* UNIT CARD OWNER */
.card-timeline-owner {
    padding: 0 !important;
    height: 100%;
    width: 100%;
    margin-bottom: 3.4375rem;
    margin-top: 3.4375rem;
    display: block;

    .timeline-container {
        width: 100%;
    }
}

.big-card {
    width: 18.75rem;
    margin: auto;
    font-size: 14px;
    @extend .text;

    .title {
        font-size: 18px;
    }

    .unit-card-img {
        object-fit: cover;
        border-radius: 1rem;
        width: 100%;
        height: 12.5rem;
    }

    .section-info {
        padding: 0.5rem;

        .primary-price {
            @extend .text-color-secondary;
            font-size: 1.125rem;
            margin-right: 0.25rem;
            font-weight: 600;
        }

        .second-price {
            color: $color-price-secondary;
            font-size: 0.75rem;
        }
    }
}

.big-card-rented {
    @extend .big-card;
    margin-bottom: 3.4375rem;
    margin-top: 3.4375rem;
}

// UPLOAD BUTTON
.upload-box {
    padding: 1rem 0rem;
    display: block;
    flex-direction: column;
    align-items: center;

    span {
        margin-bottom: 1rem;
    }

    .upload-line {
        display: block;
        width: 100%;
    }
}

.dropzone-cnt {
    display: flex;
    justify-content: space-between;

    i {
        color: $color-secondary;
        font-size: 2.5rem;
        cursor: pointer;
    }

    .dropzone-file-input {
        display: none;
    }

    .tooltip-delete-logo {
        position: relative;
    }

    .tooltip-delete-logo span {
        display: none;
        width: 212.5rem;
    }

    .tooltip-delete-logo:hover span {
        @extend .button-tertiary;
        display: block;
        position: absolute;
        top: -1.875rem;
        left: 1.875rem;
    }
}

//DOCUMENTS
.container-document {
    .subtitle {
        text-align: start;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0rem;
        color: $color-secondary;
        line-height: 20px;
        text-transform: uppercase;
    }

    .new-unit-subtitle {
        text-align: start;
        height: 30px;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 34px;
        align-items: center;
        margin-bottom: 1.638rem;
    }

    .p-info-label {
        color: #999999;
        height: auto;
        padding-left: 0px;
        padding-right: 0px;
        margin: 0px;
        width: 100%;
        text-align: start;
    }

    .p-info-label::first-letter {
        text-transform: uppercase;
    }
}

// COUNTRY MANAGER

.font-size-09 {
    font-size: 0.875rem;
}

.card-country-manager {
    @extend .row;

    .colum-card {
        @extend .col-md-4;
        @extend .mt-4;
        @extend .p-0;

        .card {
            cursor: pointer;
            border-radius: 5%;
            width: 20rem;
            height: 23rem;

            .card-img-top {
                object-fit: cover;
                height: 12rem;
            }

            .card-body {
                @extend .mt-1;
            }
        }
    }
}

.MuiCheckbox-colorPrimary {
    background-color: $color-white !important;
}

.MuiTableCell-alignRight {
    white-space: nowrap;
}

// Separator Token
.separator-token {
    margin-top: 7.5rem;
}

// HOME RENTER
.separator-top-home {
    margin-top: 100px;

    .separator-top-info {
        margin-top: 80px;
    }
}

.separator-info {
    margin-top: 80px;
}

// This must stay here because of the svg url
.welcome-info {
    margin-bottom: 2rem;
    padding: 0px;
    min-height: 4rem;

    #img-background {
        //background: url('../../resources/icons/Circle.svg');
        background-size: 2rem;
        background-position: top;
        align-self: flex-end;
        min-height: 2.1rem;
        min-width: 2rem;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        .num {
            margin: 0;
            font-size: 1rem;
            font-family: Montserrat;
            font-weight: bold;
            line-height: 24px;
            color: $color-fourth;
        }
    }

    .p-info-label {
        color: $color-primary;
        margin: 0px;
        padding: 0px;
        text-align: start;
        width: 100%;
    }
}

// CIRCLE BTN
.circle-btn-cnt {
    position: absolute;
    top: -1.8rem;
    right: -1.8rem;

    button {
        border: 0px;

        i {
            border-radius: 50%;
            padding: 0.7rem 0.9rem;
            font-size: 1.6rem;
            cursor: pointer;
        }

        .fa {
            display: inline;
            color: $color-white;
        }
    }

    @media only screen and (max-width: 400px) {
        i {
            font-size: 2.4rem;
        }
    }
}

.popup-content {
    width: 90%;
    margin-inline: auto;
    font-size: large;
}

.popup-content-mobile {
    width: 90%;
    margin-inline: auto;
}

.max-width-modal {
    max-width: 27rem;
}

.pb-7 {
    padding-bottom: 7rem;
}

.h-32-8 {
    height: 32.8px;
}

.pdr-0 {
    padding-right: 0px !important;
}
.container-home {
    .container-sort-table {
        @extend .pt-1;
        @extend .w-100;
        margin-bottom: 10rem;
    }
}

.display-center-items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-home {
    .title-carousel {
        @extend .d-flex;
        @extend .justify-content-between;
    }
    .button-carousel {
        @extend .text-right;
    }
    .title-carousel-images {
        @extend .new-subtitle;
        @extend .font-weight-bold;
    }
}
.container-carousel-images {
    @extend .row;
    section {
        width: 100%;
    }
    .carousel-card-id-number {
        width: 10%;
        border: 0.5px solid #c4c4c4;
        height: auto;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        .carousel-card__number {
            @extend .display-center-items;
            padding: 0;
            width: 100%;
            div {
                width: 3.5rem;
                height: 3rem;
                @extend .display-center-items;
                span {
                    font-size: 2.5rem;
                    font-weight: bold;
                }
            }
            @media only screen and (max-width: ($breakpoint-phone - 1)) {
                padding: 0.5rem;
                .MuiInputBase-root {
                    border: 0.1rem solid #000;
                    border-radius: 0.4rem;
                    font-size: 1.5rem;
                    padding-left: 0.75rem;
                }
                .last-card-add {
                    border: 0.1rem solid #000;
                    border-radius: 0.4rem;
                    span {
                        font-size: 1.5rem;
                        font-weight: normal;
                    }
                }
            }
        }
        p {
            margin: 0;
            padding-right: 1rem;
            font-size: 1.3rem;
        }
        @media only screen and (max-width: ($breakpoint-phone - 1)) {
            width: 100%;
        }
    }
    .carousel-card {
        height: auto;
        flex-wrap: wrap;

        img {
            pointer-events: none;
        }

        .carousel-card__image {
            margin-inline: auto;
            padding: 0;
            @extend .col-lg-2;
            @extend .col-md-4;
            @extend .col-10;
            div {
                padding: 0.5rem;
            }
        }

        .carousel-card__new-image {
            @extend .carousel-card__image;
        }

        .carousel-card__actions {
            @extend .col-lg-9;
            @extend .col-md-7;
            @extend .col-12;
            @extend .p-sm-0;
            h3 {
                font-weight: bold;
            }
            div {
                @extend .pl-2;
                &:last-child .row {
                    padding-top: 1rem !important;
                    padding-top: 1rem !important;
                    & div {
                        padding-top: 0.5rem !important;
                        padding-bottom: 0.5rem !important;
                    }
                }
            }
        }
        .carousel-card__drag-icon {
            display: flex;
            justify-content: center;
            cursor: pointer;
            img {
                margin-bottom: auto;
                margin-top: auto;
                width: 2rem;
                height: 2rem;
                z-index: -40;
            }
            .carousel-card__drag-icon {
                display: flex;
                justify-content: center;
                cursor: pointer;
                img {
                    margin-bottom: auto;
                    margin-top: auto;
                    width: 2rem;
                    height: 2rem;
                    z-index: -40;
                }
            }
        }
        border: 0.5px solid #c4c4c4;
        display: flex;
        flex-direction: row;
        padding: 1rem;
        width: 90%;
        @media only screen and (max-width: ($breakpoint-phone - 1)) {
            width: 100%;
        }
    }
    .carousel-card-new-data {
        @extend .carousel-card;
        width: 100%;
    }
}

// SELECT
.select-group {
    display: column;
    text-align: start;
}

.options-box {
    max-width: 100%;
}

.disabled-black-btn {
    background: #333333;
    border-color: #333333;
    color: #999999;
    cursor: not-allowed !important;
    &:hover:enabled {
        background-color: #333333 !important;
    }
}

.disabled-gray-btn {
    border: none;
    box-shadow: none;
    background-color: #e8e8e8 !important;
    cursor: not-allowed !important;
    color: #737373 !important;
    &:hover:enabled {
        background-color: #e8e8e8 !important;
    }
}

.select-visit-table-admin {
    @extend .w-100;
}

// RADIO BUTTON
.radio-button-container {
    margin: 0.8rem 0rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    span {
        margin-bottom: 0.8rem;
        padding: 0rem;
        text-align: start;
    }

    input {
        color: white;
    }
}

.button {
    border-radius: 13px;
    border-color: '#b0b0b0';
    margin-left: 15px;
}

//clasic checkbox color red
.align-checkbox {
    margin-left: 2.188rem;
    font-size: 0.875rem;
    padding-bottom: 0.375rem;
    color: #999999;
    text-transform: uppercase;
    text-align: left;
}

.checkbox-terms {
    color: $color-tertiary;
    text-transform: uppercase;
    font-size: 0.875rem;
}

.checkbox-terms {
    color: $color-tertiary;
    text-transform: uppercase;
    font-size: 0.875rem;
}

.checkbox-width {
    max-width: 86%;
}

.align-roles {
    margin-left: 2.188rem;
}

.checkbox-container {
    margin: 0.8rem 0rem;
    align-items: flex-start;
    text-align: start;

    .options-wrap {
        display: flex;
        flex-wrap: wrap;
    }
}

.order-select {
    .MuiInput-root.Mui-focused {
        .MuiSelect-root {
            background-color: transparent;
        }
    }

    .MuiSelect-root {
        color: #a8123e;
    }

    .MuiSelect-icon {
        margin-left: 1rem;
        color: #a8123e;
        font-size: 2.5rem;
        top: inherit;
    }
}

.mt-3-rem {
    margin-top: 3rem !important;
}

// SWITCH
.switch-span {
    align-self: center;
    text-align: start;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0rem;
    color: $color-secondary;
    line-height: 1.25rem;
    text-transform: uppercase;
}

.switch-span-option {
    text-transform: capitalize;
    color: $color-tertiary;
    line-height: 1.25rem;
    font-size: 1rem;
}

.MuiSwitch-root {
    color: $color-tertiary !important;
    height: 40px !important;
    padding: 18px !important;
    width: 75px !important;
}

.MuiSwitch-switchBase {
    color: $color-primary !important;
    /*transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;*/
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: $color-secondary !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: $color-primary !important;
}

.MuiSwitch-switchBase.Mui-checked {
    transform: translateX(38px) !important;
}

// MULTIPLE DROPZONE
.multiple-dropzone-container {
    h4 {
        margin-top: 2rem;
        text-align: start;
    }

    .multiple-dropzone-box {
        background-color: #ffffff;
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border 0.24s ease-in-out;
        border-style: dashed;
        border-width: 2px;
        border-radius: 2px;
        border-color: #8b8b8b;
    }

    .img-container {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas: 'grid-image';
        position: relative;
    }

    .selectable-photo {
        cursor: pointer;
    }

    .selected-border {
        border: 2px solid $color-secondary;
        opacity: 0.79;
    }

    .selected-photo-number-container {
        background-color: $color-secondary;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        right: 3px;
        text-align: center;
        min-width: 1.75rem;
        opacity: 0.79;
    }

    .selected-photo-number {
        color: $color-white;
        font-weight: 700;
        font-size: 0.9rem;
    }

    .grid-image {
        grid-area: grid-image;
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }

    .thumbs-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .thumb-inner {
            object-fit: cover;
            margin: 0.4rem;

            .btni {
                font-size: 0.8rem !important;
                background-color: #a8123e !important;
                color: #ffffff !important;
                border: 1px solid #ffffff;
                padding: 0.3rem !important;
            }

            .img {
                max-width: 20rem;
                height: 8.5rem;
                object-fit: cover;
                z-index: -1;
            }

            .img-component {
                width: 15rem;
                height: 15rem;
                object-fit: cover;
                border-radius: 10px;
                z-index: -1;
            }
        }
    }
}

.letter-sp-2 {
    letter-spacing: 2pt;
}

.word-nowrap {
    white-space: nowrap;
}

.word-sp-5 {
    word-spacing: 51pt;
}

// INLINE IMAGE
.image-viewer-container {
    img {
        max-width: 100%;

        border-radius: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        width: fit-content;
    }
}

.mt-lg-5 {
    margin-top: 18rem !important;
}

.mt-xl-1 {
    margin-top: 27rem !important;
}

.mt-lg-2 {
    margin-top: 11rem !important;
}

// DATE PICKER
.date-picker-container {
    display: flex;
    margin: 0.8rem 0rem;
    text-align: start;
    align-items: center;
    height: auto;
}

.text-take-calendar {
    margin-left: 1%;
    @extend .my-0;
}

.text-subtitle {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.375rem;
}

.text-basic {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.1rem;
}

.list-style-none {
    list-style: none;
    padding: 0;
    padding-left: 2px;
}

.shape {
    padding: 0.3rem;
    margin: 0.3rem;
    border: 1px solid $color-tertiary;
    border-radius: 5px;
}

.color-0 {
    background-color: $color-white;
}

.color-1 {
    background-color: $color-block-bg-disabled;
    color: $color-block-disabled;
}

.color-2 {
    background-color: $color-light-magento;
}

// CALENDAR
.border-radius-left {
    border-radius: 8px 0 0 8px;
}

.border-radius-rigth {
    border-radius: 0 8px 8px 0;
}

.calendar-mobile {
    overflow: auto;
    max-height: 33vh;
    margin-bottom: 1rem;
}

.calendar-container {
    margin: 1.4rem auto;

    .calendar-month {
        margin-bottom: 2.4rem;
    }

    .week-section {
        padding-top: 0rem;
        padding-bottom: 1rem;

        .day-column:nth-child(1n) {
            border-right: 1px solid $color-fifth !important;
        }

        .day-column:nth-last-child(1) {
            border-right: none !important;
        }

        .day-column {
            width: 14.28%;
            padding-left: 0.5%;
            padding-right: 0.5%;

            h3 {
                background-color: #a8123e !important;
                color: #ffffff !important;
                min-height: 3.5rem;
                font-size: 1.3rem;
                border-radius: 4%;
            }

            .divSection {
                background-color: #a8123e !important;
                color: #ffffff !important;
                min-height: 3.5rem;
                font-size: 1.3rem;
                border-radius: 4%;
            }

            .div-sec-owner {
                background-color: transparent !important;
                color: #ffffff !important;
                min-height: 3.5rem;
                font-size: 1rem;
                border-radius: 4%;
            }

            .day {
                background-color: #a8123e !important;
                color: #ffffff !important;
                font-size: 0.9rem;
                border: none !important;
            }

            .toggle-button-hour {
                margin: 0.2rem 0rem;
                height: 2.8rem;
                padding-left: 0;
                padding-right: 0;

                .block {
                    font-size: 0.87rem;
                }

                p {
                    margin: auto 0rem;
                }
            }

            .toggle-button-hour-renter {
                margin: 0.2rem 0rem;
                height: 2.4rem;
                margin-right: 2rem !important;
                width: 96%;
                padding-left: 0;
                padding-right: 0;

                p {
                    margin: auto 0rem;
                }
            }
        }
    }
}

// SCHEDULE CALENDAR
.schedule-calendar-container {
    margin: 2.4rem auto;

    .days-section {
        margin: 1.6rem 0rem;

        h4 {
            background-color: #c2c2c2;
            margin-bottom: 0rem;
        }

        margin-top: 2rem;

        .day-column {
            min-width: 8rem;

            .blocks-header {
                height: 2.8rem;
            }

            .blocks-container {
                padding: 1.6rem 0rem;

                button {
                    background-color: transparent;
                    border: solid #c2c2c2 0.08rem;
                    margin: 0.24rem 0rem;
                    padding: 0.8rem 0.36rem;
                    font-size: 1.2rem;
                    font-weight: 700;
                }
            }
        }
    }
}

.btn-transparent {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

// PRICE SELECT
.price-select {
    width: 8rem;

    select {
        width: 100% !important;
    }
}

// COMMUNE CONTAINER
.commune-container {
    max-height: 13rem;
    overflow: auto;
    background-color: $color-white;
}

// POST DETAILS
.body-content {
    text-align: start;

    .upper-dashed {
        border-top: solid #c2c2c2 1px;
    }

    .aditional {
        width: 80%;
    }
}

.button-blocks {
    width: 142.27px;
    height: 53.33px;
    background: #ffffff;
    border: 1px solid #adadad;
    box-sizing: border-box;
    border-radius: 8px;
}

.MuiToggleButton-root {
    color: rgb(0, 0, 0) !important;
    border: 1px solid #adadad !important;
}

.MuiToggleButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
    border: 1px solid #adadad !important;
}

.MuiToggleButton-root:hover {
    background-color: rgba(0, 0, 0, 0.09) !important;
}

.button-menu-secondary {
    @extend .button-menu;
    background-color: transparent !important;

    &:active {
        transition: none;
    }
}

.bg-color-secondary {
    background-color: $color-secondary !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.m0-without-shadow {
    margin: 0 !important;
    box-shadow: none !important;
}

// TEXT
.text-color-gray {
    color: #999999 !important;
}

.text {
    text-align: start;
    text-transform: none !important;
}

.text-word-large {
    word-wrap: break-word;
}

.text-color-primary {
    color: $color-primary !important;
}

.text-color-secondary {
    color: $color-secondary !important;
}

.text-color-secondary-bold {
    color: $color-secondary !important;
    font-weight: bold !important;
}

.text-color-white {
    color: white !important;
}

.text-color-error {
    color: red;
}

.text-color-tertiary {
    color: $color-tertiary !important;
}

.text-color-fourth {
    color: $color-fourth !important;
}

.text-color-white {
    color: $color-white !important;
}

.text-color-dark-gray {
    color: $color-dark-gray;
}

// ROW
.row-head-buttons {
    @extend .button-color;
    padding: 0;
    margin: 0;

    .separator-text-botton {
        line-height: normal;
    }
}
nav.button-navbar {
    .button-navbar-row {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 1fr 1fr;
        button {
            @extend .font-size-09;
            max-width: 20ch;
            padding-inline: 2ch;
        }
    }
    @extend .row-head-buttons;
}

@media screen and (min-width: $breakpoint-phone) {
    nav.button-navbar {
        .button-navbar-row {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
        }
    }
}

.row-text {
    margin-top: 2rem;
}

.lease-renewal-container {
    text-align: left;
    padding: 0;
}

.lease-renewal-radio.checked {
    border: 2px solid $color-secondary;
}

.lease-renewal-radio,
.lease-renewal-radio-options {
    border-radius: 0.7rem;
    display: flex;
    font-size: 1.1rem;
    align-items: center;
    transition: ease-in-out 0.2s;
    cursor: pointer;

    & input[type='radio'] {
        -webkit-appearance: none;
        border-radius: 50%;
        outline: none;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        border: 2px solid $color-secondary;
    }

    input[type='radio']:hover {
        box-shadow: 0 0 3px 0px $color-secondary inset;
    }

    input[type='radio']:checked:before {
        content: '';
        height: 1rem;
        width: 1rem;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $color-secondary;
    }

    .lease-renewal-input-container {
        @extend .col-md-3;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .lease-renewal-input-container.with-height {
        min-height: 13rem;
    }

    .lease-renewal-text {
        @extend .col-md-9;
        margin: 0;
        padding: 0;
    }
}

.lease-renewal-radio-up,
.lease-renewal-radio-options {
    border-radius: 0.7rem;
    display: block;
    font-size: 1.1rem;
    align-items: center;
    transition: ease-in-out 0.2s;
    cursor: pointer;

    & input[type='radio'] {
        display: none;
    }

    input[type='radio']:hover {
        box-shadow: 0 0 3px 0px $color-secondary inset;
    }

    input[type='radio']:checked:before {
        content: '';
        height: 1rem;
        width: 1rem;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $color-secondary;
    }

    .lease-renewal-input-container {
        @extend .col-md-3;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin: auto;
        padding: 0;
    }

    .lease-renewal-input-container.with-height {
        min-height: 13rem;
    }

    .lease-renewal-text {
        @extend .col-md-12;
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 0.875rem !important;
    }
}

.lease-renewal-contact-info {
    text-align: center;
    padding: 0.5rem 0 1rem 0;
    display: flex;
    font-size: 0.75rem !important;
}
.lease-renewal-radio-up {
    @extend .row;
    border: 2px solid $color-tertiary;
    padding: 1rem 0.3rem;
    height: 85%;
    justify-content: center;
    &:hover {
        border: 2px solid $color-secondary;
    }
}
.lease-renewal-radio {
    @extend .row;
    border: 2px solid $color-tertiary;
    padding: 4rem 0.3rem;
    height: 85%;

    &:hover {
        border: 2px solid $color-secondary;
    }
}

.lease-renewal-radio-options {
    margin: 0 0.3rem;
    margin-top: 1rem;
}

.new-price-input-container {
    position: relative;
    margin: auto;

    .new-price-input-label {
        text-align: start;
        margin-bottom: 0.25rem;
    }

    @media only screen and (min-width: $breakpoint-phone) {
        width: 80%;
    }
}

.new-price-container {
    min-height: 100px;
}

.new-price-container.desktop {
    min-width: 400px;
}

.new-price-container.mobile {
    .new-price-difference {
        bottom: -3rem;
    }
}

.rejected-lease-renewal-container {
    @extend .row;
    @extend .my-5;
    border-radius: 0.7rem;
    border: 2px solid $color-tertiary;
    padding: 2rem 0.3rem;
    width: 90%;
    display: inline-flex;
    flex-wrap: wrap;
}

.popup-paragraph {
    text-align: left;
    font-weight: bold;
    @extend .mt-4;
}

.form-short-register {
    margin: 3rem 0;
    .fa-eye,
    .fa-eye-off {
        color: $color-tertiary !important;
    }
    .Mui-disabled {
        background-color: lighten($color: $color-tertiary, $amount: 30);
    }
}

.text-left-with-margin {
    text-align: left;
    @extend .mt-2;
}

.renewal-cards-container {
    @extend .row;
    display: flex;
    justify-content: center;
}

.tango-plan-cards-container {
    display: flex;
    justify-content: center;
}

.img-center {
    margin: 1rem 1rem 0 0;
}

// OFFER DETAIL
.offer-detail-container {
    padding: 0;
    margin-top: 2rem;

    label {
        color: $color-secondary;
        line-height: 20px;
        margin-bottom: 9px;
        text-transform: uppercase;
    }

    p {
        color: $color-tertiary;
        height: 30px;
        margin-bottom: 22px;
        padding-bottom: 8px;
        width: 100%;
    }

    #comments {
        background-color: $color-white;
        border-radius: 5px;
        border: 1px solid $color-secondary;
        color: $color-tertiary;
        font-size: 18px;
        height: auto;
        line-height: 22px;
        padding: 14px;
        text-align: start;
    }

    #notice {
        border: none;
        height: auto;
        margin-bottom: 35px;
        font-size: 22px;
        color: $color-primary;
    }

    #notice-two {
        border: none;
        height: auto;
    }
}

.padding-choose-register {
    padding-inline: 2rem;
}
// CUSTOM SELECT
.customized-select {
    height: 2.313rem;
    padding: 0 0 0 4.488rem;
    font-size: 1.2rem;
    border: 1px solid $color-secondary;

    .options-box {
        margin: 20px;
        font-size: 1.2rem;
    }

    &:focus {
        outline: 0;
    }
}
.padding-blocks {
    padding-inline: 2rem;
}
@media only screen and (min-width: 100px) and (max-width: $breakpoint-tablet) {
    .padding-blocks {
        padding-inline: 1rem;
    }
}

.names-register {
    color: white;
}
@media only screen and (min-width: 100px) and (max-width: $breakpoint-tablet) {
    .names-register {
        color: white;
    }
}

.surnames-register {
    color: white;
}
@media only screen and (min-width: 100px) and (max-width: $breakpoint-tablet) {
    .surnames-register {
        color: white;
    }
}
@media only screen and (min-width: 100px) and (max-width: $breakpoint-tablet) {
    .padding-select {
        padding-left: 1rem;
    }
}
.padding-google-btn {
    padding-top: 2rem !important;
    padding-inline: 2rem !important;
}
@media only screen and (min-width: 100px) and (max-width: $breakpoint-tablet) {
    .padding-type {
        padding-inline: 1rem !important;
    }
    .services__modal {
        align-items: flex-start;
        h3 {
            width: 37% !important;
            font-weight: 600;
        }
        img {
            width: 35px;
        }
    }
}
.padding-street-address-extra {
    padding-left: 0 !important;
}
@media only screen and (min-width: 100px) and (max-width: $breakpoint-tablet) {
    .padding-street-address-extra {
    }
}
.padding-price {
    padding-right: 0 !important;
}
@media only screen and (min-width: 100px) and (max-width: $breakpoint-tablet) {
    .padding-price {
    }
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    text-decoration: none;
    color: #ffffff;
    background-color: #f3969a;
}

// ACCORDION
.accordion-item {
    @extend .text;

    .MuiPaper-elevation1 {
        box-shadow: none;
        background-color: transparent !important;
    }

    .Mui-expanded {
        min-height: inherit !important;
        background-color: transparent !important;
    }

    .advanced-filter-h4 {
        padding: 1rem 0 0 0;
        text-transform: capitalize;
        font-size: 1.2rem;
    }

    .no-text-transform {
        text-transform: none !important;
    }

    .MuiAccordionSummary-content {
        margin: 0.75rem 0 0.75rem 0 !important;
        padding: 0;
        background-color: transparent !important;
        flex-direction: column;

        @media only screen and (min-width: $breakpoint-phone) {
            flex-direction: row;
        }
    }

    .MuiAccordionSummary-root {
        padding: 0 10px;
    }

    .border-top-secondary-color {
        border-top: 1.5px solid $color-secondary;
    }

    .title-bold {
        color: $color-white !important;
        font-size: 1rem;
        font-weight: bold;
    }
}

.border-color {
    border-top: 1px solid rgba(1, 1, 1, 0.87);
}

.border-secondary-color {
    border: 1px solid $color-secondary;
}

.container-mount-view {
    grid-area: root;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 9rem auto 7rem;
    grid-template-areas:
        '.'
        'main-mount'
        '.';

    .container-uniq-mount {
        grid-area: main-mount;
    }
}

.mt-21 {
    margin-top: 6rem;
}

.bg-transparent {
    background-color: transparent !important;
}

.left-calendar-arrow {
    @extend .col-1;
    @extend .mt-3-rem;
    @extend .text-left;
    @extend .d-none;
    @extend .d-md-block;
}

.right-calendar-arrow {
    @extend .col-1;
    @extend .mt-3-rem;
    @extend .text-right;
    @extend .d-none;
    @extend .d-md-block;
}

// CALENDARS HOURS
.list-hours {
    height: 17rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.text-word-large {
    word-wrap: break-word;
}

.w-30 {
    width: 30rem;
}

.list-hours-new {
    max-height: 17rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.animation-hours {
    text-align: center;
    animation: fadeDown 0.3s ease-in both;
}

@keyframes fadeDown {
    from {
        opacity: 0;
        transform: translate3d(0, -5%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.no-line-bottom {
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom-color: transparent !important;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 0px solid rgba(0, 0, 0, 0.87);
    }

    .WithStyles\(ForwardRef\(TextField\)\)-root-25 .MuiInput-underline:before {
        border-bottom-color: transparent !important;
    }

    .MuiInput-underline:before {
        border-bottom: 0px solid rgba(0, 0, 0, 0.42);
    }

    .WithStyles\(ForwardRef\(TextField\)\)-root-25 .MuiInput-underline:after {
        border-bottom-color: transparent;
    }

    .MuiInput-underline:after {
        border-bottom: 0px solid rgba(0, 0, 0, 0.42);
    }
}

.MuiIconButton-label {
    svg {
        color: $color-secondary !important;
    }
}

.error-container-form {
    height: 3rem;
    color: $color-tertiary;
    text-align: center;
}

.is-not-valid-submit {
    button[type='submit'] {
        @extend .button-primary-disabled;
    }
}
.font-standard {
    @extend .info-text;
    font-size: 1rem !important;
}

// HOME OWNER
.check-list-home-owner {
    width: 23px;
    height: 16px;
}

.circle-home-owner {
    width: 60px;
    height: 60px;
}

.post-card-plan {
    border-radius: 35px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 29.25rem;

    .num {
        height: 100%;
        width: 100%;
        margin-top: 30%;
        font-size: 2rem;
        font-family: Montserrat;
        font-weight: bold;
        line-height: 24px;
        color: $color-fourth;
    }

    .plan {
        background-color: $color-secondary;
        border-radius: 35px 35px 0px 0px;
        color: $color-white;
        height: 7rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            position: relative;
        }
    }

    .body {
        padding: 0rem 1rem;
        min-height: 10rem;
        margin: 0;
    }

    .card-body {
        padding: 0rem 1rem;
        min-height: 18rem;
        margin: 0;
    }

    .card-plan-bottom {
        min-height: 8rem;
    }
}

.img-show-renter-post {
    object-fit: cover;
    height: 13rem;
    width: 19rem;
    max-width: 90vw;
    cursor: pointer;
    margin: 0 0.2rem;
    border-radius: 5px;
}

.modal-title {
    font-family: 'Montserrat';
}

.subtitle-modal {
    @extend .mb-3;
    @extend .text-left;
    font-size: 2rem;
}

// PAGINATION
.pagination-cards {
}

// CARD INFO HOME MASTER
.card-info-home {
    @extend .card;
    height: 33rem;
    width: 67rem;
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: $color-white;
    padding: 2.5rem;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 25% 50% 25%;
    grid-template-areas:
        'header-info-container-home'
        'body-info-container-home'
        'footer-info-container-home';

    .header-info-container-home {
        grid-area: header-info-container-home;
    }

    .body-info-container-home {
        grid-area: body-info-container-home;

        .ico-info-home-master {
            width: 3rem;
            height: 3rem;
            margin-bottom: 0.2rem;
        }

        .triangule-flotant-arrow-secondary {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 0 12px 17px;
            border-color: transparent transparent transparent $color-secondary;
        }

        .second-section-info {
            display: flex;
            align-items: center;
            flex-direction: row;
        }

        .text-container {
            @extend .text;
            padding: 0 1rem 0 1rem !important;
        }
    }

    .footer-info-container-home {
        grid-area: footer-info-container-home;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}

.border-bold-secondary-left {
    border-left-color: $color-secondary;
    border-left-style: solid;
    border-left-width: 2px;
}

.border-bold-tertiary-left {
    border-left-color: $color-tertiary;
    border-left-style: solid;
    border-left-width: 2px;
}

.border-bold-white-left {
    border-left-color: $color-white;
    border-left-style: solid;
    border-left-width: 2px;
}

.border-bold-tertiary {
    border-color: $color-tertiary;
    border-style: solid;
    border-width: 2px;
}

.border-bold-secondary {
    border-color: $color-secondary;
    border-style: solid;
    border-width: 2px;
}

.modal-container-camera {
    width: 90vw;
    height: 90vh;

    .toc-liveness-camera {
        width: 100%;
        height: 100%;
    }
}

.border-primary-color {
    border: 1px solid $color-primary;
}

.border-secondary-color {
    border: 1px solid $color-secondary;
}

.border-secondary-color-left {
    border-left-color: $color-secondary;
    border-left-style: solid;
    border-left-width: 1px;
}

.border-secondary-color-top {
    border-top-color: $color-secondary;
    border-top-style: solid;
    border-top-width: 1px;
}

.MuiAccordionDetails-root {
    display: block !important;
    padding: 0.5rem 1rem 0.625rem !important;

    #mediation-history-ul {
        padding: 0 1rem;
        li {
            margin-bottom: 0.5rem;
        }
    }
}

// LINK
.link-general-app {
    .loading-text-black-background {
        span {
            color: white;
        }
        span:hover {
            color: white;
        }
    }
    a,
    span {
        cursor: pointer;
        text-decoration: none;
        color: black;
    }

    a:hover {
        color: #a8123e;
    }

    span:hover {
        color: #a8123e;
    }
}

// LINK TOC
.link-general-app-toc {
    a {
        cursor: pointer;
        text-decoration: none;
        color: #a8123e;
    }
}

// DIALOG MODAL
.MuiDialog-paperWidthSm {
    max-width: 800px;
}

.MuiDialog-paper {
    overflow-y: visible !important;
}

.MuiPaper-rounded {
    border-radius: 10px !important;
}

.MuiPickersToolbar-toolbar {
    background-color: $color-secondary !important;
}

.MuiPickersDay-daySelected:hover {
    background-color: $color-secondary !important;
}

.MuiPickersDay-daySelected {
    background-color: $color-secondary !important;
}

.ico-image-calendar {
    object-fit: contain;
    width: 1.3rem;
}

.with-max-modal-offer-form {
    .MuiDialog-paperWidthSm {
        max-width: 36.25rem !important;
    }
}

.with-max-new-modal-offer-form {
    #customized-dialog-content {
        padding: 0px !important;
    }
}

.new-compact-input {
    .MuiOutlinedInput-inputMarginDense {
        padding: 8px 12px;
    }
}

.new-input-field {
    margin: 0px 0px 10px 0px;
    flex: 1 1 auto;
}

.new-data-contract-dialog {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 70vh;
    padding: 0px !important;
}
.content-data-contract-modal {
    flex-grow: 10;
    overflow: auto;
    padding: 0px 40px;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
}
.footer-data-contract-modal {
    flex-grow: 1;
    flex-basis: 100px;
}

.new-modal-title-main {
    color: $color-primary;
    height: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    line-height: 150%;
    text-align: justify;
}

.new-modal-title-sub {
    color: $color-primary;
    height: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    text-align: justify;
    margin-bottom: 20px;
}

.new-modal-section-title {
    color: $color-primary;
    height: auto;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    text-align: justify;
    margin-bottom: 15px;
}

.ol-custom {
    counter-reset: item;
    padding: 0;

    & li {
        display: block;
        margin-bottom: 1.2rem;
    }

    & li:before {
        content: counter(item) '. ';
        counter-increment: item;
        color: black;
        font-weight: bold;
    }

    & .current-step:before {
        color: $color-secondary;
    }

    & .current-step {
        font-weight: bold;
    }
}

.with-max-modal-visit {
    .MuiDialog-paperWidthSm {
        max-width: 27rem !important;
    }
}

.with-min-modal-offer-form {
    .MuiDialog-paperWidthSm {
        min-width: 500px !important;
        padding: 0 1rem 0 1rem;
    }
}

.confirmation-referred-popup {
    .MuiDialog-paperWidthSm {
        min-width: 80%;
    }

    /* Phone devices (portrait tablets and large phones, 768px and up) */
    @media only screen and (min-width: $breakpoint-phone) {
        .MuiDialog-paperWidthSm {
            width: 70%;
            min-width: 70%;
        }
    }

    /* Large devices with small resolutions (laptops/desktops, 1440px and up) */
    @media only screen and (min-width: $breakpoint-laptop) {
        .MuiDialog-paperWidthSm {
            min-width: 30%;
            max-width: 34.375rem !important;
        }
    }
}

.border-bottom-tango {
    border-bottom-color: $color-secondary;
    border-bottom-width: 1.5px;
    border-bottom-style: solid;
}

.inner-columns {
    padding-left: 1.25rem;
    text-align: left;
}

.percentage-icon-container {
    @extend .input-container;
    justify-content: center;
    border: 1px solid $color-secondary;
    border-right: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.img-detail {
    display: flex;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;

    .img-div {
        width: 2rem;
        height: 2.2rem;
    }

    .img-size {
        width: 2rem;
        height: 1.5rem;
    }

    .img-size-bigger {
        width: 2rem;
        height: 2.2rem;
        margin-bottom: 0.4rem;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .img-size-smaller {
        width: 1.7rem;
        height: 1.2rem;
        margin-bottom: 0.4rem;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .padding-left {
        padding-left: 1.3rem;
    }

    .margin-bottom {
        margin-bottom: 1.5rem;
    }
}

.ul-style {
    span {
        color: $color-secondary !important;
        padding-left: 2.5rem;
    }

    ul {
        list-style: none;
    }

    ol,
    ul,
    dl {
        margin-bottom: 0;
    }

    ul li::before {
        content: '\2022';
        color: $color-secondary !important;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
}

.map-title {
    padding: 1rem 0 1rem 0.75rem;
}

.something {
    margin-left: 0.8rem;
}

/* cosignee */
.button-cosignee {
    @extend .button-primary;
    @extend .col-md-6;
    @extend .my-4;
}

.button-cosignee-disabled {
    @extend .button-primary-disabled;
    @extend .col-md-6;
    @extend .my-4;
}

.center-child-elements {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
}

.button-disabled-bank {
    @extend .button-primary-disabled;
    @extend .col-md-5;
    @extend .mt-xs-1rem;
    background-color: #999999;
    color: black;
    border: none;
}

/* cosignee: signature_initiation */
.loading-signature-initiation-view {
    display: flex;
    justify-content: center;
    margin-top: 80px;
}
.container-signature-initiation-view {
    height: 100%;
    display: flex;
    justify-content: center;
}

.signature-initiation-container {
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 1152px;
    margin: 62px 10px 137px 10px;
    display: flex;

    @media only screen and (max-width: $breakpoint-tablet) {
        max-width: 623px;
    }

    @media only screen and (max-width: $breakpoint-phone) {
        max-width: 290px;
    }
}

.signature-initiation-item {
    display: flex;
    justify-content: center;
}

.signature-initiation-group-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media only screen and (max-width: $breakpoint-phone) {
        text-align: center;
    }

    .title {
        display: flex;
        margin-top: 35px;
        font-size: 20px;
    }

    .description-paragraph {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.signature-initiation-description-text-subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}

.signature-initiation-description-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.signature-initiation-navbar-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
}

.signature-initiation-logo {
    min-width: 289px;
    max-width: 350px;
    min-height: 201px;
    max-height: 243.82px;
    width: 100%;
    height: auto;
}

.signature-initiation-backbutton {
    max-width: 350px;
    width: calc(100% - 8px);
}

// MEDIA WILL BE DELETED IN NAVBAR TICKET ISSUE
@media only screen and (max-width: $breakpoint-tablet) {
    .grid-container {
        display: block;
    }

    .card-one {
        margin: auto;

        .card {
            width: 100%;
            height: 100%;
        }
    }

    .sidebar {
        visibility: visible !important;
    }

    .card-two {
        margin: auto;
        margin-top: 1rem;
    }

    .table {
        padding: 1rem;
        width: 100%;
    }

    .navbar-renter-container {
        grid-template-rows: 31rem;

        .template-navbar-container {
            grid-template-rows: 17.5rem 13.5rem;

            .template-navbar {
                height: auto;
                grid-area: template-area;
            }
        }

        .search-navbar-container {
            grid-template-rows: 14rem 17rem;
        }
    }

    .search-container {
        .search-card-container {
            border-radius: 25px !important;
        }

        .row-search {
            flex-wrap: wrap !important;
        }

        .container-25 {
            align-items: flex-end;
            flex: 0 0 80%;
            max-width: 80%;
        }

        .container-50 {
            flex: 0 0 80%;
            min-height: 3.5rem;
            max-width: 80%;
            border-top-color: #a8123e;
            border-top-width: 1px;
            border-top-style: solid;
            border-bottom-color: #a8123e;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-left: none;
            border-right: none;
        }

        label {
            z-index: 1;
        }
    }
}

.small-paragraphs p {
    font-size: 0.965rem;
}

// Only tablet
@media only screen and (min-width: 600px) and (max-width: $breakpoint-tablet) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 0px 0px;
        grid-template-areas:
            'card-one card-two'
            'table table';
    }

    .card-one {
        margin: auto;
        width: 60%;
    }

    .card-two {
        margin: auto;
        width: 60%;
    }

    .table {
        padding: 1rem;
        width: 100%;
    }
}

// TABLET
// col-sm
// 768 px
@media only screen and (min-width: $breakpoint-phone) {
    .column-margin-top {
        margin-top: 2rem;
    }
}

// ESCRITORIO
@media only screen and (min-width: $breakpoint-tablet) {
    // MARGIN NAVBAR
    .container-navbar,
    .container-footer {
        max-width: 1440px !important;
        margin: auto;
    }

    // MARGIN HOME
    .container-home {
        max-width: 1340px !important;
        margin: auto;
    }

    /* UNIT CARD OWNER */
    .card-timeline-owner {
        display: inline-flex;

        //text-align: start;
        .timeline-container {
            padding-left: 5%;
        }
    }

    .big-card {
        width: 55%;
        max-width: 24.379rem;
        min-width: auto;
    }

    .big-card-rented {
        margin-left: 0;
        margin-right: 0;
        margin-right: 2rem;
        display: inline-block;
    }

    .unit-box {
        .big-card {
            margin: 0;
        }
    }

    .column-margin-left {
        margin-left: 11rem;
    }

    .column-margin-top {
        margin-top: 1.5rem;
    }

    .button-list-map-container {
        .pin-list {
            position: relative;
            display: inline-block;
            left: 40%;
        }
    }

    .navbar-desktop {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .item-carousel-container {
        .react-multiple-carousel__arrow--left,
        .react-multiple-carousel__arrow--right {
            display: block;
        }
    }

    .navbar-renter-container {
        grid-template-rows: 20rem;

        .search-navbar-container {
            grid-template-rows: 14rem 6rem;
        }
    }

    .search-container {
        .search-card-container {
            width: 75vw;
            border-radius: 10px !important;
            max-width: 1340px !important;
        }

        .row-search {
            flex-wrap: nowrap !important;
        }

        .container-25 {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .container-50 {
            flex: 0 0 50%;
            min-height: 3.5rem;
            max-width: 50%;
            border-right-color: #a8123e;
            border-right-width: 2px;
            border-right-style: solid;
            border-left-color: #a8123e;
            border-left-width: 2px;
            border-left-style: solid;
            border-bottom: none;
            border-top: none;
        }
    }

    // LOGO NAVBAR
    .logo-navbar {
        width: 100%;
        text-align: start;
    }
}

.show-more:hover {
    cursor: pointer;
    color: $color-secondary;
    transition: 0.2s ease-in-out all;
}

.MuiTableSortLabel-root {
    width: 100%;
    justify-content: space-between !important;
}

.MuiTableSortLabel-root:focus,
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-active {
    color: white !important;
}

.arrow-table-active {
    svg {
        color: white !important;
    }
}

.arrow-table-disabled {
    svg {
        color: #d3889e !important;
    }
}

.carousel-card-a {
    color: #333333;

    &:hover {
        text-decoration: none;
        color: #333333;
    }
}

.filelabel {
    width: 120px;
    border: 2px dashed $color-secondary;
    border-radius: 5px;
    display: block;
    padding: 5px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;
    margin: 0;
}

.filelabel i {
    display: block;
    font-size: 30px;
    padding-bottom: 5px;
}

.filelabel i,
.filelabel .title {
    color: grey;
    transition: 200ms color;
}

.filelabel:hover {
    border: 2px solid $color-secondary;
}

.filelabel:hover i,
.filelabel:hover .title {
    color: $color-secondary;
}

#resume {
    display: none;
}

.checkbox {
    height: 1.25rem;
    width: 1.25rem;
    border-color: $color-tertiary;
    position: relative;
    margin-right: 1.25rem;
}

.checkbox-xs {
    height: 1.5rem;
    width: 1.5rem;
    border-color: $color-tertiary;
    position: relative;
    margin-right: 1.25rem;
}

.uploaded-image {
    margin: 0 1rem 0 1rem;
    height: 100%;
    width: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26), 0 3px 6px rgba(0, 0, 0, 0.3);
}

.images-container {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 500px;
    max-height: 30vh;
    padding: 16px 20px;
    overflow: auto;
    cursor: auto;
}

.images-container:after {
    content: '';
    padding-right: 20px;
}

//Ribbon Badge
.ribbon-badge {
    position: relative;
    font-size: 0.8rem;
}

.ribbon-badge::before {
    position: absolute;
    top: 2rem;
    right: -0.5rem;
    content: '';
    background: $color-secondary;
    height: 28px;
    width: 28px;
    transform: rotate(45deg);
    z-index: -1;
}

.ribbon-badge::after {
    position: absolute;
    content: attr(data-label);
    top: 11px;
    right: -14px;
    padding: 0.5rem;
    width: 12rem;
    background: $color-secondary;
    color: white;
    text-align: center;
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
}

.text-no-transform {
    text-transform: none;
}

.font-size-signup {
    font-size: 1.1rem !important;
}

///End Ribbon Badge

.container-xs {
    font-size: 0.75rem;
    text-align: left;
    width: 79%;
    margin-left: 10.5%;
    margin-right: 10.5%;
}

.filelabel-container {
    width: 120px;
    border: 2px dashed $color-secondary;
    border-radius: 5px;
    display: block;
    padding: 5px;
    transition: border 300ms ease;
    text-align: center;
    margin: 0;
}

.viewID {
    border: 0.175rem dashed $color-secondary;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    margin: 0;

    &:hover {
        border: 0.175rem solid $color-secondary;
    }
}

.container-blank-view {
    height: 50rem;
}
.container-blank-view-xs {
    height: 30rem;
}
.container-blank-view-s {
    height: 50rem;
}

.badge {
    background-color: $color-secondary;
    position: absolute;
    top: -12px;
    right: -10px;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    font-weight: bold;
    color: $color-white;
    padding-top: 0.4rem;
}

.badge-button-action {
    @extend .badge;
    position: absolute;
    top: -1.375rem;
    right: -1rem;
}

.rrss-icons-mobile {
    width: 75%;
    margin: 0 0 0.5rem 0.2rem;
}

.uploading-photos-message-container {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .flex-column;
    @extend .mt-3;
}

.cosigners-container {
    @extend .phone-form;
}

.cosigner-data {
    @extend .text-color-tertiary;
    @extend .my-3;
}

.button-menu {
    color: $color-secondary !important;

    .button-menu:hover {
        cursor: not-allowed;
    }

    z-index: 10000;
}

.zindex {
    z-index: -1;
}

.container-item-list {
    padding-top: 0 !important;
    display: inline;
    white-space: nowrap;
    float: left;
}

.left-item-list {
    float: left;
    padding-right: 2rem;
    display: block;
}

.right-item-list {
    float: right;
    padding-right: 2rem;
}

.terms-renter-visit {
    @extend .row;
    @extend .input-container;
    @extend .d-flex;
    @extend .align-items-start;
    @extend .input-container-class;
    @extend .p-0;
    @extend .mb-4;
}

.no-pointer {
    cursor: none !important;
}

.default-pointer {
    cursor: default !important;
}

.pointer {
    cursor: pointer !important;
}

.card-checked {
    border: 2px solid #a8123e !important;
}

.treeView {
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
    .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
        background-color: transparent !important;
    }

    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:not(:hover),
    .MuiTreeItem-root.Mui-selected:not(:focus) > .MuiTreeItem-content .MuiTreeItem-label {
        background-color: transparent !important;
    }

    .MuiTreeItem-content {
        flex-direction: row-reverse;
        cursor: default !important;
    }
}

@media only screen and (max-width: $breakpoint-ex-small) {
    .max-height-modal {
        height: 50%;
    }
    .carousel-mobile-owner {
        height: 22.5rem !important;
    }
    .text-modal-step {
        height: 15rem !important;
        .popup-description {
            overflow-y: auto;
            max-height: 100%;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: $breakpoint-extra-small) {
    .text-take-calendar {
        font-size: 0.8rem;
    }

    .text-subtitle {
        font-weight: 600;
    }
    .carousel-mobile-owner {
        // height: 28rem!important;
    }
    .services__modal {
        h3 {
            width: 100% !important;
            font-weight: 600;
        }
    }
    .owner-popup-mobile {
        width: 90vw;
        height: 71vh;
    }

    .react-multi-carousel-track {
        height: 100%;
    }

    .min-value {
        margin-top: 0 !important;
    }
    .description-step {
        overflow-y: scroll;
        max-height: 50%;
    }

    .item-carousel-container {
        height: 100%;
    }
    .card-one {
        width: 80%;
    }

    .card-two {
        margin-top: 10px;
        width: 80%;
    }

    .button-color .big-button,
    .mount-container-tango-view
        .card-container-mount
        .container-mount
        .card-container-mount-app-tango
        .button-group-change
        .button-group-selected
        .big-button,
    .row-head-buttons .big-button {
        max-height: 2.8rem;
        width: 46%;
    }

    .timeline-flex-parent .timeline-input-flex-container {
        width: 150%;
    }

    .big-card .unit-card-img,
    .big-card-rented .unit-card-img {
        height: 19.5rem;
    }

    .timeline-flex-parent .timeline-input-flex-container {
        width: 137%;
    }

    .timeline-flex-parent .timeline-input span {
        font-size: 0.75rem;
    }
}

.min-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 3rem;
    h2 {
        color: $color-secondary;
        font-weight: 500;
        font-size: 2.188rem !important;
    }
    div span {
        color: black !important;
    }
}

/* Phone devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: $breakpoint-phone) {
    .big-card .unit-card-img,
    .big-card-rented .unit-card-img {
        height: 20.5rem;
    }

    .big-card,
    .big-card-rented,
    .big-card-rented {
        width: 27.75rem;
        max-width: 100vw;
    }

    .div-5 {
        min-width: 100%;
    }

    .new-close-btn {
        position: absolute;
        right: 0;
        padding: 1rem;
        padding-right: 2rem;
        margin-top: 1rem !important;
        margin-right: 1.5rem !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: $breakpoint-tablet) {
    .big-card .unit-card-img,
    .big-card-rented .unit-card-img,
    .big-card-rented .unit-card-img {
        height: 12.5rem;
    }

    .input-center-search {
        padding-left: 0.5rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: $breakpoint-extra-large) {
    .div-5 {
        min-width: 25%;
    }
}

/* Fake payment method */

section.fake-payment-method {
    a.type-btn {
        text-align: center;
        vertical-align: middle;
        line-height: 2.75;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        text-decoration: none;
        @extend .button-primary;
    }
}

.button-search-container {
    @extend .text-left;
    @extend .my-4;
    @extend .mt-md-4;
    @extend .pl-2;
}

.button-extra-class {
    @extend .mt-4;
    @extend .px-5;
    @extend .col-10;
}

.glossary-box {
    div {
        padding: 1.5rem;
    }

    p {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.375rem;
    }

    margin-top: 3rem;
    text-align: left;
    border-radius: 0.5rem;
    box-shadow: 0px 0.3rem 0.6rem rgba(0, 0, 0, 0.25);
    width: max-content;
}

.button-bottom {
    min-height: calc(18rem - 1.25rem);
}

.text-disabled {
    color: $color-block-disabled;
}

.tabs-div {
    width: 8.4rem;
    margin: 0.25rem;
}

.text-color-tertiary-inline {
    text-align: start;
    padding-left: 0rem !important;
    color: $color-tertiary !important;
    line-height: 20px;
    margin-bottom: 0rem;
    white-space: nowrap;
    font-size: 0.825rem;
}

.text-color-secondary-inline {
    text-align: start;
    padding-left: 0rem !important;
    color: $color-secondary !important;
    line-height: 20px;
    margin-bottom: 0rem;
    white-space: nowrap;
    font-size: 1rem;
}

.circle-user-initials-navbar {
    width: 2.813rem !important;
    height: 2.813rem !important;
    line-height: 2.813rem !important;
    border-radius: 50% !important;
    font-size: 1rem !important;
    color: #fff !important;
    text-align: center !important;
    background: $color-tertiary !important;
}
.upper-body {
    margin-top: -18rem;
    margin-bottom: 10rem;
}
.upper-content {
    margin-bottom: 20rem;
}
.placeholder-sign-up {
    padding: 0.5rem;
    font-size: 0.8rem;
}

.rounded-circle-notifications-header-navbar {
    margin-top: 0.3rem;
    width: 1.4rem;
    height: 1.4rem;
    line-height: 1.4rem;
    border-radius: 50%;
    font-size: 0.9rem;
    margin: 0.2rem;
    color: #fff;
    text-align: center;
    background: $color-secondary;
    cursor: pointer;
}

.circle-user-initials {
    width: 2.813rem;
    height: 2.813rem;
    line-height: 2.813rem;
    border-radius: 50%;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    background: $color-tertiary;
}

.text-underline {
    text-decoration: underline;

    .bolder-menu {
        .MuiListItem-root {
            font-weight: 600;
        }
    }
}

.rounded-circle-notifications-header {
    margin-top: 0.3rem;
    width: 1.4rem;
    height: 1.4rem;
    line-height: 1.4rem;
    border-radius: 50%;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    background: $color-alert-red;
    cursor: pointer;
    @extend .mr-2;
    @extend .mt-0;
}

.circle-user-initials {
    width: 2.813rem;
    height: 2.813rem;
    line-height: 2.813rem;
    border-radius: 50%;
    font-size: 1.2rem;
    color: $color-white;
    text-align: center;
    background: $color-tertiary;
}

.body-card-step {
    border-radius: 20px;
}
.text-short-register {
    span {
        color: black;
        font-size: 1rem;
    }
    span > u > span {
        color: black !important;
        font-weight: bold;
    }
}

.mobile-steps {
    @extend .row;
    @extend .input-container;
    @extend .d-flex;
    @extend .align-items-start;
    @extend .col-12;
    @extend .mt-1;
    .text-header {
        font-weight: 500;
        font-size: 1rem;
    }
    .template-line {
        width: 3rem;
        border: 1px solid $color-secondary;
    }
    .circle-step-mobile {
        align-items: center;
        border-radius: 50%;
        background: $color-secondary;
        color: white;
        display: flex;
        font-weight: 600;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
    }
    .content-text {
        align-items: center;
        display: flex;
        font-size: 0.8rem;
        justify-content: start;
    }
    .step-line {
        height: 4px;
        background-color: $color-block-time;
        margin-right: 4px;
    }
    .active {
        background-color: $color-secondary;
    }
}

.card-without-shadow {
    background: $color-white !important;
    box-shadow: none !important;
}

.card-with-shadow {
    background: $color-white !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.card-view {
    display: inline-block;
    width: 43.25rem;
    height: 42.188rem;
    justify-content: center;
    border-radius: 1.25rem;
    box-shadow: 0.625rem 0.625rem 6.25rem rgba(0, 0, 0, 0.15);
    padding: 5rem 6rem 6rem 6rem;

    .row > div {
        h2 {
            font-weight: 600 !important;
            font-size: 1.75rem;
            line-height: 160%;
            letter-spacing: 0.02em;
        }
        .template-line {
            width: 4.669rem;
            border: 0.063rem solid #a8123e;
        }
    }
    form {
        & > section:nth-child(1) {
            button {
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
            & > div {
                &:nth-child(1) {
                    margin-top: 2rem;
                }
                .MuiInputBase-formControl {
                    height: 2.625rem;
                }
                padding-top: 0.4rem !important;
                div > .input-color-tertiary {
                    color: #999999;
                    font-weight: 600 !important;
                    font-size: 1.125rem;
                }
            }
        }

        & > section:nth-child(2) > .row > .google-button-container > button {
            font-weight: 600 !important;
            font-size: 1rem !important;
        }
    }

    .logo-padding {
        padding: 5rem 0 5rem 0;
    }
}

.card-view-login {
    display: inline-flex;
    height: auto;
    max-width: 600px !important;
    width: 600px;
    justify-content: center;
    border-radius: 1.25rem;
    padding: 5rem 6rem 6rem;
    flex: 1 0 100% !important;
    background: #fff;
    .row > div {
        h2 {
            font-weight: 600 !important;
            font-size: 1.75rem;
            line-height: 160%;
            letter-spacing: 0.02em;
        }
        .template-line {
            width: 4.669rem;
            border: 0.063rem solid #a8123e;
        }
    }
    form {
        & > section:nth-child(1) {
            button {
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
            & > div {
                &:nth-child(1) {
                    margin-top: 2rem;
                }
                .MuiInputBase-formControl {
                    height: 2.625rem;
                }
                padding-top: 0.4rem !important;
                div > .input-color-tertiary {
                    color: #999999;
                    font-weight: 600 !important;
                    font-size: 1.125rem;
                }
            }
        }

        & > section:nth-child(2) > .row > .google-button-container > button {
            font-weight: 600 !important;
            font-size: 1rem !important;
        }
    }

    .logo-padding {
        padding: 5rem 0 5rem 0;
    }
}

.card-view-thankyou {
    display: inline-flex;
    height: auto;
    width: 100%;
    justify-content: center;
    border-radius: 1.25rem;
    padding: 5rem 15px 6rem;
    flex: 1 0 100% !important;
    background: #fff;
    flex-direction: column;
    .row > div {
        h2 {
            font-weight: 600 !important;
            font-size: 1.75rem;
            line-height: 160%;
            letter-spacing: 0.02em;
        }
        .template-line {
            width: 4.669rem;
            border: 0.063rem solid #a8123e;
        }
    }
    form {
        & > section:nth-child(1) {
            button {
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
            & > div {
                &:nth-child(1) {
                    margin-top: 2rem;
                }
                .MuiInputBase-formControl {
                    height: 2.625rem;
                }
                padding-top: 0.4rem !important;
                div > .input-color-tertiary {
                    color: #999999;
                    font-weight: 600 !important;
                    font-size: 1.125rem;
                }
            }
        }

        & > section:nth-child(2) > .row > .google-button-container > button {
            font-weight: 600 !important;
            font-size: 1rem !important;
        }
    }

    .logo-padding {
        padding: 5rem 0 5rem 0;
    }
}

.font-weight-bold-start {
    @extend .font-weight-bold;
    text-align: start;
    font-size: 16px;
}

.title-buttons-container {
    padding-top: 1.875rem;
}

.title-container {
    text-align: start;

    .line {
        margin: 1rem 0 1.875rem 0;
    }

    .title {
        text-align: start;
        font-style: normal;
        font-weight: 600;
        align-items: center;
    }

    .title-info {
        text-align: start;
        font-style: normal;
        font-weight: normal;
        align-items: center;
        margin: 0;
        padding: 0.75rem 0 0.75rem 0;
    }

    .no-button-back-padding {
        padding-top: 5rem;
    }
}

.button-back-container {
    padding: 5rem 0 2rem 5rem;
}

.notification-in-line {
    margin-top: 0.9rem;
    margin-left: 0.6rem;
}
.container-mount {
    min-height: 50rem;
    display: grid;
    grid-template-columns: 55% 50%;
    grid-template-rows: 100%;
    grid-template-areas: 'info-card body-card';
}

.register-text-mobile {
    font-size: 1.25rem !important;
    text-align: left;
}
.register-text-desktop {
    font-size: 1.75rem !important;
    text-align: left;
}
.font-weight-bold {
    font-weight: 600 !important;
}
.margin-top-percentage {
    margin-top: 40%;
}
.price-container {
    width: 100%;
}

.primary-price-title {
    @extend .text-color-secondary;
    @extend .justify-content-end;
    @extend .mr-3;
    @extend .text-nowrap;
    font-size: 1.25rem;
    text-align: center;

    @media only screen and (min-width: 420px) {
        display: flex;
        #price-month {
            margin-left: 0.2rem;
        }
    }

    @media only screen and (max-width: $breakpoint-extra-large) {
        font-size: 1rem;
    }

    @media only screen and (max-width: $breakpoint-phone) {
        font-size: 0.8rem;
    }
    .owner-popup-mobile {
        width: 90vw;
    }
    &.primary-price-title-payments {
        @media only screen and (max-width: $breakpoint-phone) {
            font-size: 1rem;
        }
    }
}

@media only screen and (max-width: $breakpoint-extra-small) and (orientation: landscape) {
    .new-close-btn {
        img {
            width: 7% !important;
        }
    }
}

@media only screen and (max-width: 767px) {
    .new-close-btn {
        width: 100%;
        margin-top: 0.5rem !important;
        img {
            padding: 0.5rem;
            width: 11%;
            margin-right: 0.5rem;
        }
    }

    .form-short-register {
        margin: 0.8rem 0;
        input {
            // font-size: 0.8rem !important;
        }
    }

    .custom-margin-step-3 {
        .step-visit__header {
            margin-bottom: 2rem !important;
            span {
                font-size: 1rem;
            }
        }
        .step-visit__body-step-three {
            margin-bottom: 2rem !important;
        }
        .step-visit__footer-step-three {
            button {
                width: 100% !important;
            }
        }
    }

    .terms-renter-visit {
        .d-flex label {
            font-size: 0.7rem;
        }
    }

    .popup-visit-renter-step-3 {
        .MuiDialog-paperWidthSm {
            height: auto !important;
        }
    }
    .step-visit {
        margin: 0 !important;
        // .input-color-tertiary {
        //   font-size: 0.8rem;
        // }

        input::-webkit-input-placeholder {
            font-size: 0.75rem;
        }
        .step-visit__footer {
            font-size: 0.7rem;
            margin: 0;
            padding-top: 0.5rem;
        }
        .number-code {
            font-size: 0.9rem;
        }
        .flag-element {
            img {
                margin-left: 0.7rem !important;
            }
            .number-code {
                padding-left: 0.5rem !important;
            }
            .vertical-line {
                margin-left: 0.7rem;
            }
        }
        .step-visit__header {
            margin-bottom: 0.5rem;
            img {
                width: 30%;
                max-width: 5rem;
            }
            p {
                font-size: 0.9rem;
                margin: 0;
                padding-left: 0.7rem;
            }
        }
    }
}

.primary-price-title-payments,
.primary-price-title-post-detail {
    @extend .d-flex;
    @extend .pl-2;
    @media only screen and (max-width: $breakpoint-phone) {
        margin-top: 1.25rem;
        justify-content: flex-start !important;
        padding-left: 0 !important;
    }
}

.secondary-price-title-payments,
.secondary-price-title-post-detail {
    @extend .primary-price-title-payments;
    @media only screen and (max-width: $breakpoint-phone) {
        margin-top: 0;
    }
}

.secondary-price-title {
    @extend .text-color-tertiary;
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .justify-content-end;
    @extend .mr-3;
    font-size: 0.8rem;
    text-align: center;
}

.text-secondary-big {
    font-size: 2rem !important;
    font-weight: bold;
    color: $color-secondary;
}

.primary-price-title-post-detail,
.secondary-price-title-post-detail {
    @extend .primary-price-title-payments;
}

.select-currancy-filter {
    @extend .d-flex;
    @extend .flex-nowrap;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .mb-2;

    .MuiButtonBase-root:hover {
        color: white;
        background-color: $color-secondary;
    }

    .MuiButtonBase-root {
        width: 1.7rem;
        height: 1.7rem;
        color: $color-secondary;
        font-size: 0.7rem;
        border-color: $color-secondary;
        border-width: 0.1rem;
        border-style: solid;
    }
    .active-currency-filter {
        color: white;
        background-color: $color-secondary;
    }
}

.short-register-container {
    max-width: 45rem;
    margin: auto;
    .short-register-card {
        @media screen and (max-width: $breakpoint-phone) {
            margin-top: 3rem;
            margin-left: 2.5rem;
            margin-right: 2.5rem;
        }
        border-radius: 2.5rem !important;
    }
}

.px-max-7 {
    @media only screen and (min-width: $breakpoint-phone) {
        padding-right: 8rem !important;
        padding-left: 8rem !important;
    }
}

.iframe_evaluation {
    width: 100%;
    height: 50vh;
    border: none;
}
.pb-7 {
    padding-bottom: 7rem;
}

.promotion-button-container {
    max-width: clam(400px, 70, 50vw);
    justify-content: end;
    button {
        width: 20%;
    }
    @media only screen and (min-width: 600px) and (max-width: $breakpoint-tablet) {
        justify-content: end;
        display: flex;
        button {
            width: 30%;
        }
    }
    @media screen and (max-width: $breakpoint-phone) {
        padding-left: 0rem;
        button {
            width: 100%;
        }
    }
}
.dragging {
    opacity: 0.2;
}
.container-requisitions {
    width: 85%;
    margin-left: auto;
}
.section-carousel {
    margin-top: 6rem;
}

.custom-accept-requisition {
    width: 40rem;
    .custom-padding-inline {
        padding-inline: 3rem;
    }
}

.icon-info-contract{
    display: inline-block;
    margin: 0;
    color: $color-info-darker;
}

.col-contract-info{
    margin-right: -4%;

    @media only screen and (max-width: $breakpoint-extra-small) {
        padding-left: 0px !important;
    }
}

.back-sign-info {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288d1;
    padding: 1%;
    border-radius: 4px;

    @media only screen and (max-width: $breakpoint-phone) {
        text-align: left;
        padding: 6%;
    }
}

.info-contract-text{
    margin-bottom: 0rem;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    text-align: start;
    /* identical to box height, or 24px */

    letter-spacing: 0.15px;

    color: #013654;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 0%;

    @media only screen and (max-width: $breakpoint-phone) {
        margin-left: 0;
        font-size: 14px;
    }

    @media only screen and (max-width: $breakpoint-extra-small) {
        font-size: 12px;
    }
}

.available-post-info-alert-container{
    padding-top: 2%;
    margin-bottom: -2%;
}

.offers-alert-contract-container{
    margin-right: 4.2%;
}
