.container {
    display: block;
    position: absolute;
    margin-bottom: 0.75rem;
    cursor: pointer;
    left: -0.1rem;
}
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: -0.175rem;
    left: -0.5rem;
    height: 1rem;
    width: 1rem;
    border: solid 0.175rem $color-secondary;
    border-radius: 0.1875rem;
    background-color: $color-fifth;
}

.container input:checked ~ .checkmark {
    background-color: $color-secondary;
}

.container input:checked ~ :after {
    display: block;
}

.container :after {
    left: 0.28rem;
    top: 0.16rem;
    width: 0.16rem;
    height: 0.31rem;
    border: solid $color-white;
    border-width: 0 0.1875rem 0.1875rem 0;
}

// switch for photographer and tanguero
.switch-calendar-container {
    @extend .row;
    @extend .mt-3;
    @extend .ml-2;

    .switch-take-calendar {
        position: relative;
        display: inline-block;
        width: 3rem;
        height: 1.5rem;
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        border-radius: 2.2rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-tertiary;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
            border-radius: 50%;
            position: absolute;
            content: '';
            height: 1rem;
            width: 1rem;
            left: 0.25rem;
            bottom: 0.25rem;
            background-color: $color-white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }

    input:checked + .slider {
        background-color: $color-secondary;
        &:before {
            -webkit-transform: translateX(1.5rem);
            -ms-transform: translateX(1.5rem);
            transform: translateX(1.5rem);
        }
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $color-secondary;
    }
}

.roles-checkbox-input-container {
    @extend .row;
    @extend .px-0;
    @extend .d-flex;
    @extend .justify-content-between;
    font-size: 0.9rem;
}
.roles-checkbox-label-container {
    @extend .row;
    @extend .input-container;
    @extend .d-flex;
    @extend .align-items-start;
    @extend .col-12;
    @extend .mt-1;
}
.roles-checkbox {
    @extend .mx-0;
    @extend .px-0;
    @extend .my-2;
}
