.list-container {
    padding-left: 0;
    background-color: white;
    position: absolute;
    z-index: 1000;
    border-radius: 0.3rem;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);

    @media only screen and (max-width: 576px) {
        width: 100%;
        ul li {
            overflow-x: scroll;
        }
        ul li::-webkit-scrollbar {
            display: none;
        }
    }
}

.list-container-fit {
    @media only screen and (min-width: 576px) {
        width: 100%;
        ul li {
            overflow-x: scroll;
        }
        ul li::-webkit-scrollbar {
            display: block;
            height: 0.25rem;
        }
        ul li::-webkit-scrollbar-track {
            background: transparent;
        }
    }
}

.position-top.with-error {
    top: -5.9rem;

    @media only screen and (min-width: 576px) {
        top: -11.2rem;
    }

    @media only screen and (min-width: 768px) {
        top: -7.2rem;
    }

    @media only screen and (min-width: 992px) {
        top: -5.9rem;
    }

    @media only screen and (min-width: 1200px) {
        top: -3.7rem;
    }
}
