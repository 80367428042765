.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    top: 6.5rem;
    background-color: rgba(48, 48, 48, 0.7);
}
.backdrop.left {
    left: 0;
}
.backdrop.right {
    right: 0;
}
.sidebar.open.right,
.sidebar.open.left {
    transform: translateX(0);
    opacity: 1;
}

.sidebar {
    background-color: $color-white;
    position: fixed;
    top: 6.5rem;
    width: 320px;
    height: 100vh;
    z-index: 10000;
    color: $color-primary;
    font-weight: 100;
    opacity: 0;
    transition: transform 0.4s ease;
    padding: 0.9rem 0 0 0;
    line-height: 3rem;
    font-size: 1.2rem;
    ul {
        list-style-type: none;
        text-align: left;
    }
    a {
        text-decoration: none;
        color: $color-primary;
        border-bottom: 2px solid transparent;
    }
    a:hover {
        color: $color-secondary;
        text-decoration: none;
    }
}
.sidebar.left {
    left: 0;
    transform: translateX(-100%);
}
.sidebar.right {
    right: 0;
    transform: translateX(100%);
}
.sidebar-items-container {
    position: relative;
    height: 85%;
}

.notifier.sidebar.open.right {
    height: 100vh;
    width: 85vw;
    transform: translateX(0);
    opacity: 1;
    border-bottom-left-radius: 20px;
    .sidebar-items-container {
        height: 80vh;
    }
}

.notifier.sidebar.right {
    height: 70%;
    right: 0;
    transform: translateX(100%);
}

.sidebar-media {
    position: absolute;
    bottom: 0;
    left: 0.4rem;
    right: 0.4rem;
    width: 95%;
    .media-text {
        font-size: 1rem;
    }
}
.sidebar-media ul.media {
    display: flex;
    text-align: left;
}
.sidebar-media ul.media li {
    padding: 0 1.5rem 1rem 0;
    a {
        cursor: pointer;
    }
}
.menu-icon {
    cursor: pointer;
}
.menu-icon.white {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(142deg) brightness(104%) contrast(106%);
}
