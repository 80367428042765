// MODAL DIALOG
.dialog-modal-container-tango {
    z-index: 4000;
    padding-top: 3rem;
    .MuiTypography-h6 {
        font-size: 1rem !important;
        text-align: center !important;
    }
    .MuiDialogContent-root {
        text-align: center !important;
    }
    .MuiFormControl-root {
        width: 100%;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $color-secondary !important;
    }
    .MuiFormLabel-root.Mui-focused {
        color: $color-secondary;
    }
    .MuiDialogContent-dividers {
        border: none !important;
    }
}

// UNIFICAR URGENTE !!!!
// MODAL BTN CLOSE
.btn-close {
    width: 40px;
    height: 40px;
    background-color: #a8123e;
    position: relative;
    border-radius: 50%;
}

// DIALOG MODAL BTN CLOSE
.btn-close-dm {
    width: 40px;
    height: 40px;
    background-color: #a8123e;
    position: relative;
    border-radius: 50%;
}
.promotion-text-container {
    max-width: 100%;
    small {
        font-size: 0.65rem;
    }
}

.confirmation-text-container {
    text-align: left;
    margin-top: 2rem;
    padding: 0 1.6rem;
}

.confirmation-referred-text-container {
    text-align: center;
    margin-top: 2rem;
    p {
        font-size: 1rem;
        margin-bottom: 0;
    }
}

.confirmation-referred-button-container {
    @extend .mt-4;
    button {
        font-size: 1rem;
        height: 2.625rem;
    }
}

.confirmation-referred-popup-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.icon-requirements-modal {
    width: 2.6rem;
    img {
        max-width: 100%;
    }
}

/* Phone devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: $breakpoint-phone) {
    .referred-code-popup {
        #customized-dialog-title {
            padding-left: 5rem;
            padding-right: 5rem;
            padding-top: 3rem;
        }
        #customized-dialog-title-with-icon {
            padding-left: 5rem;
            padding-right: 5rem;
            padding-top: 3rem;
        }
        #customized-dialog-content {
            padding-left: 5rem;
            padding-right: 5rem;
            padding-bottom: 3rem;
        }
    }

    .confirmation-referred-text-container p {
        font-size: 1.375rem;
    }

    .confirmation-referred-popup-title {
        font-size: 1.5rem;
    }
}

/* Large devices with small resolutions (laptops/desktops, 1440px and up) */
@media only screen and (min-width: $breakpoint-laptop) {
    .confirmation-referred-text-container p {
        font-size: 1.4375rem;
    }

    .confirmation-referred-popup-title {
        font-size: 1.5625rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: $breakpoint-tablet) {
    .modal-full-address-form {
        .MuiDialogContent-dividers {
            margin: auto 1.75rem;
        }
        .MuiDialogTitle-root {
            margin: 1rem 3.75rem auto 1.75rem;
        }
    }
    .dialog-modal-container-tango {
        #customized-dialog-title {
            padding-left: 2.6rem;
            padding-right: 2.6rem;
        }
        #customized-dialog-title-with-icon {
            padding-left: 2.6rem;
            padding-right: 2.6rem;
        }

        #customized-dialog-content {
            padding-left: 2.6rem;
            padding-right: 2.6rem;
        }
    }
    .referred-code-popup {
        #customized-dialog-title {
            padding-left: 5rem;
            padding-right: 5rem;
        }
        #customized-dialog-title-with-icon {
            padding-left: 5rem;
            padding-right: 5rem;
        }
        #customized-dialog-content {
            padding-left: 5rem;
            padding-right: 5rem;
        }
    }
}

.popup-phone-input-modal {
    .MuiPaper-root.MuiDialog-paper {
        padding-top: 2rem;
        width: 50%;
        height: 70%;
        max-width: 30.625rem !important;
        max-height: 26.813rem;
        max-height: 25rem;
        .MuiTypography-root.MuiTypography-h2 {
            font-weight: bold;
            @media only screen and (max-width: $breakpoint-extra-small) {
                font-size: 1rem !important;
            }
        }
        .MuiDialogContent-root {
            display: block;
            justify-content: center;
            padding-top: inherit;
            color: $color-tertiary;
            max-width: 80%;
            padding-top: 0.5rem;
            font-size: 0.8rem;
            margin-left: 10%;
            .btn-user-close {
                @extend .color-secondary;
                background-color: transparent;
                border-style: none;
                text-decoration: underline;
                font-weight: bold;
            }
            @media only screen and (min-width: $breakpoint-phone) {
                margin-left: 16%;
                max-width: 70%;
            }
            @media only screen and (max-width: $breakpoint-extra-small) {
                max-width: 100%;
                margin-left: 0;
            }
        }
        @media only screen and (max-width: $breakpoint-phone) {
            width: 100%;
            min-height: 20rem;
            min-height: 22rem;
        }
    }
    .MuiDialogTitle-root {
        margin-left: 13%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: 100%;
        @media only screen and (min-width: $breakpoint-phone) {
            margin-left: 19%;
        }
    }
}
.content-modal-dialog {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    @media only screen and (min-width: $breakpoint-tablet) {
        padding-left: 2.6rem !important;
        padding-right: 2.6rem !important;
    }
}

.no-center-font {
    max-width: 40.625rem;
    text-align: left !important;
}

.buttons-pre-approved {
    @extend .col-6;
    @extend .mx-1;
    @extend .col-sm-3;
}

.toc-response-modal {
    .MuiPaper-root.MuiDialog-paper {
        width: 100%;
        @media only screen and (min-width: $breakpoint-ultra-small) {
            max-width: 32rem !important;
        }
    }
    section {
        font-size: 1.12rem;
        @media only screen and (max-width: $breakpoint-extra-small) {
            font-size: 1rem;
        }
    }
    .button-toc-response {
        width: 50%;
        @media only screen and (max-width: $breakpoint-extra-small) {
            width: 100%;
        }
    }
}

.title-toc-response-modal {
    @extend .font-weight-bold;
    font-size: 1.25rem;
    @media screen and (max-width: $breakpoint-ultra-small) {
        font-size: 1rem;
    }
}

.promotion-popup {
    .MuiPaper-root.MuiDialog-paper {
        width: 100%;
    }
}

.promotions-popup {
    .MuiDialog-paper {
        margin: 1.5rem;
    }
    .MuiDialog-container {
        height: fit-content;
    }
    .MuiPaper-root.MuiDialog-paper {
        top: -5rem;
    }
    .MuiDialogTitle-root {
        p {
            font-size: 1.25rem;
        }
    }
    .MuiDialogContent-root {
        margin-left: 0;
        display: flex;
        padding-left: 1rem;
        padding-right: 1rem;

        p {
            font-size: 1.12rem;
        }
        .MuiTableCell-root {
            padding: 0;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
        }
        .buttons-container {
            @extend .d-flex;
            @extend .flex-wrap-reverse;
            @extend .justify-content-end;
            @extend .pt-md-4;
            @extend .flex-md-nowrap;
            button {
                margin-bottom: 0.2rem;
                box-shadow: none;
                padding-inline: 2rem;
                border: 1px solid black;
            }
        }
    }
}

.dialog-save-charge-lines {
    .MuiDialog-paper {
        margin: 1.5rem;
    }
    .MuiDialog-container {
        height: fit-content;
    }
    .MuiPaper-root.MuiDialog-paper {
        top: -2.5rem;
    }
    .MuiDialogTitle-root {
        p {
            font-size: 1.25rem;
        }
    }
    .MuiDialogContent-root {
        margin-left: 0;
        display: flex;
        padding-left: 1rem;
        padding-right: 1rem;

        p {
            font-size: 1.12rem;
        }
        .MuiTableCell-root {
            padding: 0;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
        }
        .buttons-container {
            @extend .d-flex;
            @extend .flex-wrap-reverse;
            @extend .justify-content-end;
            @extend .pt-md-4;
            @extend .flex-md-nowrap;
            button {
                margin-bottom: 0.2rem;
                box-shadow: none;
                border: 1px solid black;
            }
        }
    }
}

.dialog-mark-charge-lines {
    @extend .dialog-save-charge-lines;
}

.general-popup-with-buttons {
    .MuiDialog-paperScrollPaper {
        min-width: 17rem;
        max-width: 45rem;
    }
    span {
        text-align-last: center;
    }
    .MuiCircularProgress-root {
        color: white;
    }
    #customized-dialog-content {
        .confirm-finishi-contrct-popup-container {
            margin-inline: 5.5rem;
        }
    }
}
