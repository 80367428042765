h3.owner-home-column {
    font-weight: 500;
}
.owner-carousel-slide {
    background-size: cover;
    color: $color-white;
    background-position: center center;
    position: relative;
    min-height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        font-size: 2.1rem;
        text-shadow: 1.2px 1.4px 1.6px #000;
        color: #fff;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.07);
        border-radius: 0.3rem;
    }
    .carousel-number {
        position: absolute;
        background-color: $color-secondary;
        color: $color-white;
        width: 5rem;
        height: 100%;
        bottom: 0;
        font-size: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .number-left {
        left: 0;
    }
    .number-right {
        right: 0;
    }
    .carousel-number-skew-left {
        position: absolute;
        background-color: $color-secondary;
        width: 7.9rem;
        height: 100%;
        bottom: 0;
        transform: skew(-10deg);
        left: 0;
    }
    .carousel-number-skew-right {
        position: absolute;
        background-color: $color-secondary;
        width: 7.9rem;
        height: 100%;
        bottom: 0;
        transform: skew(-10deg);
        right: 0;
    }
}
.owner-carousel {
    touch-action: none;
    .react-multiple-carousel__arrow:hover {
        background-color: transparent;
    }
    .react-multiple-carousel__arrow {
        background-color: transparent;
    }
    .react-multiple-carousel__arrow::before {
        font-weight: 900;
        font-size: 3rem;
    }
    .react-multi-carousel-dot--active button {
        background-color: $color-secondary;
    }
    .react-multi-carousel-dot button {
        border-color: transparent;
    }
}
.item-carousel-container.plans-carousel {
    .react-multiple-carousel__arrow--left,
    .react-multiple-carousel__arrow--right {
        display: block;
    }
}

.slide-number {
    font-size: 1.8rem;
    font-weight: 600;
}
.home-contact {
    a {
        color: inherit;
    }
}
.rotated-dropdown {
    transform: rotate(180deg);
}
