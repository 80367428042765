.select-available-visitors {
    min-width: 16.875rem;
    background: $color-white;
    border: 0.063rem solid $color-tertiary;
    border-radius: 0.313rem;
    height: 1.875rem;
    .MuiSelect-icon {
        margin-right: 0.625rem;
    }
    .MuiSelect-select.MuiSelect-select {
        padding-left: 4%;
    }
}
.select-disabled-visitors {
    min-width: 16.875rem;
    height: 1.875rem;
    .MuiSelect-icon {
        display: none;
    }
}
.payments-containers {
    display: block;
    padding-bottom: 1rem;
    p {
        text-align: left;
        margin: 0;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
        width: 100%;
        text-align: left;
    }
    .MuiOutlinedInput-input {
        padding: 0rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1.5rem;
    }
}

.select-promotions-style {
    @extend .select-style, .pl-2, .py-1;
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 25.5rem;
}
