.document-html-container {
    font-size: 0.8rem;
    margin: 5rem auto;
    max-width: 100vw;
    padding: 0 0.5rem;

    h2 {
        margin: 1rem auto;
        text-align: left;
        font-weight: bold;
    }
    h3 {
        margin: 1rem auto;
        text-align: center;
        font-weight: bold;
    }
    p {
        text-align: justify;
    }
    .document_title {
        text-align: center;
        font-weight: bold;
        margin: 1.5rem auto;
    }
    .title_underline {
        text-decoration: underline;
    }
    .document_generalBackground {
        margin: 2rem auto;
    }

    ol {
        counter-reset: item;
    }

    ol li {
        display: block;
        position: relative;
    }
    li ol p {
        margin: 0;
        padding: 0;
    }

    ol p {
        margin-top: 1rem;
    }

    ol .clear-padding {
        position: relative;
        left: -2.5rem;
    }

    ol li:before {
        content: counters(item, '.') '.';
        counter-increment: item;
        position: absolute;
        right: 1rem;
        margin: 0 100% 0 0;
        font-weight: bold;
    }
    .list-wrap {
        text-align: left;
        margin-bottom: 1rem;
    }

    div.list {
        counter-reset: list-roman;
    }
    div.list p:before {
        counter-increment: list-roman;
        content: counter(list-roman, upper-roman) '. ';
        right: 2rem;
    }
}
