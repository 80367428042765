.alert-content {
    .MuiAlert-root {
        box-sizing: border-box;

        /* Auto layout */

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 6px 16px;

        width: 320px;
        height: 96px;
        left: 1163px;
        top: 139px;
    }

    .MuiAlert-message {
        /* Typography/Body 2 */
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        /* or 20px */
        letter-spacing: 0.17px;
        text-align: left;
    }

    &--success {
        @extend .alert-content;

        .MuiAlert-root {
            width: 320px;
            height: 96px;
            left: 1163px;
            top: 139px;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2e7d32;
            border: 1px solid #2e7d32;
            border-radius: 4px;
        }

        .MuiIconButton-label {
            svg {
                color: #2e7d32 !important;
            }
        }
    }

    &--warning {
        @extend .alert-content;

        .MuiAlert-root {
            width: 320px;
            height: auto;
            left: 1166px;
            top: 136px;

            background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ed6c02;
            border: 1px solid #ed6c02;
            border-radius: 4px;
        }

        .MuiIconButton-label {
            svg {
                color: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #ed6c02;
            }
        }
    }

    &--error {
        @extend .alert-content;

        .MuiAlert-root {
            width: 350px;
            height: 117px;
            left: 1171px;
            top: 137px;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #d32f2f;
            border: 1px solid #fb0000;
            border-radius: 4px;
        }

        .MuiIconButton-label {
            svg {
                color: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #d32f2f;
            }
        }
    }
}
