.payment-method-selector {
    @extend .mb-4;

    .payment-method-provider-row {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .payment-method-name {
        @extend .font-sm-07, .text-left;
    }
    .payment-method-selector-actions {
        @extend .col;
        display: flex;
        justify-content: end;
    }
}
