$color-block-time: #c1c5c8;
$color-fifth: #f7f7f7;
$color-fourth: #333333;
$color-primary: #1a1a1a;
$color-secondary: #a8123e;
$color-tertiary: #999999;
$color-tertiary-light: #999999a2;
$color-white: #ffffff;
$color-button-change: #e3e3e3;
$color-light-magento: #e097ad;
$color-light-grey: #737373;
$color-dark-gray: #5a5a5a;
$color-medium-gray: #7a7a7a;
$color-block-bg-disabled: #ecedee;
$color-block-disabled: #9a9b9c;
$color-notification: rgba(51, 51, 51, 0.65);
$color-alert-red: #dc3545;
$color-alert: #fb0000;
$color-success: #049248;
$color-success-bg: #c5eed9;
$color-warning: #fb8c00;
$color-warning-bg: #ffd5a0;
$color-price-secondary: #6b6b6b;
$color-button-carousel: #b8b8b8;
$color-muted: #6b6b6b;
$color-disabled: #e8e8e8;
$color-info: #2196f326;
$color-info-darker: #0288d1;
$color-check-sign: #013654;

:root {
    --color-block-time: #c1c5c8;
    --color-fifth: #f7f7f7;
    --color-fourth: #333333;
    --color-primary: #1a1a1a;
    --color-secondary: #a8123e;
    --color-tertiary: #999999;
    --color-tertiary-light: #999999a2;
    --color-white: #ffffff;
    --color-button-change: #e3e3e3;
    --color-light-magento: #e097ad;
    --color-light-grey: #737373;
    --color-dark-gray: #5a5a5a;
    --color-block-bg-disabled: #ecedee;
    --color-block-disabled: #9a9b9c;
    --color-notification: rgba(51, 51, 51, 0.65);
    --color-alert-red: #dc3545;
    --color-alert: #fb0000;
    --color-price-secondary: #6b6b6b;
    --color-button-carousel: #b8b8b8;
    --color-muted: #6b6b6b;
    --color-disabled: #e8e8e8;
}

// color instapage - primary
$color-instapage-items-primary: #ffffff;
$color-instapage-bg-primary: #a8123e;
$color-instapage-btn-primary: #000000;

// color instapage - secondary
$color-instapage-items-secondary: #a8123e;
$color-instapage-bg-secondary: #ffffff;
$color-instapage-btn-secondary: #a8123e;

// color instapage - tertiary
$color-instapage-items-tertiary: #000000;
$color-instapage-bg-tertiary: #efefef;
$color-instapage-btn-tertiary: #ffffff;
$color-instapage-btn-border-text-tertiary: #a8123e;

/*  Extra small devices (Iphone 5) */
$breakpoint-ex-small: 321px;
/*  Ultra small devices (phones, 360px) */
$breakpoint-ultra-small: 360px;
/* Extra small devices (phones, 600px and down) */
$breakpoint-extra-small: 600px;
/* Phone devices (portrait tablets and large phones, 768px and up) */
$breakpoint-phone: 767px;
/* Large devices (laptops/desktops, 992px and up) */
$breakpoint-tablet: 994px;
/* Large devices with small resolutions (laptops/desktops, 1440px and up) */
$breakpoint-laptop: 1440px;
/* Extra large devices (large laptops and desktops, 1200px and up) */
$breakpoint-extra-large: 1200px;
/* Extra extra large devices (large laptops and desktops, 2560px and up) */
$breakpoint-xx-large: 2560px;
