// INPUTS APLICATION
.input-uppercase {
    border-bottom-color: $color-secondary !important;
    background-color: transparent;
    background: none;
    border: 0;
    border-bottom: 1px;
    border-bottom-style: solid;
    text-transform: uppercase;
    font: inherit;
    width: 100%;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 0.375rem 0 0.438rem;
    min-width: 0;
    box-sizing: content-box;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    box-shadow: none;
    outline: none;
    border-radius: 0;
}

.custom-radio {
    input[type='radio'] {
        -webkit-appearance: none;
        border-radius: 50%;
        outline: none;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        border: 2px solid $color-secondary;
        cursor: pointer;
    }

    input[type='radio']:hover {
        box-shadow: 0 0 3px 0px $color-secondary inset;
    }

    input[type='radio']:checked:before {
        content: '';
        height: 1rem;
        width: 1rem;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $color-secondary;
    }
}

.separator-line {
    width: 100%;
    margin: 0;
    border-color: #727272;
    @media only screen and (min-width: 1025px) {
        width: 73.5%;
    }
}

.input-adverts {
    border-color: black !important;
    height: 2rem !important;
}

.input-container {
    align-items: center;
    display: flex;
    height: auto;
    margin: 0.8rem 0rem;
    text-align: start;

    .super-index {
        text-transform: lowercase;
        align-self: center;
    }

    .MuiFormHelperText-root.Mui-required {
        color: red;
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: $color-secondary;
    }

    .MuiOutlinedInput-input {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        border-radius: 0.5rem;
    }

    .input-label-container {
        @extend .col-md-4;
        @extend .pl-0;
        @extend .mb-1;
        padding-top: 0.8rem;
    }

    .input-select-container {
        @extend .col-lg-3;
        @extend .plm-0;
    }
}

.input-container-class {
    @extend .col-12;
    @extend .mt-1;
}

.input-class {
    @extend .col-12;
    @extend .pl-0;
}

.label-class {
    @extend .col-12;
    @extend .pl-0;
    @extend .text-color-secondary;
}

.div-container-input-class-10 {
    @extend .col-md-10;
    @extend .plm-0;
}

.div-container-input-class-12 {
    @extend .col-md-12;
    @extend .plm-0;
}

.label-input-number {
    @extend .col-md-5;
    @extend .p-0;
    p {
        &.text-color-secondary {
            @extend .mb-0;
            @extend .align-self-center;
        }
    }
}

.input-number {
    @extend .col-md-3;
    @extend .p-0;
    .WithStyles\(ForwardRef\(TextField\)\)-root-3 .MuiOutlinedInput-root fieldset {
        border-color: $color-tertiary;
    }
    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 10rem !important;
    }
}

.input-with-image {
    width: 2rem;
}

.div-2 {
    @extend .col-sm-2;
    @extend .p-0;
    @extend .mt-2;
}

.div-5 {
    @extend .col-sm-5;
    @extend .p-0;
    @extend .mt-2;
}

.date-picker {
    @extend .col-sm-6;
    @extend .p-0;
    @extend .my-4;
}

.label-input-text {
    @extend .col-md-3;
    @extend .p-0;
}

.input-text {
    @extend .col-md-9;
    @extend .p-0;
}

.form-control-label {
    @extend .col-12;
    @extend .col-md-4;
    @extend .span-text;
    border: 0;
}

.form-control-label-long {
    @extend .col-12;
    @extend .span-text;
    border: 0;
}

.input-color-tertiary {
    text-align: start;
    padding-left: 0rem !important;
    color: $color-tertiary !important;
    line-height: 1.25rem;
    margin-bottom: 0rem;

    .MuiOutlinedInput-root {
        & fieldset {
            border-color: aqua;
        }
    }
}

.input-class-short-register {
    @extend .col-12;
    @extend .pl-0;

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: $color-tertiary !important;
    }

    .MuiOutlinedInput-root fieldset {
        border-color: $color-tertiary !important;
    }
}

.flag-element {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-color: $color-dark-gray;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    max-width: 100%;
}

.number-format {
    @extend .d-flex;
    div:nth-child(1) {
        img {
            border-radius: 0.2rem;
            margin-left: 0.5rem;
            -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.71);
            box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.71);
        }
        .number-code {
            color: $color-dark-gray;
            padding-left: 1rem;
        }
        .vertical-line {
            height: 70%;
            width: 0.063rem;
            background: $color-dark-gray;
        }
    }
    & div:nth-child(2) div fieldset {
        border: none;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-right: 1px solid;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-color: $color-dark-gray;
    }
}

.user-phone-input {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .p-2;
    border-color: $color-tertiary;
    border-style: solid;
    border-width: 0.07rem;
    border-radius: 0.5rem;

    .user-phone-input-flag {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        @extend .col-3;
        @extend .col-lg-3;
        @extend .p-0;
        @extend .mx-2;
        border-right: 0.07rem solid $color-tertiary;

        img {
            margin-left: 1rem;
            margin-right: 0.5rem;
            border-radius: 0.2rem;
            @media only screen and (max-width: $breakpoint-phone) {
                padding: 0.2rem;
                margin-right: 0.1rem;
            }
        }
        span {
            @extend .pr-2;
            @extend .mr-4;
        }
    }
    @media only screen and (max-width: $breakpoint-phone) {
        font-size: 0.7rem;
    }
    .MuiInputBase-input.MuiInput-input {
        @media only screen and (max-width: $breakpoint-phone) {
            font-size: 0.8rem;
        }
    }
}
.input-pre-approved {
    .MuiOutlinedInput-root fieldset {
        border-color: $color-fourth !important;
    }
    .MuiOutlinedInput-root:hover fieldset {
        border-color: $color-fourth !important;
    }
}

.input-alert {
    .MuiOutlinedInput-root fieldset {
        border-color: $color-alert !important;
    }
}

.information-price {
    @extend .d-block;
}

.number-input-admin-payments {
    .MuiOutlinedInput-input {
        padding: 0.5rem;
    }
    .MuiFormHelperText-contained {
        margin-left: 0;
        margin-right: 0;
        font-size: 0.7rem;
        line-height: 0.7rem;
    }
    input {
        text-align: right;
    }
    input:disabled {
        background-color: #dcdcdc;
    }
}

.disable-inputs {
    input:disabled {
        background-color: $color-disabled;
    }
}

.rounded-input {
    &-0 {
        border-radius: 0;
        .MuiOutlinedInput-root {
            border-radius: 0;
        }
    }
    &-1 {
        border-radius: 0.25rem;
        .MuiOutlinedInput-root {
            border-radius: 0.25rem;
        }
    }
    &-2 {
        border-radius: 0.5rem;
        .MuiOutlinedInput-root {
            border-radius: 0.5rem;
        }
    }
    &-3 {
        border-radius: 0.75rem;
        .MuiOutlinedInput-root {
            border-radius: 0.75rem;
        }
    }
    &-4 {
        border-radius: 1rem;
        .MuiOutlinedInput-root {
            border-radius: 1rem;
        }
    }
    &-5 {
        border-radius: 1.25rem;
        .MuiOutlinedInput-root {
            border-radius: 1.25rem;
        }
    }
    &-6 {
        border-radius: 1.5rem;
        .MuiOutlinedInput-root {
            border-radius: 1.5rem;
        }
    }
    &-7 {
        border-radius: 1.75rem;
        .MuiOutlinedInput-root {
            border-radius: 1.75rem;
        }
    }
    &-8 {
        border-radius: 2rem;
        .MuiOutlinedInput-root {
            border-radius: 2rem;
        }
    }
}
