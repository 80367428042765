.numbered-table {
    .label-row {
        color: #1a1a1a;
    }
    td {
        input {
            @extend .text-right;
        }
    }
    th {
        border-bottom: none;
    }
    &.without-total {
        tr:not(:last-child) {
            td {
                border-bottom: none;
            }
        }
    }

    &.with-total {
        tr:not(:nth-last-child(-n + 2)) {
            td {
                border-bottom: none;
            }
        }
    }
    &.headerless {
        td {
            border-bottom: none;
        }
    }
    &.without-border-bottom {
        tr:last-child {
            td {
                border-bottom: none;
            }
        }
    }

    @media only screen and (max-width: $breakpoint-phone) {
        th,
        td {
            &:first-child {
                width: 50%;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-phone) {
        th,
        td {
            &:first-child {
                width: 67%;
            }
        }
    }
}
